import {
	Box,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/themes/theme";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CustomButton from "../../atoms/Buttons/CustomButton";
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/fr";
import GoodMoveLogo from "../../../assets/images/logo_classic_outlined.svg";
import { useSelector } from "react-redux";

const WelcomeModal = ({ open, handleBack, handleSave }) => {
	const [userName, setUserName] = useState("");
	const [birthday, setBirthday] = useState(null);
	const [errForm, setErrForm] = useState(null);
	dayjs.extend(localizedFormat);
	dayjs.locale("fr");

	const { error } = useSelector((state) => state.auth);

	const handleVerifData = () => {
		let err = { userName: null, birthday: null };

		if (userName.trim()?.length < 1) {
			err.userName = "Le nom d'utilisateur est requis.";
		} else if (userName.trim().length > 30) {
			err.userName =
				"Le nom d'utilisateur ne doit pas dépasser 30 caractères.";
		}

		if (!birthday) {
			err.birthday = "La date de naissance est requise.";
		} else {
			const age = dayjs().diff(birthday, "year");
			if (age < 16) {
				err.birthday =
					"Vous devez avoir au moins 16 ans pour vous inscrire sur GoodMove.";
			}
		}

		if (err.userName != null || err.birthday != null) {
			setErrForm(err);
		} else {
			handleSave(userName, birthday.format("YYYY/MM/DD"));
		}
	};

	const handleChangeUserName = (e) => {
		errForm && setErrForm(null);
		setUserName(e.target.value);
	};

	const handleChangeBirth = (e) => {
		errForm && setErrForm(null);
		setBirthday(e);
	};

	const handleBackClick = () => {
		setUserName("");
		setBirthday(dayjs().subtract(16, "year"));
		setErrForm(null);
		handleBack();
	};

	useEffect(() => {
		if (error && error?.code === 409) {
			setErrForm({ userName: "nom d'utilisateur déjà utilisé" });
		}
	}, [error]);

	return (
		<Modal
			open={open}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<Card
				sx={{
					width: {
						xs: "100%",
						sm: "100%",
						md: "850px",
					},
					height: {
						xs: "100%",
						sm: "100%",
						md: "auto",
					},
					m: "auto",
					backgroundColor: theme.palette.secondary.dark,
					overflowY: "scroll",
				}}
			>
				<CardHeader
					avatar={
						<>
							<IconButton
								aria-label="close"
								onClick={handleBackClick}
							>
								<KeyboardBackspaceIcon />
							</IconButton>
						</>
					}
					title={"Finalisez la création de votre compte"}
					sx={{
						pb: 1,
						".MuiCardHeader-title": {
							textAlign: "center",
							fontSize: "16px",
							fontWeight: "500",
							pr: 5,
						},
					}}
				/>
				<CardContent sx={{ display: "flex", flexDirection: "column" }}>
					<Box
						sx={{
							mt: 2,
							ml: "auto",
							mr: "auto",
							mb: 5,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							maxWidth: "500px",
							width: "100%",
						}}
					>
						<img alt="GoodMove" src={GoodMoveLogo} />
					</Box>
					<Typography variant="h6" mb={2}>
						Choisissez un username qui vous correspond et qui sera
						unique sur notre plateforme
					</Typography>
					<TextField
						label="Nom d'utilisateur"
						helperText={
							errForm?.userName
								? errForm?.userName
								: "Attention : il vous sera impossible de le modifier plus tard"
						}
						title="Renseignez votre nom d'utilisateur"
						InputLabelProps={{
							sx: {
								backgroundColor: theme.palette.secondary.dark,
							},
						}}
						variant="outlined"
						fullWidth
						value={userName}
						onChange={handleChangeUserName}
						error={errForm?.userName}
					/>

					<MobileDatePicker
						label={"Date de naissance"}
						value={birthday}
						onChange={handleChangeBirth}
						sx={{
							ml: "auto",
							mr: "auto",
							mt: 2,
							width: "100%",
						}}
					/>
					<Typography
						color={
							errForm?.birthday
								? theme.palette.error.main
								: theme.palette.otherColor.extraLight
						}
						mb={2}
						fontSize={12}
						ml={2}
						mt={0.5}
					>
						{errForm?.birthday
							? errForm?.birthday
							: "L'âge minimum requis pour l'inscription est de 16 ans. Les informations liées à votre âge ne seront pas affichées publiquement."}
					</Typography>

					<CustomButton
						content={"Sauvegarder"}
						css={{ width: "100%" }}
						onClick={handleVerifData}
					/>
				</CardContent>
			</Card>
		</Modal>
	);
};

export default WelcomeModal;
