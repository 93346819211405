import { Box, Modal, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import CustomButton from "../../atoms/Buttons/CustomButton";
import CustomOutlinedBtn from "../../atoms/Buttons/CustomOutlinedBtn";
import { theme } from "../../../assets/themes/theme";

const ConfirmModal = ({ open, title, description, handleClose, handleConfirmClick, confirmTitle, cancelTitle }) => {
	const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
		>
			<Box
				width={mobileScreen ? 250 : 500}
				p={5}
				borderRadius="7px"
				position="absolute"
				top="50%"
				left="50%"
				textAlign={"center"}
				sx={{
					transform: "translate(-50%, -50%)",
					backgroundColor: theme.palette.secondary.main,
				}}
			>
				<Typography variant="h5" mb={2}>
					{title}
				</Typography>
				<Typography mb={2}>
					{description}
				</Typography>
				<Box display="flex" flexWrap={"wrap"} justifyContent={mobileScreen ? "center" : "space-evenly"}>
					<CustomButton
						content={confirmTitle||"Supprimer"}
						css={{
							mb:1,
							mt:1,
							backgroundColor: theme.palette.otherColor.light,
							"&:hover": {
								backgroundColor: theme.palette.otherColor.main,
							},
						}}
						onClick={handleConfirmClick}
					/>
					<CustomOutlinedBtn
						content={cancelTitle||"Annuler"}
						onClick={handleClose}
						css={{mt:1}}
					/>
				</Box>
			</Box>
		</Modal>
	);
};

export default ConfirmModal;
