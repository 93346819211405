import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import React, { useState } from "react";
import { addComment } from "../../../store/slices/commentSlice";
import { useDispatch } from "react-redux";
import { addOneComment } from "../../../store/slices/postSlice";

const InputComment = ({postId, userId}) => {
	const [input, setInput] = useState("");
	const dispatch = useDispatch();
	
	const handleAddComment = () => {
		dispatch(addComment({ postId, content: input, replyTo: null, commentType:"TEXT"}));
		dispatch(addOneComment({ postId, userId }))
		setInput("");
	};

	return (
		<OutlinedInput
			multiline
			maxRows={4}
			fullWidth
			placeholder={"Réagir à cette bonne action..."}
			onChange={(e) => setInput(e.target.value)}
			value={input}
			endAdornment={
				<InputAdornment position="end">
					<IconButton
						aria-label="toggle password visibility"
						edge="end"
						disabled={!input}
						onClick={handleAddComment}
					>
						<SendOutlinedIcon />
					</IconButton>
				</InputAdornment>
			}
			sx={{mb:0.5}}
		/>
	);
};

export default InputComment;
