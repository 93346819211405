import { Avatar, Box, Icon, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/themes/theme";
import CircleIcon from "@mui/icons-material/Circle";
import TrophyIcon from "@mui/icons-material/EmojiEvents";
import { useIntersectionObserver } from "../../../hooks/useIntersectionObserver";
import { useDispatch } from "react-redux";
import { readNotifications } from "../../../store/slices/notifSlice";

const NotifCard = ({
	id,
	avatar,
	handleClickAvatar,
	isTrophy,
	title,
	description,
	createdAt,
	readAt,
	onClickNotif,
	css,
	loadingN,
}) => {
	const [isRead, setIsRead] = useState(readAt);
	const [targetRef, isIntersecting] = useIntersectionObserver({
		threshold: 1,
	});
	const dispatch = useDispatch();

	useEffect(() => {
		if (isIntersecting && !isRead && !loadingN) {
			setIsRead(true);
			dispatch(readNotifications({ notifId: id }));
		}
	}, [isIntersecting, loadingN]);

	return (
		<Box
			ref={targetRef}
			display={"flex"}
			justifyContent={"space-between"}
			bgcolor={readAt ? "#eee" : "white"}
			p={1.5}
			borderRadius={2}
			mb={1.5}
			onClick={onClickNotif}
			sx={{
				boxShadow: readAt
					? "1px 3px 6px rgba(85, 85, 85, 0.25)"
					: "0px 0px 9px -2px #1ABD8C",
				...css,
			}}
		>
			{isTrophy ? (
				<Box
					sx={{
						borderRadius: 1,
						bgcolor: theme.palette.secondary.dark,
						width: "40px",
						height: "40px",
						display: "flex",
					}}
				>
					<Icon
						component={TrophyIcon}
						sx={{
							m: "auto",
							width: "30px",
							height: "30px",
							color: theme.palette.primary.main,
						}}
					/>
				</Box>
			) : (
				<Avatar
					src={avatar}
					alt="avatar picture"
					onClick={handleClickAvatar}
					sx={{
						borderRadius: 1,
						cursor: "pointer",
						mb: "auto",
						width: "40px",
						height: "40px",
					}}
				/>
			)}

			<Box mt={"auto"} mb={"auto"} flex={1} mr={1} zIndex={1}>
				<Typography
					fontWeight={500}
					fontSize={14}
					color={theme.palette.otherColor.main}
					ml={2.5}
				>
					<b>
						{!isTrophy && "@"}
						{title}
					</b>{" "}
					{description}
				</Typography>
					<Box display={"flex"}>
						<CircleIcon
							sx={{
								width: "10px",
								height: "10px",
								mt: "auto",
								mb: "auto",
								mr: 1,
								ml: 3,
								color: theme.palette.primary.main,
							}}
						/>
						<Typography fontSize={14}>{createdAt}</Typography>
					</Box>
			</Box>
		</Box>
	);
};

export default NotifCard;
