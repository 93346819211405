import React, { useEffect } from "react";
import LoadingSection from "../organisms/LoadingSection/LoadingSection";
import {
	requestAxiosLinkedinAccessToken,
	requestAxiosLinkedinUserInfo,
} from "../../services/axios/axiosRequests";

const LinkedinCallbackPage = () => {
	const handleLogin = async (code) => {
		try {
			const response = await requestAxiosLinkedinAccessToken(code);
			const accessToken = response.access_token;
			const userProfile = await requestAxiosLinkedinUserInfo(accessToken);
			await window.opener.postMessage(
				{ userProfile },
				window.location.origin
			);
			window.close();
		} catch (error) {
			console.error("Error during LinkedIn authentication", error);
		}
	};

	useEffect(() => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const code = urlParams.get("code");
		if (code) {
			handleLogin(code);
		}
	}, []);

	return <LoadingSection />;
};

export default LinkedinCallbackPage;
