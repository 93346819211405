import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../../assets/themes/theme";
import { useNavigate } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";

const UserCard = ({ user }) => {
	const navigate = useNavigate();

	return (
		<Box
			display={"flex"}
			justifyContent={"space-between"}
			bgcolor={"white"}
			p={1.5}
			borderRadius={2}
			onClick={() => navigate("/profile/" + user?.id)}
			sx={{
				cursor: "pointer",
				boxShadow: "1px 3px 6px rgba(85, 85, 85, 0.25)",
				ml: "auto",
				mr: "auto",
			}}
		>
			<Avatar
				src={user?.profilePicture}
				alt="relation profile picture"
				sx={{
					borderRadius: 1,
					cursor: "pointer",
					mt: "auto",
					mb: "auto",
					width: "50px",
					height: "50px",
				}}
			/>
			<Box mt={"auto"} mb={"auto"} flex={1} mr={1} zIndex={1}>
				<Typography
					fontWeight={600}
					fontSize={15}
					color={theme.palette.otherColor.main}
					ml={2}
					sx={{
						maxWidth: { xs: "230px", sm: "300px", md: "250px", lg: '300px', xl: '300px' },
						whiteSpace: "nowrap",
						overflowX: "hidden",
						textOverflow: "ellipsis",
					}}
				>
					{user?.firstName} {user?.lastName}
				</Typography>
				<Box display={"flex"}>
					<CircleIcon
						sx={{
							width: "10px",
							height: "10px",
							mt: "auto",
							mb: "auto",
							mr: 1,
							ml: 2,
							color: theme.palette.primary.main,
						}}
					/>
					<Typography
						fontWeight={300}
						fontSize={15}
						color={theme.palette.otherColor.main}
					>
						@{user?.username || "unknown"}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default UserCard;
