import {
	Box,
	CardMedia,
	CircularProgress,
	IconButton,
	Typography,
} from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../../assets/themes/theme";
import CreatePostSection from "../CreatePostSection/CreatePostSection";
import PostComponent from "../../molecules/PostComponent/PostComponent";
import useDimensions from "../../../hooks/useDimensions";
import { useDispatch, useSelector } from "react-redux";
import PostComponentDetails from "../../molecules/PostComponent/PostComponentDetails";
import OptionsModal from "../../molecules/Modal/OptionsModal";
import { toast } from "react-toastify";
import { deletePost } from "../../../store/slices/postSlice";
import ReplayIcon from "@mui/icons-material/Replay";
import { useParams } from "react-router-dom";
import FeedTabs from "../../molecules/Tabs/FeedTabs";
import bookGif from "../../../assets/videos/notebook.gif";
import ReportModal from "../../molecules/Modal/ReportModal";

const FeedSection = ({
	handleAddPost,
	targetRef,
	context,
	handleClickFetch,
	filter = true,
	create = true,
}) => {
	const [visible, setVisible] = useState(false);
	const [postDetailsId, setPostDetailsId] = useState(null);
	const [optionsPostId, setOptionsPostId] = useState(null);
	const [reportPostId, setReportPostId] = useState(null);
	const [openOptions, setOpenOptions] = useState(false);
	const [openReport, setOpenReport] = useState(false);
	const [optionsIsAuthor, setOptionsIsAuthor] = useState(false);
	const [dimensions] = useDimensions();
	const dispatch = useDispatch();
	const { id } = useParams();
	const posts = useSelector((state) => {
		switch (context) {
			case "profile":
				return state.posts.profilesItems[id]?.itemsPI;
			default:
				return state.posts.itemsP;
		}
	});

	const endData = useSelector((state) => {
		switch (context) {
			case "profile":
				return state.posts.profilesItems[id]?.endDataPI;
			default:
				return state.posts.endDataP;
		}
	});

	const { user } = useSelector((state) => state.auth);

	const handleMoreDetails = (postId) => {
		setPostDetailsId(postId);
		setVisible(true);
	};

	const handleCloseDetails = () => {
		setVisible(false);
		setPostDetailsId(null);
	};

	const handleCloseOptions = () => {
		setOpenOptions(false);
		setOptionsPostId(null);
		setOptionsIsAuthor(false);
	};

	const handleOpenOptions = (postId, isAuthor) => {
		setOptionsPostId(postId);
		setOptionsIsAuthor(isAuthor);
		setOpenOptions(true);
	};

	const handleOptionsDelete = () => {
		if (optionsIsAuthor) {
			dispatch(deletePost({ postId: optionsPostId, userId: user.id }));
			toast.success("Post supprimé", {
				toastId: "delete-post-success-" + optionsPostId,
			});
		}
		handleCloseDetails();
		handleCloseOptions();
	};

	const handleOptionsReport = () => {
		setReportPostId(optionsPostId);
		setOpenReport(true);
		handleCloseOptions();
	};

	return (
		<Box
			color={theme.palette.primary.main}
			bgcolor={theme.palette.secondary.dark}
			flex={4}
			height={"100%"}
		>
			<Box
				ml={"auto"}
				mr={"auto"}
				sx={{
					maxWidth: {
						xs: "450px",
						sm: "480px",
						md: "800px",
						lg: "800px",
					},
				}}
			>
				{filter && <FeedTabs />}
				{create && <CreatePostSection handleAddPost={handleAddPost} />}
				{posts?.length > 0 && (
					<>
						{posts.map((post) => (
							<Box key={post?.id} mb={3}>
								<PostComponent
									postId={post?.id}
									parentWidth={dimensions?.width}
									setMoreDetails={handleMoreDetails}
									handleOpenOptions={handleOpenOptions}
									context={context}
								/>
							</Box>
						))}
						{!endData && (
							<Box ref={targetRef} textAlign={"center"}>
								<CircularProgress />
							</Box>
						)}
					</>
				)}
				{posts?.length === 0 && (
					<Box
						display={"flex"}
						justifyContent={"center"}
						flexDirection={"column"}
					>
						<CardMedia
							component="img"
							image={bookGif}
							alt="searching-data"
							sx={{
								width: "80px",
								height: "80px",
								ml: "auto",
								mr: "auto",
								mt: 3,
							}}
						/>
						<Typography
							textAlign={"center"}
							mt={3}
							fontWeight={500}
							color={theme.palette.otherColor.main}
						>
							Aucun résultat pour le moment
						</Typography>
					</Box>
				)}
				{(posts?.length === 0 || endData) && (
					<Box textAlign={"center"} mt={2}>
						<IconButton
							onClick={handleClickFetch}
							sx={{ m: 1, mb: 10 }}
						>
							<ReplayIcon />
						</IconButton>
					</Box>
				)}
			</Box>
			<PostComponentDetails
				open={visible}
				handleClose={handleCloseDetails}
				handleOpenOptions={handleOpenOptions}
				postId={postDetailsId}
				context={context}
			/>
			<OptionsModal
				postId={optionsPostId}
				open={openOptions}
				handleClose={handleCloseOptions}
				handleDelete={handleOptionsDelete}
				handleReport={handleOptionsReport}
				allAccess={optionsIsAuthor}
			/>
			<ReportModal
				open={openReport}
				postId={reportPostId}
				handleClose={() => {
					setOpenReport(false);
					setReportPostId(null);
				}}
			/>
		</Box>
	);
};

export default FeedSection;
