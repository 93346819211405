import React, { useEffect, useState } from "react";
import Navbar from "../molecules/Navbar/Navbar";
import { Box, Stack } from "@mui/material";
import BottomNavbar from "../molecules/BottomNavbar/BottomNavbar";
import ProfilSection from "../organisms/ProfilSection/ProfilSection";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchUserById } from "../../store/slices/userSlice";
import LoadingSection from "../organisms/LoadingSection/LoadingSection";
import { toast } from "react-toastify";
import { cleanErrorU } from "../../store/slices/userSlice";
import HeaderMobile from "../molecules/HeaderMobile/HeaderMobile";
import NotFoundPage from "./NotFoundPage";
import { addPost, cleanErrorP } from "../../store/slices/postSlice";
import CreatePostSectionModale from "../organisms/CreatePostSection/CreatePostSectionModal";

const ProfilPage = ({ SXMarginSidebar }) => {
	document.title = "Page de profil | Goodmove";
	const [open, setOpen] = useState(false);
	const [redirect, setRedirect] = useState(false);
	const dispatch = useDispatch();
	const [loadProfile, setLoadProfile] = useState(true);
	const { id } = useParams();
	const { loadingU, itemsU, errorU } = useSelector((state) => state.users);
	const user = useSelector((state) => state.auth.user);
	const { errorP } = useSelector((state) => state.posts);

	const handleAddPost = (newPost) => {
		open && setOpen(false);
		dispatch(addPost({ userId: user?.id, newPost }));
	};

	useEffect(() => {
		setLoadProfile(true);
	}, []);

	useEffect(() => {
		if ((!id || errorU) && !redirect) {
			dispatch(cleanErrorU());
			setRedirect(true);
		}
	}, [id, errorU]);

	useEffect(() => {
		if (!itemsU[id] && !loadingU) {
			dispatch(fetchUserById({ userId: id }));
		} else if (itemsU[id] && loadProfile) {
			setLoadProfile(false);
		}
	}, [itemsU, id, loadProfile]);

	if (errorP) {
		toast.error(errorP?.message || "un problème est survenu", {
			toastId: "post-error",
		});
		dispatch(cleanErrorP());
	}

	if (redirect) {
		return <NotFoundPage />;
	}

	if (loadProfile) {
		return <LoadingSection />;
	}

	return (
		<Box minHeight={"100vh"}>
			<Navbar handleOpenCreatePost={() => setOpen(true)} />
			<HeaderMobile />
			<Box
				sx={{
					ml: SXMarginSidebar(),
					pt: { xs: 12.5, sm: 0, md: 0, lg: 0, xl: 0 },
				}}
			>
				<Stack direction="row">
					<ProfilSection />
				</Stack>
			</Box>
			<CreatePostSectionModale
				open={open}
				handleClose={() => setOpen(false)}
				handleAddPost={handleAddPost}
			/>
			<BottomNavbar />
		</Box>
	);
};

export default ProfilPage;
