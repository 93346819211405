import { createTheme } from "@mui/material";

export const theme = createTheme({
	typography: {
		fontFamily: "Montserrat, Arial, sans-serif",
		h1: {
			fontSize: "2.5rem",
			fontWeight: 700,
			lineHeight: 1.2,
			letterSpacing: "-0.01562em",
		},
		h2: {
			fontSize: "2rem",
			fontWeight: 700,
			lineHeight: 1.3,
			letterSpacing: "-0.00833em",
		},
		h3: {
			fontSize: "1.75rem",
			fontWeight: 700,
			lineHeight: 1.4,
			letterSpacing: "0em",
		},
		h4: {
			fontSize: "1.5rem",
			fontWeight: 700,
			lineHeight: 1.5,
			letterSpacing: "0.00735em",
		},
		h5: {
			fontSize: "1.25rem",
			fontWeight: 700,
			lineHeight: 1.6,
			letterSpacing: "0em",
		},
		h6: {
			fontSize: "1rem",
			fontWeight: 700,
			lineHeight: 1.7,
			letterSpacing: "0.0075em",
		},
		body1: {
			fontSize: "1rem",
			fontWeight: 400,
			lineHeight: 1.5,
			letterSpacing: "0.00938em",
		},
		body2: {
			fontSize: "14px",
			fontWeight: 500,
			lineHeight: 1.3,
			letterSpacing: "0.01071em",
		},
		subtitle1: {
			fontSize: "1rem",
			fontWeight: 400,
			lineHeight: 1.75,
			letterSpacing: "0.00938em",
		},
		subtitle2: {
			fontSize: "0.875rem",
			fontWeight: 500,
			lineHeight: 1.57,
			letterSpacing: "0.00714em",
		},
		button: {
			fontSize: "0.875rem",
			fontWeight: 700,
			lineHeight: 1.75,
			letterSpacing: "0.02857em",
			textTransform: "uppercase",
		},
        buttonTag: {
			fontSize: "0.875rem",
			fontWeight: 500,
			lineHeight: 1.75,
			letterSpacing: "0.02857em",
			textTransform: "uppercase",
		},
		caption: {
			fontSize: "0.75rem",
			fontWeight: 400,
			lineHeight: 1.66,
			letterSpacing: "0.03333em",
		},
		overline: {
			fontSize: "0.75rem",
			fontWeight: 400,
			lineHeight: 2.66,
			letterSpacing: "0.08333em",
			textTransform: "uppercase",
		},
	},
	palette: {
		primary: {
			main: "#1ABD8C",
		},
		secondary: {
			main: "#FAFAFA",
			dark: "#E9ECEE",
			ultradark: "#BCBCBC",
		},
		otherColor: {
			main: "#171717",
			light: "#464655",
			extraLight: "#757575",
		},
		error: {
			main: "rgb(230, 57, 57)",
		},
	},
});
