import { Avatar, Box } from "@mui/material";
import React from "react";

const ProfilePicture = ({ profilePicture }) => {
	const size = {
		xs: "100px",
		sm: "105px",
		md: "120px",
		lg: "130px",
		xl: "150px",
	};

	return (
		<Box position={"relative"} sx={{ width: size, height: size }}>
			<Box
				position={"absolute"}
				zIndex={10}
				sx={{
					top: {
						xs: "-40px",
						sm: "-40px",
						md: "-55px",
						lg: "-63px",
						xl: "-80px",
					},
				}}
			>
				<Avatar
					src={profilePicture}
					alt="Banniere utilisateur"
					sx={{
						height: size,
						width: size,
						borderRadius: "16px",
						border: "3px solid white",
						boxShadow: "1px 3px 6px rgba(85, 85, 85, 0.25)",
					}}
				/>
			</Box>
		</Box>
	);
};

export default ProfilePicture;
