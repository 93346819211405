import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	requestAxiosCreatePost,
	requestAxiosDeletePost,
	// requestAxiosEditPost,
	requestAxiosGetPostById,
	requestAxiosGetPosts,
	requestAxiosGetPostsByUserId,
	requestAxiosLikePost,
} from "../../services/axios/axiosRequests";

// récupération des posts du fil d'actualité
export const fetchPosts = createAsyncThunk(
	"posts/fetchPosts",
	async (params, { rejectWithValue }) => {
		try {
			const response = await requestAxiosGetPosts(params);
			if (!response) {
				throw new Error("Failed to fetch posts");
			}
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// récupération d'un post en fonction de son Id
export const fetchPostsById = createAsyncThunk(
	"posts/fetchPostById",
	async ({ postId, userId }, { rejectWithValue }) => {
		try {
			const response = await requestAxiosGetPostById(postId);
			if (!response) {
				throw new Error("Failed to fetch posts");
			}
			return { userId, post: response };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// récupération des posts d'un profil utilisateur
export const fetchPostsByUserId = createAsyncThunk(
	"posts/fetchProfilePosts",
	async ({ userId, page, limit }, { rejectWithValue }) => {
		try {
			const posts = await requestAxiosGetPostsByUserId(
				userId,
				page,
				limit
			);
			return { userId, ...posts };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const addPost = createAsyncThunk(
	"posts/addPost",
	async ({ userId, newPost }, { rejectWithValue }) => {
		try {
			const response = await requestAxiosCreatePost(newPost);
			return { userId, post: response };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const likePost = createAsyncThunk(
	"posts/likePost",
	async ({ postId, userId }, { rejectWithValue }) => {
		try {
			const response = await requestAxiosLikePost(postId);
			return { postId, userId, ...response };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// export const editPost = createAsyncThunk( //TODO:
// 	"posts/editPost",
// 	async (params, { rejectWithValue }) => {
// 		try {
// 			const response = await requestAxiosEditPost(params);
// 			if (!response) {
// 				throw new Error("Failed to fetch posts");
// 			}
// 			return response;
// 		} catch (error) {
// 			return rejectWithValue(error);
// 		}
// 	}
// );

export const deletePost = createAsyncThunk(
	"posts/deletePost",
	async ({ postId, userId }, { rejectWithValue }) => {
		try {
			await requestAxiosDeletePost(postId);
			return { postId, userId };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

//ici 2 array de posts sont présent
//car le chargement des posts d'un profile ne doit pas impacter les posts du fil d'actualité
const postSlice = createSlice({
	name: "posts",
	initialState: {
		itemsP: [],
		profilesItems: [],
		limitP: 10,
		pageP: 0,
		endDataP: false,
		pagePI: 0,
		endDataPI: false,
		loadingP: false,
		errorP: null,
		reloadP: false,
	},
	reducers: {
		addOneComment: (state, action) => {
			const { postId, userId } = action.payload;
			const index = state.itemsP.findIndex((post) => post?.id === postId);
			if (index !== -1) {
				state.itemsP[index].nbComments += 1;
			}
			if (userId) {
				const indexPI = state.profilesItems[userId]?.itemsPI?.findIndex(
					(post) => post?.id === postId
				);
				if (indexPI !== -1) {
					state.profilesItems[userId].itemsPI[
						indexPI
					].nbComments += 1;
				}
			}
		},
		deleteOneComment: (state, action) => {
			const { postId, userId } = action.payload;
			const index = state.itemsP.findIndex((post) => post?.id === postId);
			if (index !== -1) {
				state.itemsP[index].nbComments -= 1;
			}
			if (userId) {
				const indexPI = state.profilesItems[userId]?.itemsPI?.findIndex(
					(post) => post?.id === postId
				);
				if (indexPI !== -1) {
					state.profilesItems[userId].itemsPI[
						indexPI
					].nbComments -= 1;
				}
			}
		},
		sortPosts: (state) => {
			state.itemsP?.sort((a, b) => {
				// comparaison par date de publication (ordre décroissant)
				if (a.createdAt < b.createdAt) return 1;
				if (a.createdAt > b.createdAt) return -1;

				return 0;
			});
		},
		sortProfilePosts: (state, action) => {
			state.profilesItems[action.payload.userId]?.itemsPI?.sort(
				(a, b) => {
					// comparaison par date de publication (ordre décroissant)
					if (a.createdAt < b.createdAt) return 1;
					if (a.createdAt > b.createdAt) return -1;

					return 0;
				}
			);
		},
		cleanErrorP: (state) => {
			state.errorP = null;
		},
		cleanDataP: (state) => {
			state.itemsP = [];
			state.profilesItems = [];
			state.pageP = 0;
			state.endDataP = false;
			state.pagePI = 0;
			state.endDataPI = false;
			state.reloadP = true;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPosts.pending, (state) => {
				state.loadingP = true;
				state.errorP = null;
				state.reloadP = false;
			})
			.addCase(fetchPosts.fulfilled, (state, action) => {
				const { items, pagination } = action.payload;
				if (state.itemsP?.length === 0) {
					state.itemsP = items;
				} else {
					// si itemsP n'est pas vide on va éviter l'ajout de doublons
					// on récupère les id des posts existants
					const existingPostIds = new Set(
						state.itemsP?.map((post) => post?.id)
					);
					// on garde uniquement les nouveaux posts qui ne sont pas déjà présents dans la liste
					const newPosts = items?.filter(
						(post) => !existingPostIds.has(post?.id)
					);
					state.itemsP = [...state.itemsP, ...newPosts];
				}
				const endDataP = state.itemsP?.length === pagination?.total;
				const limitReached = items?.length === pagination?.limit;
				state.endDataP = endDataP;
				// S'il reste des posts à charger on passe à la page suivante
				if (!endDataP && limitReached) {
					state.pageP += 1;
				}
				state.loadingP = false;
			})
			.addCase(fetchPosts.rejected, (state, action) => {
				state.loadingP = false;
				state.errorP = action.payload;
			})
			.addCase(fetchPostsById.pending, (state) => {
				state.loadingP = true;
				state.errorP = null;
			})
			.addCase(fetchPostsById.fulfilled, (state, action) => {
				const { userId, post } = action.payload;
				state.loadingP = false;
				const index = state.itemsP.findIndex((p) => p?.id === post?.id);

				// on met à jours les posts une fois qu'on a eu des données actualisées
				if (index !== -1) {
					state.itemsP[index] = post;
				} else {
					state.itemsP[post.id] = post;
				}
				if (userId) {
					const indexPI = state.profilesItems[
						userId
					]?.itemsPI?.findIndex((p) => p?.id === post?.id);
					if (indexPI !== -1) {
						state.profilesItems[userId].itemsPI[indexPI] = post;
					}
				}
			})
			.addCase(fetchPostsById.rejected, (state, action) => {
				state.loadingP = false;
				state.errorP = action.payload;
			})
			.addCase(fetchPostsByUserId.pending, (state) => {
				state.loadingP = true;
				state.errorP = null;
			})
			.addCase(fetchPostsByUserId.fulfilled, (state, action) => {
				const { userId, items, pagination } = action.payload;
				state.loadingP = false;
				// on forme l'objet s'il n'existe pas
				if (!state.profilesItems[userId]) {
					state.profilesItems[userId] = {
						itemsPI: items,
						pagePI: 1,
						endDataPI: items.length === pagination?.total,
					};
				} else {
					// si profilesItems[userId] n'est pas vide on va éviter l'ajout de doublons
					// on récupère les id des posts existants
					const existingPostIds = new Set(
						state.profilesItems[userId]?.itemsPI?.map(
							(post) => post?.id
						)
					);
					// on garde uniquement les nouveaux posts qui ne sont pas déjà présents dans la liste
					const newProfilePosts = items?.filter(
						(post) => !existingPostIds.has(post?.id)
					);
					state.profilesItems[userId].itemsPI = [
						...state.profilesItems[userId]?.itemsPI,
						...newProfilePosts,
					];
					const endDataPI =
						state.profilesItems[userId]?.itemsPI?.length ===
						pagination?.total;
					const limitReached = items?.length === pagination?.limit;
					state.profilesItems[userId].endDataPI = endDataPI;
					// S'il reste des posts à charger on passe à la page suivante
					if (!endDataPI && limitReached) {
						state.profilesItems[userId].pagePI += 1;
					}
				}
			})
			.addCase(fetchPostsByUserId.rejected, (state, action) => {
				state.loadingP = false;
				state.errorP = action.payload;
			})
			.addCase(addPost.pending, (state) => {
				state.loadingP = true;
				state.errorP = null;
			})
			.addCase(addPost.fulfilled, (state, action) => {
				const { userId, post } = action.payload;
				state.loadingP = false;
				state.itemsP.unshift(post);
				if (userId) {
					state.profilesItems[userId]?.itemsPI?.unshift(post);
				}
			})
			.addCase(addPost.rejected, (state, action) => {
				state.loadingP = false;
				state.errorP = action.payload;
				if (action.payload?.status === 400) {
					state.errorP.message =
						"Une erreur est survenue lors de la création du post : media invalide";
				} else if (action.payload?.status === 406) {
					state.errorP.message =
						"Votre post doit être soumi à une phase d'analyse avant d'être publié";
				}
			})
			.addCase(likePost.pending, (state, action) => {
				const postId = action.meta.arg.postId;
				const userId = action.meta.arg?.userId;
				const post = state.itemsP.find((post) => post.id === postId);
				if (post) {
					post.loadingLike = true;
				}
				if (userId) {
					const profilePost = state.profilesItems[
						userId
					]?.itemsPI?.find((post) => post?.id === postId);
					if (profilePost) {
						profilePost.loadingLike = true;
					}
				}
				state.errorP = null;
			})
			.addCase(likePost.fulfilled, (state, action) => {
				const { postId, userId, nbLikes, userHasLiked } =
					action.payload;
				const post = state.itemsP.find((post) => post?.id === postId);
				if (post) {
					post.loadingLike = false;
					post.nbLikes = nbLikes;
					post.userHasLiked = userHasLiked;
				}
				if (userId) {
					const profilePost = state.profilesItems[
						userId
					]?.itemsPI?.find((post) => post?.id === postId);
					if (profilePost) {
						profilePost.loadingLike = false;
						profilePost.nbLikes = nbLikes;
						profilePost.userHasLiked = userHasLiked;
					}
				}
			})
			.addCase(likePost.rejected, (state, action) => {
				const postId = action.meta.arg.postId;
				const userId = action.meta.arg?.userId;
				const post = state.itemsP.find((post) => post?.id === postId);
				if (post) {
					post.loadingLike = false;
				}
				if (userId) {
					const profilePost = state.profilesItems[
						userId
					]?.itemsPI?.find((post) => post?.id === postId);
					if (profilePost) {
						profilePost.loadingLike = false;
					}
				}
				state.errorP = action.payload;
			})
			.addCase(deletePost.pending, (state) => {
				state.loadingP = true;
				state.errorP = null;
			})
			.addCase(deletePost.fulfilled, (state, action) => {
				const { postId, userId } = action.payload;
				state.loadingP = false;
				const index = state.itemsP?.findIndex(
					(post) => post?.id === postId
				);
				if (index !== -1) {
					state.itemsP.splice(index, 1);
				}
				if (userId) {
					const indexPI = state.profilesItems[
						userId
					]?.itemsPI.findIndex((post) => post?.id === postId);

					if (indexPI !== -1) {
						state.profilesItems[userId]?.itemsPI.splice(indexPI, 1);
					}
				}
			})
			.addCase(deletePost.rejected, (state, action) => {
				state.loadingP = false;
				state.errorP = action.payload;
			});
	},
});

export const {
	addOneComment,
	deleteOneComment,
	sortPosts,
	sortProfilePosts,
	cleanErrorP,
	cleanDataP,
} = postSlice.actions;

export default postSlice.reducer;
