import { Box, IconButton } from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import banner from "../../../assets/images/banner.png";
import BackBtn from "../Buttons/BackBtn";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";

const Banner = ({ bannerPicture }) => {
	const user = useSelector((state) => state.auth.user);
	const navigate = useNavigate();
	const { id } = useParams();
	return (
		<Box
			position={"relative"}
			width={"100%"}
			zIndex={1}
			sx={{ mt: { xs: 2, sm: 4, md: 4 } }}
		>
			<Box
				position={"absolute"}
				top={"12px"}
				left={"12px"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				borderRadius={"50%"}
				opacity={0.4}
				bgcolor={"white"}
				width={25}
				height={25}
				sx={{ cursor: "pointer" }}
			>
				<BackBtn />
			</Box>
			<Box
				component="img"
				src={bannerPicture || banner}
				alt="Bannière"
				width={"100%"}
				height={"auto"}
				sx={{
					objectFit: "cover",
				}}
			/>
			<Box
				position={"absolute"}
				top={"12px"}
				right={"12px"}
				display={"flex"}
				alignItems={"center"}
				justifyContent={"center"}
				borderRadius={"50%"}
				opacity={0.4}
				bgcolor={"white"}
				width={25}
				height={25}
				sx={{ cursor: "pointer" }}
			>
				{user?.id == id ? (
					<IconButton onClick={()=>{navigate('/settings')}}>
						<SettingsIcon sx={{width:20, height:20}} />
					</IconButton>
				) : (
					<IconButton >
						<MoreVertIcon sx={{width:20, height:20}}/>
					</IconButton>
				)}
			</Box>
		</Box>
	);
};

export default Banner;
