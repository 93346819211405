import { Grid, Paper, useMediaQuery } from "@mui/material";
import greenRoad from "../../assets/images/greenRoad.jpg";
import React, { useEffect, useState } from "react";
import SignUpSection from "../organisms/SignUpSection/SignUpSection";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	cleanError,
	redirect,
	registerUser,
} from "../../store/slices/authSlice";
import { toast } from "react-toastify";
import { theme } from "../../assets/themes/theme";

const SignUpPage = () => {
	document.title = "S'inscrire | Goodmove";
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [errors, setErrors] = useState({
		email: "",
		password: "",
		confirmPassword: "",
		firstName: "",
		lastName: "",
		global: "",
	});
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { loading, error, handleRedirect } = useSelector(
		(state) => state.auth
	);

	const handleSubmit = (e) => {
		let valid = true;
		let newErrors = {
			email: "",
			password: "",
			confirmPassword: "",
			firstName: "",
			lastName: "",
			global: "",
		};

		if (!email.includes("@")) {
			valid = false;
			newErrors.email = "L'email doit être valide.";
		}
		if (password.length < 6) {
			valid = false;
			newErrors.password =
				"Le mot de passe doit comporter au moins 6 caractères.";
		}
		if (password !== confirmPassword) {
			valid = false;
			newErrors.confirmPassword =
				"Les mots de passe ne correspondent pas.";
		}
		if (!firstName.trim()) {
			valid = false;
			newErrors.firstName = "Le prénom est requis.";
		} else if (!firstName.length > 30) {
			valid = false;
			newErrors.firstName =
				"Le prénom ne doit pas dépasser les 30 caractères";
		}

		if (!lastName.trim()) {
			valid = false;
			newErrors.lastName = "Le nom est requis.";
		} else if (!lastName.length > 30) {
			valid = false;
			newErrors.lastName =
				"Le nom de famille ne doit pas dépasser les 30 caractères";
		}
		setErrors(newErrors);
		if (valid) {
			e.preventDefault();
			dispatch(registerUser({ firstName, lastName, email, password }));
		} else {
			setPassword("");
			setConfirmPassword("");
		}
	};

	useEffect(() => {
		if (handleRedirect) {
			toast.success("Un mail de validation vient de vous être envoyé !", {
				toastId: "send-register-mail-success",
			});

			dispatch(redirect());
			navigate("/signin");
		}
	}, [handleRedirect]);

	useEffect(() => {
		dispatch(cleanError());
		setErrors({
			email: "",
			password: "",
			confirmPassword: "",
			firstName: "",
			lastName: "",
			global: "",
		});
	}, []);

	useEffect(() => {
		if (error) {
			setPassword("");
			setConfirmPassword("");
			if (error?.status === 409) {
				setErrors((prevErrors) => ({
					...prevErrors,
					global: "Compte déjà existant avec cet email",
				}));
			} else {
				setErrors((prevErrors) => ({
					...prevErrors,
					global: "Erreur interne",
				}));
			}
		}
	}, [error]);

	return (
		<Grid
			container
			component="main"
			p={isMobile ? 0 : 2}
			height={"100vh"}
			borderRadius={"7px"}
		>
			<Grid
				item
				xs={false}
				sm={5}
				md={7}
				component={Paper}
				elevation={10}
				sx={{
					backgroundImage: "url(" + greenRoad + ")",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
					borderRadius: "7px 0px 0px 7px",
				}}
			/>
			<SignUpSection
				email={email}
				setEmail={setEmail}
				password={password}
				setPassword={setPassword}
				confirmPassword={confirmPassword}
				setConfirmPassword={setConfirmPassword}
				firstName={firstName}
				lastName={lastName}
				setFirstName={setFirstName}
				setLastName={setLastName}
				loading={loading}
				errors={errors}
				setErrors={setErrors}
				handleSubmit={handleSubmit}
			/>
		</Grid>
	);
};

export default SignUpPage;
