import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ element: Component }) => {
	const { user } = useSelector((state) => state.auth);

	return user ? Component : <Navigate to="/signin" />;
};

export default ProtectedRoute;
