import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import GoodMoveLogo from "../../assets/images/logo_classic_outlined.svg";
import ResetPasswordForm from "../molecules/Forms/ResetPasswordForm";
import { useNavigate, useParams } from "react-router-dom";
import { requestAxiosResetPassword } from "../../services/axios/axiosRequests";
import LockResetIcon from "@mui/icons-material/LockReset";
import { toast } from "react-toastify";

const ResetPasswordPage = () => {
	document.title = "Réinitialisation du mot de passe | Goodmove";
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [errors, setErrors] = useState({
		password: "",
		confirmPassword: "",
		global: "",
	});
	const { token } = useParams();
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		let valid = true;
		setLoading(true);
		let newErrors = {
			olderpassword: "",
			password: "",
			confirmPassword: "",
			global: "",
		};

		if (password.length < 6) {
			valid = false;
			newErrors.password =
				"Le mot de passe doit comporter au moins 6 caractères.";
		}
		if (password !== confirmPassword) {
			valid = false;
			newErrors.confirmPassword =
				"Les mots de passe ne correspondent pas.";
		}

		if (valid) {
			e.preventDefault();
			try {
				await requestAxiosResetPassword(token, password);
				toast.success("Le nouveau mot de passe a été enregistré avec succès. ", {
					toastId: "reset-password-success",
				});
				navigate('/signin');
			} catch (error) {
				let msg;
				if (error?.code === 409) {
					msg =
						"Le nouveau mot de passe doit être différent du dernier.";
				} else if (error?.code === 410) {
					msg = "Session expirée.";
				} else {
					msg = "Erreur interne.";
				}
				toast.error(msg, {
					toastId: "reset-password-error",
				});
			}
		} else {
			setPassword("");
			setConfirmPassword("");
		}
		setErrors(newErrors);
		setLoading(false);
	};

	return (
		<Box
			minHeight={"100vh"}
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"center"}
			ml={2}
			mr={2}
		>
			<Box sx={{ pt: 2 }} ml={"auto"} mr={"auto"} mb={3}>
				<img
					alt="GoodMove"
					src={GoodMoveLogo}
					style={{ width: "300px", cursor: "pointer" }}
					onClick={() => {
						navigate("/signin");
					}}
				/>
			</Box>
			<Box
				ml={"auto"}
				mr={"auto"}
				width={"100%"}
				sx={{ maxWidth: "700px" }}
			>
				<Box display={"flex"} mb={1}>
					<Typography variant="h6">
						Changez votre mot de passe
					</Typography>
					<LockResetIcon sx={{ ml: 1, mt: "auto", mb: "auto" }} />
				</Box>

				<ResetPasswordForm
					password={password}
					confirmPassword={confirmPassword}
					setPassword={setPassword}
					setConfirmPassword={setConfirmPassword}
					handleSubmit={handleSubmit}
					loading={loading}
					errors={errors}
					setErrors={setErrors}
				/>
			</Box>
		</Box>
	);
};

export default ResetPasswordPage;
