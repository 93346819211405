import { Avatar, Box, Typography } from "@mui/material";
import { timeElapsed } from "../../../utils/tool";
import React from "react";
import { formatText } from "../../../utils/tool";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../assets/themes/theme";

const CommentShared = ({ comment }) => {
	const navigate = useNavigate();
	const timeElapsedStr = timeElapsed(comment?.createdAt);


	return (
		<Box display={"flex"} justifyContent={"space-between"} mt={1}>
			<Box flex={1} mr={1} onClick={() => navigate("/profile/"+comment?.user?.id)}>
				<Avatar
					alt="Profile picture"
					src={comment?.user?.profilePicture}
					sx={{ borderRadius: 1, ml: "auto", mr: "auto", mt : 0.5, cursor:'pointer' }}
				/>
			</Box>
			<Box flex={9}>
				<Box display={"flex"} justifyContent={"space-evenly"}>
					<Box flex={8}>
						<Typography>
							<b>{comment?.user?.username || "unknown"} </b>
							{formatText(comment?.content)}
						</Typography>
						<Box
							display={"flex"}
							justifyContent={"left"}
							flexWrap={"wrap"}
							mb={1}
						>
							<Typography
								mr={2}
								color={theme.palette.primary.main}
								fontWeight={500}
							>
								{timeElapsedStr}
							</Typography>
							<Typography
								mr={2}
								ml={1}
							>
								{comment?.nbLikes || 0} J'aimes
							</Typography>
						</Box>
					</Box>
				</Box>
				{/* {comment?.nbReplies > 0 && (
					<Box display={"flex"}>
						<Typography
							variant="body2"
							color="secondary.ultradark"
							textAlign={"left"}
							sx={{
								mt: 1,
								cursor: "pointer",
							}}
						>
							{"--- " + comment?.nbReplies + " réponses"}
						</Typography>
					</Box>
				)} */}
			</Box>
		</Box>
	);
};

export default CommentShared;
