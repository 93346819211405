import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/system";
import CustomTabCard from "../../atoms/Tab/CustomTabCard";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import NoAccountsOutlinedIcon from "@mui/icons-material/NoAccountsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

const SettingsTabs = () => {
	const [activeTab, setActiveTab] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams();

	const handleTabChange = (event, newValue) => {
		switch (newValue) {
			case 1:
				setSearchParams("filter=security");
				break;
			case 2:
				setSearchParams("filter=theme");
				break;
			case 3:
				setSearchParams("filter=lang");
				break;
			case 4:
				setSearchParams("filter=reported");
				break;
			case 5:
				setSearchParams("filter=help");
				break;
			case 6:
				setSearchParams("filter=delete");
				break;
			default:
				setSearchParams("");
				break;
		}
	};

	useEffect(() => {
		const filterParam = searchParams.get("filter");
		switch (filterParam) {
			case "security":
				return setActiveTab(1);
			case "theme":
				return setActiveTab(2);
			case "lang":
				return setActiveTab(3);
			case "reported":
				return setActiveTab(4);
			case "help":
				return setActiveTab(5);
			case "delete":
				return setActiveTab(6);
			default:
				return setActiveTab(0);
		}
	}, [searchParams]);

	return (
		<Box mr={5}>
			<Box display={"flex"} justifyContent={"center"} mb={2}>
				<Tabs
					orientation="vertical"
					value={activeTab}
					onChange={handleTabChange}
					aria-label="navigation tabs"
					variant="scrollable"
					scrollButtons="auto"
				>
					<Tab
						value={0}
						icon={
							<CustomTabCard
								icon={AccountCircleOutlinedIcon}
								label="Modifier le profil"
							/>
						}
						sx={{maxWidth:'none'}}
					/>
					<Tab
						value={1}
						icon={
							<CustomTabCard
								icon={LockResetOutlinedIcon}
								label="Changer le mot de passe"
							/>
						}
						sx={{maxWidth:'none'}}
					/>
					<Tab
						value={2}
						icon={
							<CustomTabCard
								icon={LightModeOutlinedIcon}
								label="Changer l'apparence"
							/>
						}
						sx={{maxWidth:'none'}}
					/>
					<Tab
						value={3}
						icon={
							<CustomTabCard
								icon={TranslateOutlinedIcon}
								label="Changer la langue"
							/>
						}
						sx={{maxWidth:'none'}}
					/>
					<Tab
						value={4}
						icon={
							<CustomTabCard
								icon={NoAccountsOutlinedIcon}
								label="Gérer les comptes bloqués"
							/>
						}
						sx={{maxWidth:'none'}}
						
					/>
					<Tab
						value={5}
						icon={
							<CustomTabCard
								icon={HelpOutlineOutlinedIcon}
								label="Obtenir de l'aide"
							/>
						}
						sx={{maxWidth:'none'}}
					/>
					<Tab
						value={6}
						icon={
							<CustomTabCard
								icon={RemoveCircleOutlineOutlinedIcon}
								label="Supprimer son compte"
							/>
						}
						sx={{maxWidth:'none'}}
					/>
				</Tabs>
			</Box>
		</Box>
	);
};

export default SettingsTabs;
