import { Grid, Paper, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import greenRoad from "../../assets/images/greenRoad.jpg";
import ForgotSection from "../organisms/ForgotSection/ForgotSection";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { theme } from "../../assets/themes/theme";
import { cleanError } from "../../store/slices/authSlice";
import { useDispatch } from "react-redux";
import { requestAxiosSendMailResetPassword } from "../../services/axios/axiosRequests";

const ForgotPasswordPage = () => {
	document.title = "Mot de passe oublié | Goodmove";
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [email, setEmail] = useState("");
	const [error, setError] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSubmit = async () => {
		let valid = true;
		let newError = "";
		if (!email.includes("@")) {
			valid = false;
		}

		if (valid) {
			try {
				await requestAxiosSendMailResetPassword(email);
				toast.success("Mail de récupération envoyé à " + email + " !", {
					toastId: "send-mail-success",
				});
				navigate("/signin");
			} catch (error) {
				newError = error?.message || "Echec de l'envoie de mail.";
			}
		}
		setError(newError);
	};

	const goBack = () => {
		navigate(-1);
	};

	useEffect(() => {
		dispatch(cleanError());
	}, []);

	return (
		<Grid
			container
			component="main"
			p={isMobile ? 0 : 2}
			height={"100vh"}
			borderRadius={"7px"}
		>
			<Grid
				item
				xs={false}
				sm={5}
				md={7}
				component={Paper}
				elevation={isMobile ? 0 : 10}
				sx={{
					backgroundImage: "url(" + greenRoad + ")",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
					backgroundPosition: "center",
					borderRadius: "7px 0px 0px 7px",
				}}
			/>
			<ForgotSection
				email={email}
				setEmail={setEmail}
				handleSubmit={handleSubmit}
				goBack={goBack}
				error={error}
			/>
		</Grid>
	);
};

export default ForgotPasswordPage;
