import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/themes/theme";
import { Badge, CardMedia, Tab, Tabs, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, useMediaQuery } from "@mui/system";
import notifGif from "../../../assets/videos/notification.gif";
import { useDispatch, useSelector } from "react-redux";
import NotifCard from "../../molecules/Card/NotifCard";
import { timeElapsed } from "../../../utils/tool";
import PostComponentDetails from "../PostComponent/PostComponentDetails";
import OptionsModal from "../Modal/OptionsModal";
import { toast } from "react-toastify";
import { cleanDataP, deletePost } from "../../../store/slices/postSlice";
import ReportModal from "../Modal/ReportModal";

const NotifsTabs = ({ data, loadingData }) => {
	const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [activeTab, setActiveTab] = useState(0);
	const [openPost, setOpenPost] = useState(false);
	const [openOptions, setOpenOptions] = useState(false);
	const [openReport, setOpenReport] = useState(false);
	const [optionsIsAuthor, setOptionsIsAuthor] = useState(false);
	const [postIdDetails, setPostIdDetails] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);
	const { nbNewsNotifs, loadingN } = useSelector((state) => state.notifs);

	const handleTabChange = (event, newValue) => {
		switch (newValue) {
			case 1:
				setSearchParams("filter=publications");
				break;
			case 2:
				setSearchParams("filter=relations");
				break;
			case 3:
				setSearchParams("filter=trophies");
				break;
			default:
				setSearchParams("");
				break;
		}
	};

	const handleCloseOptions = () => {
		setOpenOptions(false);
		setOptionsIsAuthor(false);
	};

	const handleOpenOptions = (isAuthor) => {
		setOptionsIsAuthor(isAuthor);
		setOpenOptions(true);
	};

	const handleCloseDetails = () => {
		setOptionsIsAuthor(false);
		setPostIdDetails(false);
		setOpenPost(false);
		dispatch(cleanDataP());
	};

	const handleOpenDetails = (postId, isAuthor) => {
		setOptionsIsAuthor(isAuthor);
		setPostIdDetails(postId);
		setOpenPost(true);
	};

	const handleOptionsDelete = () => {
		if (optionsIsAuthor) {
			dispatch(
				deletePost({ postId: postIdDetails, userId: auth?.user.id })
			);
			toast.success("Post supprimé", {
				toastId: "delete-post-success-" + postIdDetails,
			});
		}
		handleCloseDetails();
		handleCloseOptions();
	};

	const handleOptionsReport = () => {
		setOpenReport(true);
		handleCloseOptions();
	};

	useEffect(() => {
		const filterParam = searchParams.get("filter");
		switch (filterParam) {
			case "publications":
				return setActiveTab(1);
			case "relations":
				return setActiveTab(2);
			case "trophies":
				return setActiveTab(3);
			default:
				return setActiveTab(0);
		}
	}, [searchParams]);

	return (
		<Box>
			<Box
				display={"flex"}
				justifyContent={"center"}
				mb={3}
				sx={{
					maxWidth: { xs: 300, sm: 400, md: 700 },
					ml: "auto",
					mr: "auto",
				}}
			>
				<Tabs
					value={activeTab}
					onChange={handleTabChange}
					aria-label="navigation tabs"
					variant="scrollable"
					scrollButtons="auto"
					allowScrollButtonsMobile
				>
					<Tab
						label={
							nbNewsNotifs ? (
								<Badge
									badgeContent={nbNewsNotifs}
									color="error"
									sx={{
										"& .MuiBadge-badge": {
											right: -10,
											top: 7,
										},
									}}
								>
									<Typography
										sx={{
											textTransform: "uppercase",
											fontSize: "0.875rem",
											fontWeight: 700,
											lineHeight: 1.25,
											letterSpacing: "0.02857em",
										}}
									>
										Tout
									</Typography>
								</Badge>
							) : (
								"Tout"
							)
						}
						value={0}
					/>
					<Tab label="Publications" value={1} />
					<Tab label="Relations" value={2} />
					<Tab label="Trophées" value={3} />
				</Tabs>
			</Box>
			{loadingData ? (
				<Box
					display={"flex"}
					justifyContent={"center"}
					flexDirection={"column"}
				>
					<CardMedia
						component="img"
						image={notifGif}
						alt="wait-ro-search"
						sx={{
							width: "80px",
							height: "80px",
							ml: "auto",
							mr: "auto",
							mt: 8,
						}}
					/>
					<Typography
						textAlign={"center"}
						mt={3}
						fontWeight={500}
						color={theme.palette.otherColor.main}
					>
						Chargement des notifications ...
					</Typography>
				</Box>
			) : data && data.length > 0 ? (
				data.map((notif) => {
					switch (notif?.type) {
						case "NEW_FOLLOW":
							return (
								<NotifCard
									key={notif?.id}
									id={notif?.id}
									avatar={
										notif?.data?.follower?.profilePicture
									}
									title={
										notif?.data?.follower?.username ||
										"unknown"
									}
									description={
										"vient de s'abonner à votre compte."
									}
									readAt={notif?.readAt}
									createdAt={timeElapsed(notif?.createdAt)}
									onClickNotif={() =>
										navigate(
											"/profile/" +
												notif?.data?.follower?.id
										)
									}
									handleClickAvatar={() => {}}
									css={{ cursor: "pointer" }}
									loadingN={loadingN}
								/>
							);
						case "NEW_COMMENT":
							return (
								<NotifCard
									key={notif?.id}
									id={notif?.id}
									avatar={
										notif?.data?.commentedBy?.profilePicture
									}
									title={
										notif?.data?.commentedBy?.username ||
										"unknown"
									}
									description={
										"a commenté votre publication."
									}
									readAt={notif?.readAt}
									createdAt={timeElapsed(notif?.createdAt)}
									onClickNotif={() =>
										handleOpenDetails(
											notif?.data?.socialPost?.id,
											notif?.data?.socialPost?.user
												?.id === auth?.user?.id
										)
									}
									handleClickAvatar={() =>
										navigate(
											"/profile/" +
												notif?.data?.commentedBy?.id
										)
									}
									css={{ cursor: "pointer" }}
									loadingN={loadingN}
								/>
							);
						case "NEW_COMPLETED_CHALLENGE":
							return (
								<NotifCard
									key={notif?.id}
									id={notif?.id}
									title={"Trophée obtenu : "}
									description={
										notif?.data?.completedChallenge
											?.challenge?.name
									}
									readAt={notif?.readAt}
									createdAt={timeElapsed(notif?.createdAt)}
									isTrophy={true}
									onClickNotif={() =>
										navigate(
											"/profile/" +
												auth?.user?.id +
												"?filter=trophies"
										)
									}
									handleClickAvatar={() => {}}
									css={{ cursor: "pointer" }}
									loadingN={loadingN}
								/>
							);
						case "NEW_SOCIALPOST_LIKE":
							return (
								<NotifCard
									key={notif?.id}
									id={notif?.id}
									avatar={
										notif?.data?.likedBy?.profilePicture
									}
									title={
										notif?.data?.likedBy?.username ||
										"unknown"
									}
									description={"a aimé votre publication."}
									readAt={notif?.readAt}
									createdAt={timeElapsed(notif?.createdAt)}
									onClickNotif={() =>
										handleOpenDetails(
											notif?.data?.socialPost?.id,
											notif?.data?.socialPost?.user
												?.id === auth?.user?.id
										)
									}
									handleClickAvatar={() =>
										navigate(
											"/profile/" +
												notif?.data?.likedBy?.id
										)
									}
									css={{ cursor: "pointer" }}
									loadingN={loadingN}
								/>
							);
						case "NEW_COMMENT_LIKE":
							return (
								<NotifCard
									key={notif?.id}
									id={notif?.id}
									avatar={
										notif?.data?.likedBy?.profilePicture
									}
									title={notif?.data?.likedBy?.username}
									description={"a aimé votre commentaire."}
									readAt={notif?.readAt}
									createdAt={timeElapsed(notif?.createdAt)}
									onClickNotif={() =>
										handleOpenDetails(
											notif?.data?.socialPost?.id,
											notif?.data?.socialPost?.user
												?.id === auth?.user?.id
										)
									}
									css={{ cursor: "pointer" }}
									handleClickAvatar={() =>
										navigate(
											"/profile/" +
												notif?.data?.likedBy?.id
										)
									}
									loadingN={loadingN}
								/>
							);
						default:
					}
				})
			) : (
				<Box
					display={"flex"}
					justifyContent={"center"}
					flexDirection={"column"}
				>
					<CardMedia
						component="img"
						image={notifGif}
						alt="wait-ro-search"
						sx={{
							width: "80px",
							height: "80px",
							ml: "auto",
							mr: "auto",
							mt: 8,
						}}
					/>
					<Typography
						textAlign={"center"}
						mt={3}
						fontWeight={500}
						color={theme.palette.otherColor.main}
					>
						Aucune notification disponible pour le moment.
					</Typography>
				</Box>
			)}
			<PostComponentDetails
				open={openPost}
				handleClose={handleCloseDetails}
				handleOpenOptions={handleOpenOptions}
				postId={postIdDetails}
				context={"notifications"}
			/>
			<OptionsModal
				postId={postIdDetails}
				open={openOptions}
				handleClose={handleCloseOptions}
				handleDelete={handleOptionsDelete}
				handleReport={handleOptionsReport}
				allAccess={optionsIsAuthor}
			/>
			<ReportModal
				open={openReport}
				postId={postIdDetails}
				handleClose={() => {
					setOpenReport(false);
				}}
			/>
		</Box>
	);
};

export default NotifsTabs;
