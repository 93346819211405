import { Box, TextField, Typography } from "@mui/material";
import React from "react";

const InputCustom = ({
	value,
	errors,
	label,
	entityName,
	setErrors,
	setValue,
}) => {
	return (
		<Box sx={{ mb: 2, width:"100%" }}>
			<TextField
				required
				fullWidth
				id={"outlined-required-" + entityName}
				label={label}
				value={value}
				onChange={(e) => {
					if (errors && errors[entityName]) {
						let cleanError = { ...errors };
						cleanError[entityName] = "";
						setErrors(cleanError);
					}
					setValue(e.target.value);
				}}
				error={errors && !!errors[entityName]}
				sx={{
					boxShadow: "1px 1px 4px rgba(85, 85, 85, 0.25)",
					maxWidth: "700px",
					ml: "auto",
					mr: "auto",
				}}
			/>

			{errors && errors[entityName] && (
				<Typography color="error" fontSize={11} sx={{ ml: 2, mt: 0.5 }}>
					{errors[entityName]}
				</Typography>
			)}
		</Box>
	);
};

export default InputCustom;
