import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import GoodMoveLogo from "../../../assets/images/logo_classic_outlined.svg";
import SignUpForm from "../../molecules/Forms/SignUpForm";
import { Link, useNavigate } from "react-router-dom";
import { theme } from "../../../assets/themes/theme";
import { useDispatch } from "react-redux";
import { cleanError } from "../../../store/slices/authSlice";

const SignUpSection = ({
	email,
	setEmail,
	password,
	setPassword,
	confirmPassword,
	setConfirmPassword,
	firstName,
	lastName,
	setFirstName,
	setLastName,
	loading,
	errors,
	setErrors,
	handleSubmit,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const SXBox = {
		ml: "auto",
		mr: "auto",
		mb: 5,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		maxWidth: "500px",
		width: "100%",
	};

	const SXBoxTypo = {
		ml: "auto",
		mr: "auto",
		display: "flex",
		flexWrap: "wrap",

		justifyContent: "center",
		maxWidth: "500px",
		width: "100%",
	};

	return (
		<Grid
			item
			xs={12}
			sm={7}
			md={5}
			component={Paper}
			elevation={10}
			square
			sx={{
				borderRadius: "0px 7px 7px 0px",
				pl: 2,
				pr: 2,
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<Box sx={SXBox}>
				<img alt="GoodMove" src={GoodMoveLogo} />
			</Box>
			<Box sx={SXBox}>
				<SignUpForm
					email={email}
					password={password}
					firstName={firstName}
					lastName={lastName}
					confirmPassword={confirmPassword}
					setEmail={setEmail}
					setPassword={setPassword}
					setConfirmPassword={setConfirmPassword}
					setFirstName={setFirstName}
					setLastName={setLastName}
					handleSubmit={handleSubmit}
					loading={loading}
					errors={errors}
					setErrors={setErrors}
				/>
			</Box>
			<Box sx={SXBoxTypo}>
				<Typography sx={{ mr: 1 }}>
					Vous avez déjà un compte ?
				</Typography>
				<Typography
					onClick={() => {
						dispatch(cleanError());
						navigate("/signup");
					}}
					sx={{ cursor: "pointer", textDecoration: "underline" }}
					color={theme.palette.primary.main}
				>
					Se connecter
				</Typography>
			</Box>
		</Grid>
	);
};

export default SignUpSection;
