import { Box, Button, Modal, Typography, useMediaQuery } from "@mui/material";
import SettingsTabs from "../../molecules/Tabs/SettingsTabs";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { theme } from "../../../assets/themes/theme";
import BackBtn from "../../atoms/Buttons/BackBtn";
import EditProfileCard from "../../molecules/Card/EditProfileCard";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import ResetPasswordCard from "../../molecules/Card/ResetPasswordCard";
import SwitchCard from "../../molecules/Card/SwitchCard";
import RadioCard from "../../molecules/Card/RadioCard";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import HelpCard from "../../molecules/Card/HelpCard";
import ListUsersCard from "../../molecules/Card/ListUsersCard";
import DeleteAccountCard from "../../molecules/Card/DeleteAccountCard";
import CustomTabCard from "../../atoms/Tab/CustomTabCard";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import NoAccountsOutlinedIcon from "@mui/icons-material/NoAccountsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import { useDispatch, useSelector } from "react-redux";
import { cleanError, updateConfig } from "../../../store/slices/authSlice";
import { toast } from "react-toastify";

const SettingsSection = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [activeTab, setActiveTab] = useState(0);
	const [activeDarkMode, setActiveDarkMode] = useState(false);
	const [openSettingsModal, setOpenSettingsModal] = useState(false);
	const [waitUpdated, setWaitUpdated] = useState(false);
	const [activeRadio, setActiveRadio] = useState("");

	const mediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
	const configs = useSelector((state) => state.auth.user.configurations);
	const dispatch = useDispatch();

	const { loading, error } = useSelector((state) => state.auth);

	useEffect(() => {
		const filterParam = searchParams.get("filter");
		switch (filterParam) {
			case "security":
				return setActiveTab(1);
			case "theme":
				return setActiveTab(2);
			case "lang":
				return setActiveTab(3);
			case "reported":
				return setActiveTab(4);
			case "help":
				return setActiveTab(5);
			case "delete":
				return setActiveTab(6);
			default:
				return setActiveTab(0);
		}
	}, [searchParams]);

	const handleClickOpen = (newValue) => {
		switch (newValue) {
			case 1:
				setSearchParams("filter=security");
				break;
			case 2:
				setSearchParams("filter=theme");
				break;
			case 3:
				setSearchParams("filter=lang");
				break;
			case 4:
				setSearchParams("filter=reported");
				break;
			case 5:
				setSearchParams("filter=help");
				break;
			case 6:
				setSearchParams("filter=delete");
				break;
			default:
				setSearchParams("");
				break;
		}
		setOpenSettingsModal(true);
	};

	const handeSave = (configurationKey, value) => {
		dispatch(updateConfig({ configurationKey, value }));
		setWaitUpdated(true);
	};

	useEffect(() => {
		if (configs) {
			setActiveDarkMode(
				configs?.find(
					(config) => config?.configurationKey === "APP_THEME"
				)?.value === "dark"
			);
			setActiveRadio(
				configs?.find(
					(config) => config?.configurationKey === "APP_LANGUAGE"
				)?.value
			);
		} else {
			console.log("Problème de chargement de la configuration.");
		}
	}, [activeTab]);

	useEffect(() => {
		if (waitUpdated && !loading && !error) {
			setWaitUpdated(false);
			toast.success("Paramètres modifiés avec succès.", {
				toastId: "settings-update-error",
			});
		} else if (waitUpdated && !loading && error) {
			setWaitUpdated(false);
			toast.error("La mise à jour des paramètres n'a pas pu aboutir", {
				toastId: "settings-update-error",
			});
			dispatch(cleanError());
		}
	}, [configs]);

	return (
		<Box
			width={"100%"}
			ml={2}
			mr={2}
			sx={{ mb: { xs: 10, sm: 2, md: 2, lg: 2, xl: 2 } }}
		>
			<Box display={"flex"} m={2}>
				<BackBtn />
				<Typography variant="h4" sx={{ mt: "auto", mb: "auto", ml: 2 }}>
					Paramètres
				</Typography>
			</Box>
			{mediumScreen ? (
				<Box width={"100%"}>
					<Button
						fullWidth
						color="otherColor"
						onClick={() => handleClickOpen(0)}
					>
						<CustomTabCard
							icon={AccountCircleOutlinedIcon}
							label="Modifier le profil"
						/>
					</Button>
					<Button
						fullWidth
						color="otherColor"
						onClick={() => handleClickOpen(1)}
					>
						<CustomTabCard
							icon={LockResetOutlinedIcon}
							label="Changer le mot de passe"
						/>
					</Button>
					<Button
						fullWidth
						color="otherColor"
						onClick={() => handleClickOpen(2)}
					>
						<CustomTabCard
							icon={LightModeOutlinedIcon}
							label="Modifier le thème"
						/>
					</Button>
					<Button
						fullWidth
						color="otherColor"
						onClick={() => handleClickOpen(3)}
					>
						<CustomTabCard
							icon={TranslateOutlinedIcon}
							label="Changer la langue"
						/>
					</Button>
					<Button
						fullWidth
						color="otherColor"
						onClick={() => handleClickOpen(4)}
					>
						<CustomTabCard
							icon={NoAccountsOutlinedIcon}
							label="Gérer les comptes bloqués"
						/>
					</Button>
					<Button
						fullWidth
						color="otherColor"
						onClick={() => handleClickOpen(5)}
					>
						<CustomTabCard
							icon={HelpOutlineOutlinedIcon}
							label="Obtenir de l'aide"
						/>
					</Button>
					<Button
						fullWidth
						color="otherColor"
						onClick={() => handleClickOpen(6)}
					>
						<CustomTabCard
							icon={RemoveCircleOutlineOutlinedIcon}
							label="Supprimer son compte"
						/>
					</Button>
					<Modal
						open={openSettingsModal}
						onClose={() => setOpenSettingsModal(false)}
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
						}}
					>
						<Box width={"100%"} height={"100%"}>
							{activeTab === 0 && (
								<EditProfileCard
									loading={waitUpdated}
									handleClose={() =>
										setOpenSettingsModal(false)
									}
								/>
							)}
							{activeTab === 1 && (
								<ResetPasswordCard
									loading={waitUpdated}
									handleClose={() =>
										setOpenSettingsModal(false)
									}
								/>
							)}
							{activeTab === 2 && (
								<SwitchCard
									loading={waitUpdated}
									active={activeDarkMode}
									onChange={() =>
										setActiveDarkMode(!activeDarkMode)
									}
									description={
										"Changer le thème global de votre application"
									}
									handeSave={() =>
										handeSave(
											"APP_THEME",
											activeDarkMode ? "dark" : "light"
										)
									}
									icon={LightModeOutlinedIcon}
									subtitle={"Mode sombre"}
									title={"Changer l'apparence"}
									handleClose={() =>
										setOpenSettingsModal(false)
									}
								/>
							)}
							{activeTab === 3 && (
								<RadioCard
									loading={waitUpdated}
									activeRadio={activeRadio}
									setActiveRadio={setActiveRadio}
									onChange={() =>
										setActiveDarkMode(!activeDarkMode)
									}
									description={
										"Changer la langue globale de votre application"
									}
									handeSave={() =>
										handeSave("APP_LANGUAGE", activeRadio)
									}
									icon={TranslateOutlinedIcon}
									subtitle={
										"Choisir la langue de l'application"
									}
									title={"Changer la langue"}
									handleClose={() =>
										setOpenSettingsModal(false)
									}
									disabled={true}
									disabledMessage={"Fonctionnalité bientôt disponible sur GoodMove"}
								/>
							)}
							{activeTab === 4 && (
								<ListUsersCard
									description={
										"Bloquer un utilisateur revient à empécher un utilisateur de visualiser votre profil, de vous contacter et de vous ajouter en tant que relation."
									}
									icon={TranslateOutlinedIcon}
									subtitle={"Liste des utilisateurs bloqués"}
									title={"Gérer les comptes bloqués"}
									users={[]}
									handleClose={() =>
										setOpenSettingsModal(false)
									}
								/>
							)}
							{activeTab === 5 && (
								<HelpCard
									handleClose={() =>
										setOpenSettingsModal(false)
									}
								/>
							)}
							{activeTab === 6 && (
								<DeleteAccountCard
									loading={waitUpdated}
									handleClose={() =>
										setOpenSettingsModal(false)
									}
									disabled={true}
									disabledMessage={"Fonctionnalité bientôt disponible sur GoodMove"}
								/>
							)}
						</Box>
					</Modal>
				</Box>
			) : (
				<Box display={"flex"} justifyContent={"space-between"}>
					<SettingsTabs />
					{activeTab === 0 && (
						<EditProfileCard loading={waitUpdated} />
					)}
					{activeTab === 1 && (
						<ResetPasswordCard loading={waitUpdated} />
					)}
					{activeTab === 2 && (
						<SwitchCard
							loading={waitUpdated}
							active={activeDarkMode}
							onChange={() => setActiveDarkMode(!activeDarkMode)}
							description={
								"Changer le thème global de votre application"
							}
							handeSave={() =>
								handeSave(
									"APP_THEME",
									activeDarkMode ? "dark" : "light"
								)
							}
							icon={LightModeOutlinedIcon}
							subtitle={"Mode sombre"}
							title={"Changer l'apparence"}
						/>
					)}
					{activeTab === 3 && (
						<RadioCard
							loading={waitUpdated}
							activeRadio={activeRadio}
							setActiveRadio={setActiveRadio}
							onChange={() => setActiveDarkMode(!activeDarkMode)}
							description={
								"Changer la langue globale de votre application"
							}
							handeSave={() =>
								handeSave("APP_LANGUAGE", activeRadio)
							}
							icon={TranslateOutlinedIcon}
							subtitle={"Choisir la langue de l'application"}
							title={"Changer la langue"}
						/>
					)}
					{activeTab === 4 && (
						<ListUsersCard
							description={
								"Bloquer un utilisateur revient à empécher un utilisateur de visualiser votre profil, de vous contacter et de vous ajouter en tant que relation."
							}
							icon={TranslateOutlinedIcon}
							subtitle={"Liste des utilisateurs bloqués"}
							title={"Gérer les comptes bloqués"}
							users={[]}
						/>
					)}
					{activeTab === 5 && <HelpCard />}
					{activeTab === 6 && (
						<DeleteAccountCard loading={waitUpdated} />
					)}
				</Box>
			)}
		</Box>
	);
};

export default SettingsSection;
