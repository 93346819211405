import axiosInstance from "./axiosInstance";

//##############LINKEDIN REQUESTS
export const requestAxiosLinkedinAccessToken = async (code) => {
	try {
		const response = await axiosInstance.post("/proxy/linkedin-access", {
			code: code,
		});

		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosLinkedinUserInfo = async (accessToken) => {
	try {
		const response = await axiosInstance.post("/proxy/linkedin-userinfo", {
			accessToken,
		});
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

//############## AUTH REQUESTS
export const requestAxiosLogin = async ({ username, password }) => {
	try {
		const response = await axiosInstance.post("/auth/login", {
			username,
			password,
		});
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosGetMe = async () => {
	try {
		const response = await axiosInstance.get("/users/getme");
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosRegister = async ({
	firstName,
	lastName,
	email,
	password,
}) => {
	try {
		const response = await axiosInstance.post("/auth/register", {
			firstName,
			lastName,
			email,
			password,
			provider: "APP",
		});
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosEndOfRegister = async (userId, username, birthday) => {
	try {
		const response = await axiosInstance.patch("/users/" + userId, {
			username: username,
			birthday: birthday,
		});
		return response.data;
	} catch (error) {
		throw error.response
			? { data: error.response.data, code: error.response.status }
			: error;
	}
};

export const requestAxiosValidateRegister = async (token) => {
	try {
		await axiosInstance.patch("/auth/validate/" + token);
		return;
	} catch (error) {
		throw error.response
			? { data: error.response.data, code: error.response.status }
			: error;
	}
};

export const requestAxiosOauth = async ({
	firstName,
	lastName,
	email,
	avatar,
}) => {
	try {
		const response = await axiosInstance.post("/auth/oauth", {
			email,
			firstName,
			lastName,
			profilePicture: avatar,
			provider: "LINKEDIN",
		});

		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosSendMailResetPassword = async (email) => {
	try {
		await axiosInstance.post("/reset-password", {
			email: email,
		});
		return;
	} catch (error) {
		let msg;
		if (error?.response?.status === 400) {
			msg = "Erreur detectée lors de l'envoie de mail.";
		} else if (error?.response?.status === 404) {
			msg = "Aucun compte classique n'est associé avec cet email.";
		} else {
			msg = "Erreur interne.";
		}
		throw error.response
			? { message: msg, code: error?.response?.status }
			: error;
	}
};

export const requestAxiosResetPassword = async (token, password) => {
	try {
		await axiosInstance.patch("/reset-password/" + token, {
			password: password,
		});
		return;
	} catch (error) {
		throw error.response
			? { message: error.response.data, code: error.response.status }
			: error;
	}
};

export const requestAxiosResetPasswordFromConfig = async (
	oldpassword,
	newpassword
) => {
	try {
		await axiosInstance.patch("/reset-password/fromparam", {
			oldpassword: oldpassword,
			newpassword: newpassword,
		});
		return;
	} catch (error) {
		throw error.response
			? { message: error.response.data, code: error.response.status }
			: error;
	}
};

//############## POSTS REQUESTS
export const requestAxiosGetPosts = async ({ page, limit, filter }) => {
	try {
		let req = "/posts?page=" + page + "&limit=" + limit;
		if (filter && filter !== "all" && filter !== "follow") {
			req += "&tags=" + filter;
		} else if (filter && filter === "follow") {
			req += "&relations=1";
		}
		const response = await axiosInstance.get(req);
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosGetPostsFollowingsUsers = async ({ page, limit }) => {
	try {
		const response = await axiosInstance.get(
			"/posts/relations?page=" + page + "&limit=" + limit
		);
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosGetPostById = async (postId) => {
	try {
		const response = await axiosInstance.get("/posts/" + postId);
		return response.data;
	} catch (error) {
		throw error.response ? error.response : error;
	}
};

export const requestAxiosCreatePost = async ({ content, imgs, tags }) => {
	try {
		const formData = new FormData();
		formData.append("description", content);
		tags.forEach((tag) => formData.append("tags[]", tag?.id));
		// Append images to FormData
		imgs.forEach((img, index) => {
			if (img && img.src && img.src.file instanceof File) {
				const file = img.src.file;
				if (file.size > 10 * 1024 * 1024) {
					// Limite de 10 MB pour les tests
					console.warn(
						`File at index ${index} is too large: ${file.size}`
					);
				} else {
					console.log(
						`Appending file: ${file.name}, size: ${file.size}, type: ${file.type}`
					);
					formData.append("files[]", file);
				}
			} else {
				console.warn(
					`Image at index ${index} is not a valid file or has an incorrect structure`,
					img
				);
			}
		});

		const response = await axiosInstance.post("/posts", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		return response.data;
	} catch (error) {
		throw error.response ? error.response : error;
	}
};

export const requestAxiosEditPost = async ({ postId, content, imgs, tags }) => {
	try {
		const formData = new FormData();
		formData.append("description", content);
		tags.forEach((tag) => formData.append("tags", tag));

		// Append images to FormData
		imgs.forEach((img, index) => {
			formData.append(`files[${index}]`, img);
		});

		const response = await axiosInstance.post(
			"/posts/" + postId,
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);

		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosDeletePost = async (postId) => {
	try {
		await axiosInstance.delete("/posts/" + postId);
		return;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosLikePost = async (postId) => {
	try {
		const response = await axiosInstance.post(
			"/posts/" + postId + "/likes"
		);
		return {
			nbLikes: response?.data?.nbLikes || 0,
			userHasLiked: response?.data?.userHasLiked || false,
		};
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosGetSharedPost = async (uuid) => {
	try {
		const response = await axiosInstance.get("posts/shared-post/" + uuid);
		return response?.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

//############## TAGS REQUESTS

export const requestAxiosGetTags = async () => {
	try {
		const response = await axiosInstance.get("/tags/");
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

//############## COMMENTS REQUESTS
export const requestAxiosAddComment = async ({
	content,
	commentType,
	replyTo,
	postId,
}) => {
	try {
		const response = await axiosInstance.post(
			"/posts/" + postId + "/comments",
			{
				content,
				commentType,
				// replyTo,
			}
		);
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosGetComments = async ({ postId, page, limit }) => {
	try {
		const response = await axiosInstance.get(
			"/posts/" + postId + "/comments?page=" + page + "&limit=" + limit
		);
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosEditComment = async ({
	postId,
	commentId,
	content,
	commentType,
}) => {
	try {
		const response = await axiosInstance.put(
			"/posts/" + postId + "/comments/" + commentId,
			{
				content,
				commentType,
			}
		);
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosDeleteComment = async ({ postId, commentId }) => {
	try {
		const response = await axiosInstance.delete(
			"/posts/" + postId + "/comments/" + commentId
		);
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosLikeComment = async (postId, commentId) => {
	try {
		const response = await axiosInstance.post(
			"/posts/" + postId + "/comments/" + commentId + "/likes"
		);
		return {
			nbLikes: response?.data?.nbLikes || 0,
			userHasLiked: response?.data?.userHasLiked || false,
		};
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosGetSharedPostComments = async (uuid, page) => {
	try {
		const response = await axiosInstance.get(
			"posts/shared-post/" + uuid + "/comments?page=" + page + "&limit=10"
		);
		return response?.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

//############## PROFIL USER REQUESTS

export const requestAxiosGetOneUser = async (userId) => {
	try {
		const response = await axiosInstance.get("/users/" + userId);
		return {
			id: response?.data?.id,
			email: response?.data?.email,
			firstName: response?.data?.firstName,
			lastName: response?.data?.lastName,
			profilePicture: response?.data?.profilePicture,
			backgroundPicture: response?.data?.backgroundPicture,
			bio: response?.data?.bio,
			username: response?.data?.username,
			followedSince: response?.data?.followedSince,
			nbFollowers: response?.data?.nbFollowers,
			nbFollowing: response?.data?.nbFollowing,
		};
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosEditOneUser = async (
	userId,
	profilePicture,
	bannerImage,
	lastName,
	firstName,
	birthday,
	bio
) => {
	try {
		const formData = new FormData();
		formData.append("profilePicture", profilePicture?.file);
		formData.append("backgroundPicture", bannerImage?.file);
		formData.append("lastName", lastName);
		formData.append("firstName", firstName);
		formData.append("birthday", birthday);
		formData.append("bio", bio);

		const response = await axiosInstance.post(
			"/users/" + userId,
			formData,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);

		return {
			id: response?.data?.id,
			firstName: response?.data?.firstName,
			lastName: response?.data?.lastName,
			profilePicture: response?.data?.profilePicture,
			backgroundPicture: response?.data?.backgroundPicture,
			birthday: response?.data?.birthday,
			bio: response?.data?.bio,
		};
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosGetPostsByUserId = async (userId, page, limit) => {
	try {
		const response = await axiosInstance.get(
			"/users/" + userId + "/posts?page=" + page + "&limit=" + limit
		);
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

//############## FOLLOWS REQUESTS

export const requestAxiosSuggestFollow = async (page) => {
	try {
		const response = await axiosInstance.get(
			"/users/following/suggestions?page=" + page + "&limit=15"
		);
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosGetFollowers = async (userId, page) => {
	try {
		const response = await axiosInstance.get(
			"/users/" + userId + "/followers?page=" + page + "&limit=15"
		);
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosGetFollowings = async (userId, page) => {
	try {
		const response = await axiosInstance.get(
			"/users/" + userId + "/following?page=" + page + "&limit=15"
		);
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosToggleFollowing = async (userId) => {
	try {
		const response = await axiosInstance.post(
			"/users/" + userId + "/followers"
		);
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

//############## SEARCH USER REQUESTS

export const requestAxiosGetSearchResult = async (input, filter, source) => {
	try {
		const response = await axiosInstance.get("/search?q=" + input, {
			cancelToken: source.token,
		});
		return response.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

//############## SETTINGS REQUESTS

export const requestAxiosGetSettings = async () => {
	try {
		const response = await axiosInstance.get("userconfigurations/");
		return response?.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosPutSettings = async (configurationKey, value) => {
	try {
		const response = await axiosInstance.put("userconfigurations", {
			userconfigs: [
				{
					configurationKey: configurationKey,
					value: value,
				},
			],
		});
		return response?.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

//############## SETTINGS REQUESTS

export const requestAxiosGetNotifs = async (
	page,
	limit,
	categories,
	toread
) => {
	try {
		let req = "notifications?page=" + page + "&limit=" + limit;
		if (categories) {
			req += "&categories=" + categories;
		}
		if (toread) {
			req += "&toread=1";
		}
		const response = await axiosInstance.get(req);
		return response?.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosGetNewsNotifs = async (page, limit, toread) => {
	try {
		let req = "notifications?page=" + page + "&limit=" + limit;
		if (toread) {
			req += "&toread=1";
		}
		const response = await axiosInstance.get(req);
		return response?.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosReadNotifs = async (listIds) => {
	try {
		await axiosInstance.patch("notifications", {
			notifications: listIds,
		});
		return;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

//############## REPORTS REQUESTS

export const requestAxiosGetReportsTypes = async () => {
	try {
		const response = await axiosInstance.get(
			"report/posts/available_types"
		);
		return response?.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

export const requestAxiosCreateReports = async (postId, description, types) => {
	try {
		await axiosInstance.post("report/posts", {
			socialPost: postId,
			description,
			types,
		});
		return;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};

//############## CHALLENGES REQUESTS

export const requestAxiosGetChallenges = async (userId) => {
	try {
		const response = await axiosInstance.get("challenge?user="+userId);
		return response?.data;
	} catch (error) {
		throw error.response ? error.response.data : error;
	}
};
