import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { theme } from "../../../assets/themes/theme";

const ImageControl = ({ index, image, handleImageRemove }) => {

	return (
		<Box mx={2} mt="auto" mb="auto" position={'relative'}>
			<img
				src={image}
				alt={`upload-${index}`}
				style={{
					width: "130px",
					height: "80px",
					objectFit: "cover",
					opacity: 0.9,
					borderRadius: "7px",
				}}
			/>
			<IconButton
				onClick={() => handleImageRemove(index)}
				sx={{
					width:'18px',
					height:'18px',
					position: "absolute",
					top: 2,
					right: 2,
					backgroundColor: theme.palette.otherColor.light,
					opacity: 0.4,
					"&:hover": {
						opacity: 0.7,
						backgroundColor: theme.palette.otherColor.light,
					},
				}}
			>
				<CloseIcon color="secondary"  sx={{width:'16px',height:'16px'}}/>
			</IconButton>
		</Box>
	);
};

export default ImageControl;
