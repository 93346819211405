import { Box, Card, Divider, Icon, IconButton, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { theme } from "../../../assets/themes/theme";
import CloseIcon from "@mui/icons-material/Close";

const ListUsersCard = ({
	title,
	icon,
	users,
	subtitle,
	description,
	handleClose,
}) => {
	const mediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
	const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Card
			sx={{
				maxWidth: mediumScreen?"none":"750px",
				width: {
					xs: "100%",
					sm: "100%",
					md: "100%",
				},
				height: {
					xs: "100%",
					sm: "100%",
					md: "auto",
				},
				pt: 2,
				pb: 2,
				borderRadius: "16px",
				minHeight: mediumScreen?"100vh":"100%",
				ml: "auto",
				mr: "auto",
				backgroundColor: theme.palette.secondary.main,
			}}
		>
			{mediumScreen && (
				<IconButton
					sx={{ top: 30, left: 10, position: "absolute" }}
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			)}
			<Box display={"flex"} justifyContent={"center"} mb={2} mt={2}>
				<Typography variant="h5" mr={2} mt={"auto"} mb={"auto"}>
					{title}
				</Typography>
				<Icon
					component={icon}
					sx={{
						mt: "auto",
						mb: "auto",
						width: "30px",
						height: "30px",
					}}
				/>
			</Box>
			<Divider sx={{ mb: 2 }} />
			<Box m={4} display={"flex"} flexDirection={"column"}>
				<Typography fontSize={"20px"}>{subtitle}</Typography>
				<Typography mb={4} mt={3}>
					{description}
				</Typography>
				{users?.map((user) => {
					<Box>{user?.username}</Box>;
				})}
			</Box>
		</Card>
	);
};

export default ListUsersCard;
