import { Box, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import React from "react";
import { theme } from "../../../assets/themes/theme";

const ReportCard = ({ active, title, onClick }) => {
	return (
		<Box
			position={"relative"}
			display={"flex"}
			bgcolor={theme.palette.secondary.main}
			p={2}
			borderRadius={2}
			mb={1}
			onClick={onClick}
			sx={{
				cursor: "pointer",
				border: '1px solid',
				borderColor: active ? theme.palette.primary.main: theme.palette.secondary.main,
				"&:hover": {
					backgroundColor: theme.palette.secondary.dark,
				},
			}}
		>
			<Typography fontWeight={500}>{title}</Typography>
			{active && (
				<CheckIcon
					sx={{ position: "absolute",right:30}}
				/>
			)}
		</Box>
	);
};

export default ReportCard;
