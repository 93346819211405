import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	requestAxiosAddComment,
	requestAxiosGetComments,
	requestAxiosDeleteComment,
	requestAxiosEditComment,
	requestAxiosLikeComment,
} from "../../services/axios/axiosRequests";

// création d'un commentaire
export const addComment = createAsyncThunk(
	"comments/addComment",
	async ({ postId, content, replyTo, commentType }, { rejectWithValue }) => {
		try {
			const response = await requestAxiosAddComment({
				postId,
				content,
				replyTo,
				commentType,
			});
			return { postId, response };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// récupération d'un ensemble de commentaires
export const fetchCommentsByPostId = createAsyncThunk(
	"comments/fetchComments",
	async ({ postId, page, limit }, { rejectWithValue }) => {
		try {
			const { items, pagination } = await requestAxiosGetComments({
				postId,
				page,
				limit,
			});
			return { postId, items, pagination };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// like/dislike d'un commentaire
export const likeComment = createAsyncThunk(
	"comments/likeComment",
	async ({ postId, commentId }, { rejectWithValue }) => {
		try {
			const response = await requestAxiosLikeComment(postId, commentId);
			return { postId, commentId, ...response };
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

// // edition d'un commentaire
// export const editComment = createAsyncThunk( // TODO
// 	"comments/editComment",
// 	async ({ commentId }, { rejectWithValue }) => {
// 		try {
// 			const response = await requestAxiosEditComment({ commentId });
// 			return response;
// 		} catch (error) {
// 			return rejectWithValue(error);
// 		}
// 	}
// );

// // suppression d'un commentaire
// export const deleteComment = createAsyncThunk( // TODO
// 	"comments/deleteComment",
// 	async ({ commentId }, { rejectWithValue }) => {
// 		try {
// 			const response = await requestAxiosDeleteComment({ commentId });
// 			return response;
// 		} catch (error) {
// 			return rejectWithValue(error);
// 		}
// 	}
// );

const commentSlice = createSlice({
	name: "comments",
	initialState: {
		itemsC: [],
		loadingC: false,
		limitC: 10,
		errorC: null,
	},
	reducers: {
		sortComments: (state, action) => {
			state.itemsC[action.payload.postId].comments.sort((a, b) => {
				// Comparaison par nbLikes (ordre décroissant)
				if (a.nbLikes > b.nbLikes) return -1;
				if (a.nbLikes < b.nbLikes) return 1;

				// Si nbLikes est identique, comparaison par createdAt (ordre décroissant)
				if (a.createdAt < b.createdAt) return 1;
				if (a.createdAt > b.createdAt) return -1;

				return 0;
			});
		},
	},
	extraReducers: (builder) => {
		builder
			// Si des commentaires sont chargés
			.addCase(fetchCommentsByPostId.pending, (state) => {
				state.loadingC = true;
				state.errorC = null;
			})
			.addCase(fetchCommentsByPostId.fulfilled, (state, action) => {
				const { postId, items, pagination } = action.payload;
				// on forme l'objet s'il n'existe pas
				if (!state.itemsC[postId]) {
					state.itemsC[postId] = {
						comments: [],
						pageC: 0,
						totalC: 0,
					};
				}
				// on récupère les id des commentaires existants
				const existingCommentIds = new Set(
					state.itemsC[postId]?.comments?.map(
						(comment) => comment?.id
					)
				);
				// on garde uniquement les nouveaux commentaires qui ne sont pas déjà présents dans la liste
				const newComments = items?.filter(
					(comment) => !existingCommentIds.has(comment?.id)
				);
				state.itemsC[postId].comments = [
					...state.itemsC[postId]?.comments,
					...newComments,
				];
				const endDataC =
					state.itemsC[postId]?.comments?.length ===
					pagination?.total;
				const limitReached = items?.length === pagination?.limit;
				// S'il reste des commentaires à charger on passe à la page suivante
				if (!endDataC && limitReached) {
					state.itemsC[postId].pageC += 1;
				}
				state.loadingC = false;
				state.itemsC[postId].totalC = pagination?.total;
			})
			.addCase(fetchCommentsByPostId.rejected, (state, action) => {
				state.loadingC = false;
				state.errorC = action.payload;
			})
			// Si un commentaire est créé
			.addCase(addComment.pending, (state) => {
				state.loadingC = true;
				state.errorC = null;
			})
			.addCase(addComment.fulfilled, (state, action) => {
				state.loadingC = false;
				const { postId, response } = action.payload;
				if (!state.itemsC[postId]) {
					state.itemsC[postId] = {
						comments: [],
						pageC: 0,
						totalC: 0,
					};
				}
				state.itemsC[postId]?.comments?.unshift(response);
			})
			.addCase(addComment.rejected, (state, action) => {
				state.loadingC = false;
				state.errorC = action.payload;
			})
			// Si un commentaire est like
			.addCase(likeComment.pending, (state, action) => {
				const postId = action.meta.arg.postId;
				const commentId = action.meta.arg.commentId;
				const comment = state?.itemsC[postId]?.comments?.find(
					(comment) => comment?.id === commentId
				);
				if (comment) {
					comment.loadingLike = true;
				}
				state.errorC = null;
			})
			.addCase(likeComment.fulfilled, (state, action) => {
				state.loadingC = false;
				const postId = action.payload.postId;
				const commentId = action.payload.commentId;
				const comment = state?.itemsC[postId]?.comments?.find(
					(comment) => comment?.id === commentId
				);
				// si le post a été identifié alors on met à jour ses données
				if (comment) {
					comment.loadingLike = false;
					comment.nbLikes = action.payload.nbLikes;
					comment.userHasLiked = action.payload.userHasLiked;
				}
			})
			.addCase(likeComment.rejected, (state, action) => {
				const postId = action.meta.arg.postId;
				const commentId = action.meta.arg.commentId;
				const comment = state?.itemsC[postId]?.comments?.find(
					(comment) => comment?.id === commentId
				);
				if (comment) {
					comment.loadingLike = false;
				}
				state.errorC = action.payload;
			});
	},
});
export const { sortComments } = commentSlice.actions;

export default commentSlice.reducer;
