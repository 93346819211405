import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { theme } from "../../../assets/themes/theme";
import GoodMoveLogo from "../../../assets/images/logo_classic_outlined.svg";

const LoadingSection = () => {
	const SXBoxLoadingSection = {
		backgroundColor: theme.palette.secondary.dark,
		position: "absolute",
		display: "block",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		textAlign: "center",
	};

	const SXBoxContainer = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		textAlign: "center",
	};
	const SXBoxLogoContainer = {
		mb: {
			sm: "30px",
			md: "50px",
			lg: "70px",
		},
		width: {
			xs: "300px",
			sm: "500px",
			md: "700px",
			lg: "900px",
			xl: "1100px",
		},
	};

	return (
		<Box sx={SXBoxLoadingSection}>
			<Box sx={SXBoxContainer}>
				<Box sx={SXBoxLogoContainer}>
					<img alt="GoodMove" src={GoodMoveLogo} style={{with:'100%'}}/>
				</Box>
				<CircularProgress />
			</Box>
		</Box>
	);
};

export default LoadingSection;
