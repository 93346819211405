import React, { useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import linkedin from "../../../assets/images/linkedin.png";
import { theme } from "../../../assets/themes/theme";
import { useDispatch, useSelector } from "react-redux";
import { authLinkedin } from "../../../store/slices/authSlice";

const LinkedinAuthBtn = () => {
	const dispatch = useDispatch();
	const { loadingLinkedin } = useSelector((state) => state.auth);

	const [loading, setLoading] = useState(false);

	const LINKEDIN_OAUTH_URL =
		`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${
			process.env.REACT_APP_LINKEDIN_CLIENT_ID
		}&redirect_uri=${encodeURIComponent(
			process.env.REACT_APP_LINKEDIN_CALLBACK_URL
		)}&scope=email openid profile`;

	const handlePopupMessage = (e) => {
		if (e.origin !== window.location.origin) {
			return;
		}
		const { userProfile } = e.data;

		if (userProfile) {
			dispatch(
				authLinkedin({
					email: userProfile?.email,
					firstName: userProfile?.given_name,
					lastName: userProfile?.family_name,
					avatar: userProfile?.picture,
				})
			); // autre champs : email_verified, local : {country, language}, name (= fullname)
			return;
		}
	};

	React.useEffect(() => {
		window.addEventListener("message", handlePopupMessage);
		return () => {
			window.removeEventListener("message", handlePopupMessage);
		};
	}, []);

	React.useEffect(() => {
		setLoading(loadingLinkedin);
	}, [loadingLinkedin]);

	const openPopup = () => {
		const width = 600;
		const height = 800;
		const left = window.screen.width / 2 - width / 2;
		const top = window.screen.height / 2 - height / 2;
		window.open(
			LINKEDIN_OAUTH_URL,
			"LinkedIn Auth",
			`width=${width},height=${height},top=${top},left=${left}`
		);
	};

	return (
		<LoadingButton
			sx={{
				boxShadow: "1px 3px 6px rgba(85, 85, 85, 0.25)",
				backgroundColor: "#0077B7",
				ml: "auto",
				mr: "auto",
				mt: 3,
				mb: 1,
				height: 39,
				width: "230px",
				".MuiLoadingButton-loadingIndicator": {
					color: "#FFFF",
				},
				"&:hover": {
					backgroundColor: "#00537F",
				},
			}}
			loading={loading}
			onClick={openPopup}
		>
			<Box
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-start",
					position: "absolute",
					left: 8,
				}}
			>
				<Avatar
					src={linkedin}
					sx={{ borderRadius: 0, height: 35, width: 35 }}
				/>
			</Box>
			<Typography
				sx={{
					fontSize: 14,
					fontWeight: "600",
					m: "auto",
					pl: 1,
					color: theme.palette.secondary.main,
				}}
			>
				{!loading && "Linkedin"}
			</Typography>
		</LoadingButton>
	);
};

export default LinkedinAuthBtn;
