import {
	Box,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	CircularProgress,
	IconButton,
	Modal,
	Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useState } from "react";
import FollowCard from "../Card/FollowCard";
import { theme } from "../../../assets/themes/theme";
import { useNavigate } from "react-router-dom";
import desertGif from "../../../assets/videos/desert.gif";

const FollowModal = ({
	open,
	data,
	handleFetch,
	handleClose,
	title,
}) => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (open) {
			setLoading(true);
			handleFetch().then(() => setLoading(false));
		}
	}, [open]);

	const handleClickProfil = (id) => {
		handleClose();
		navigate("/profile/" + id);
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<Card
				sx={{
					width: {
						xs: "100%",
						sm: "100%",
						md: "800px",
						lg: "900px",
						xl: "1100px",
					},
					height: {
						xs: "100%",
						sm: "100%",
						md: "auto",
					},
					m: "auto",
					backgroundColor: theme.palette.secondary.dark,
				}}
			>
				<CardHeader
					avatar={
						<>
							<IconButton
								aria-label="close"
								onClick={handleClose}
							>
								<CloseIcon />
							</IconButton>
						</>
					}
					title={title}
					sx={{
						pb: 1,
						".MuiCardHeader-title": {
							textAlign: "center",
							fontSize: "16px",
							fontWeight: "500",
							pr: 5,
						},
					}}
				/>
				<CardContent sx={{ pt: 1, pb: 1 }}>
					{loading ? (
						<CircularProgress />
					) : data?.length > 0 ? (
						data?.map((user) => (
							<FollowCard
								key={user?.id}
								userId={user?.id}
								handleClickProfil={() =>
									handleClickProfil(user?.id)
								}
							/>
						))
					) : (
						<Box
					display={"flex"}
					justifyContent={"center"}
					flexDirection={"column"}
				>
					<CardMedia
						component="img"
						image={desertGif}
						alt="searching-data"
						sx={{
							width: "80px",
							height: "80px",
							ml: "auto",
							mr: "auto",
							mt: 3,
						}}
					/>
					<Typography
						textAlign={"center"}
						mt={3}
						fontWeight={500}
						color={theme.palette.otherColor.main}
					>
						Il semble qu'il n'y ait personne par ici...
					</Typography>
				</Box>
					)}
				</CardContent>
			</Card>
		</Modal>
	);
};

export default FollowModal;
