import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../../assets/themes/theme";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
	addOneFollower,
	addOneFollowing,
	cleanErrorU,
	deleteOneFollower,
	deleteOneFollowing,
} from "../../../store/slices/userSlice";
import { requestAxiosToggleFollowing } from "../../../services/axios/axiosRequests";

const SuggestCard = ({ userId, handleClickProfil }) => {
	const auth = useSelector((state) => state?.auth);
	const user = useSelector((state) => state?.users?.itemsU[userId]);
	const dispatch = useDispatch();
	// const [openConfirm, setOpenConfirm] = useState(false);

	const handleClickFollow = async () => {
		try {
			await requestAxiosToggleFollowing(userId);
			dispatch(addOneFollower({ userId: userId }));
			dispatch(addOneFollowing({ userId: auth?.user?.id }));
		} catch (err) {
			toast.error(err?.message || "un problème est survenu", {
				toastId: "follow-error",
			});
			dispatch(cleanErrorU());
		}
	};

	const handleClickUnFollow = async () => {
		try {
			await requestAxiosToggleFollowing(userId);
			dispatch(deleteOneFollower({ userId: userId }));
			dispatch(deleteOneFollowing({ userId: auth?.user?.id }));
		} catch (err) {
			toast.error(err?.message || "un problème est survenu", {
				toastId: "follow-error",
			});
			dispatch(cleanErrorU());
		}
	};

	return (
		<Box
			display={"flex"}
			justifyContent={"space-between"}
			bgcolor={"white"}
			p={1.5}
			borderRadius={2}
			mb={1}
		>
			<Avatar
				src={user?.profilePicture}
				alt="relation profile picture"
				onClick={handleClickProfil}
				sx={{
					borderRadius: 1,
					cursor: "pointer",
					mt: "auto",
					mb: "auto",
					width: "40px",
					height: "40px",
				}}
			/>
			<Box mt={"auto"} mb={"auto"} flex={1} mr={1} zIndex={1}>
				<Typography
					fontWeight={600}
					fontSize={14}
					color={theme.palette.otherColor.main}
					ml={2}
					sx={{whiteSpace:'nowrap', overflowX:'hidden', textOverflow:'ellipsis', maxWidth: {lg:'150px', xl: '250px'}}}
					
				>
					{user?.firstName} {user?.lastName}
				</Typography>
				<Typography
					fontWeight={300}
					fontSize={14}
					color={theme.palette.otherColor.main}
					ml={2}
				>
					@{user?.username}
				</Typography>
			</Box>
			<Box display={"flex"} zIndex={10}>
				{user?.id != auth?.user?.id &&
					(user?.followedSince ? (
						<Typography
							fontWeight={500}
							color={theme.palette.primary.main}
							textAlign={"center"}
							onClick={handleClickUnFollow}
							mt={"auto"}
							mb={"auto"}
							sx={{cursor:'pointer'}}
						>
							Ne plus suivre
						</Typography>
					) : (
						<Typography
							fontWeight={600}
							color={theme.palette.primary.main}
							textAlign={"center"}
							onClick={handleClickFollow}
							mt={"auto"}
							mb={"auto"}
							sx={{cursor:'pointer'}}
						>
							Suivre
						</Typography>
					))}
			</Box>
		</Box>
	);
};

export default SuggestCard;
