import React, { useRef, useState } from "react";
import ImageControl from "../../atoms/ImageControl/ImageControl";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, IconButton } from "@mui/material";

const CarouselPostImage = ({ images, handleImageRemove, slidesPerView }) => {
	const [isBeginning, setIsBeginning] = useState(true);
	const [isEnd, setIsEnd] = useState(false);
	const swiperRef = useRef(null);

	const handlePrev = () => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slidePrev();
			isEnd && setIsEnd(false);
			swiperRef?.current?.swiper?.isBeginning &&
				setIsBeginning(swiperRef?.current?.swiper?.isBeginning);
		}
	};

	const handleNext = () => {
		if (swiperRef.current && swiperRef.current.swiper) {
			swiperRef.current.swiper.slideNext();
			isBeginning && setIsBeginning(false);
			swiperRef?.current?.swiper?.isEnd &&
				setIsEnd(swiperRef?.current?.swiper?.isEnd);
		}
	};
	return (
		<Box
			height="84px"
			display="flex"
			justifyContent="space-evenly"
			ml="auto"
			mr="auto"
			sx={{width:{
				xs : "300px",
				sm : "400px",
				md : "600px",
				lg : "750px",
			}}}
		>
			<IconButton
				onClick={handlePrev}
				disabled={isBeginning}
				sx={{
					m: "auto",
				}}
			>
				<ArrowBackIosIcon />
			</IconButton>
			<Swiper
				ref={swiperRef}
				slidesPerView={slidesPerView}
				modules={[Navigation]}
				style={{ marginTop: "auto", marginBottom: "auto" }}
			>
				{images.map((image, index) => (
					<SwiperSlide key={`carousel-item-${index}`}>
						<Box display="flex" justifyContent="center">
							<ImageControl
								key={`carousel-item-${index}`}
								index={index}
								image={image?.picture_url}
								handleImageRemove={handleImageRemove}
							/>
						</Box>
					</SwiperSlide>
				))}
			</Swiper>
			<IconButton
				onClick={handleNext}
				disabled={isEnd}
				sx={{
					m: "auto",
				}}
			>
				<ArrowForwardIosIcon />
			</IconButton>
		</Box>
	);
};

export default CarouselPostImage;
