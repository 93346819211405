import React, { useEffect, useRef, useState } from "react";
import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	Divider,
	IconButton,
	Modal,
	Typography,
	useMediaQuery,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ReplayIcon from '@mui/icons-material/Replay';
import CloseIcon from "@mui/icons-material/Close";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CarouselViewer from "../Carousel/CarouselViewer";
import { theme } from "../../../assets/themes/theme";
import { timeElapsed } from "../../../utils/tool";
import InputComment from "../../atoms/Inputs/InputComment";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { useDispatch, useSelector } from "react-redux";
import { fetchPostsById, likePost } from "../../../store/slices/postSlice";
import Comment from "../../atoms/Comment/Comment";
import {
	fetchCommentsByPostId,
	sortComments,
} from "../../../store/slices/commentSlice";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import commentGif from "../../../assets/videos/redaction.gif";
import PostErrorModal from "./PostErrorModal";

const PostComponentDetails = ({
	postId,
	open,
	handleClose,
	handleOpenOptions,
	context,
}) => {
	const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));
	const [isExpanded, setIsExpanded] = useState(false);
	const [postKO, setPostKO] = useState(false);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const [comments, setComments] = useState([]);
	const [pageC, setPageC] = useState(0);
	const [loadComments, setLoadComments] = useState(false);
	const [loadingPostData, setLoadingPostData] = useState(false);
	const contentRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	const post = useSelector((state) => {
		switch (context) {
			case "profile":
				return state.posts.profilesItems[id]?.itemsPI?.find(
					(p) => p?.id === postId
				);
			default:
				return state.posts.itemsP?.find((p) => p?.id === postId);
		}
	});

	const { itemsC, limitC, errorC } = useSelector((state) => state.comments);

	const { errorP, loadingP } = useSelector((state) => state.posts);

	const timeElapsedStr = timeElapsed(post?.createdAt);

	const SXBoxContainer = {
		display: "flex",
		ml: "auto",
		mr: "auto",
	};

	const SXCardMediaSmallScreen = {
		width: "100%",
		height: "auto",
	};

	const SXCardMediaWeb = {
		width: {
			lg: "580px",
			xl: "700px",
		},
		height: {
			lg: "464px",
			xl: "560px",
		},
		borderRadius: "10px",
	};

	const handleShared = () => {
		navigator.clipboard.writeText(
			"https://goodmove.gregory-mostacci.fr/shared/" + post?.uuid
		);
		toast.success("Copier dans le presse papier. ", {
			toastId: "shared-post-success",
		});
	};

	const handleLike = () => {
		if (!post.loadingLike) {
			dispatch(likePost({ postId, userId: id }));
		}
	};

	const handleToggleExpand = () => {
		setIsExpanded(!isExpanded);
	};

	const handleClean = () => {
		setComments([]);
		setPageC(0);
		setPostKO(false);
		setLoadingPostData(false);
		handleClose();
	};

	const handleLoadMoreComments = () => {
		setLoadComments(true);
		dispatch(
			fetchCommentsByPostId({ postId, page: pageC + 1, limit: limitC })
		);
	};

	useEffect(() => {
		if (itemsC[postId]) {
			setComments(itemsC[postId]?.comments);
			setPageC(itemsC[postId]?.pageC);
			setLoadComments(false);
		} else if (errorC) {
			setLoadComments(false);
		}
	}, [itemsC, postId, errorC]);

	useEffect(() => {
		open && postId && dispatch(fetchPostsById({ postId, userId: id }));
		!itemsC[postId] && !loadComments && open && handleLoadMoreComments();
		itemsC[postId] &&
			!loadComments &&
			open &&
			dispatch(sortComments({ postId }));
		if (open && !post) {
			setLoadingPostData(true);
		}
	}, [open]);

	useEffect(() => {
		const element = contentRef.current;
		if (element) {
			setIsOverflowing(element.scrollHeight > element.clientHeight);
		}
	}, [post?.description]);

	useEffect(() => {
		if (open && errorP) {
			setLoadingPostData(false);
			setPostKO(true);
		} else if (open && post) {
			setLoadingPostData(false);
		}
	}, [open, errorP, post]);	

	if (postKO || loadingPostData) {
		return (
			<PostErrorModal
				open={loadingPostData || postKO}
				handleClose={handleClean}
				isError={postKO}
			/>
		);
	}

	return (
		<Modal
			open={open}
			onClose={handleClean}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<Card
				sx={{
					width: {
						xs: "100%",
						sm: "550px",
						md: "850px",
						lg: "1150px",
						xl: "1480px",
					},
					height: {
						xs: "100%",
						sm: "auto",
					},
					m: "auto",
				}}
			>
				<Box sx={SXBoxContainer}>
					{!smallScreen && (
						<Box ml={2} mt={"auto"} mb={"auto"}>
							{post?.mediaObjects &&
								post?.mediaObjects.length > 0 &&
								(post?.mediaObjects.length === 1 ? (
									<CardMedia
										component="img"
										image={
											post?.mediaObjects[0].picture_url
										}
										alt="poste img"
										sx={{
											mt: 2,
											mb: 2,
											...SXCardMediaWeb,
										}}
									/>
								) : (
									<Box
										mt={2}
										mb={2}
										sx={{
											...SXCardMediaWeb,
											borderRadius: 0,
										}}
									>
										<CarouselViewer
											images={post?.mediaObjects}
											SXCardMedia={SXCardMediaWeb}
										/>
									</Box>
								))}
						</Box>
					)}
					<Box
						flex={1}
						display={"flex"}
						flexDirection={"column"}
						sx={{
							maxHeight: {
								xs: "100vh",
								sm: "100vh",
								md: "100vh",
								lg: "464px",
								xl: "560px",
							},
							overflowY: "scroll",
						}}
					>
						<CardHeader
							avatar={
								<Avatar
									src={post?.user?.profilePicture}
									onClick={() =>
										navigate("/profile/" + post?.user?.id)
									}
									sx={{ borderRadius: 1, cursor: "pointer" }}
								/>
							}
							action={
								<>
									<IconButton
										id="settingsBtn"
										aria-label="settings"
										aria-describedby={"test"}
										type="button"
										onClick={() =>
											handleOpenOptions(postId)
										}
									>
										<MoreVertIcon />
									</IconButton>
									<IconButton
										aria-label="close"
										onClick={handleClean}
										sx={{ mr: 1 }}
									>
										<CloseIcon />
									</IconButton>
								</>
							}
							title={
								post?.user?.firstName +
								" " +
								post?.user?.lastName
							}
							subheader={timeElapsedStr}
							sx={{
								pb: 1,
								".MuiCardHeader-title": {
									fontSize: "16px",
									fontWeight: "600",
								},
								".MuiCardHeader-subheader": {
									fontSize: "12px",
									fontWeight: "500",
									color: theme.palette.primary.main,
								},
							}}
						/>
						<CardContent sx={{ pt: 1, pb: 1 }}>
							<Typography
								ref={contentRef}
								variant="body2"
								sx={{
									width: "100%",
									overflow: "hidden",
									overflowWrap: "break-word",
									display: "-webkit-box",
									WebkitBoxOrient: "vertical",
									WebkitLineClamp: isExpanded ? "none" : 5,
									lineClamp: isExpanded ? "none" : 5,
								}}
							>
								{post?.description}
							</Typography>
							{isOverflowing && (
								<Typography
									variant="body2"
									onClick={handleToggleExpand}
									sx={{ cursor: "pointer" }}
								>
									{isExpanded ? "voir moins" : "voir plus..."}
								</Typography>
							)}
						</CardContent>

						{smallScreen && (
							<Box ml={"auto"} mr={"auto"}>
								{post?.mediaObjects &&
									post?.mediaObjects.length > 0 &&
									(post?.mediaObjects.length === 1 ? (
										<CardMedia
											component="img"
											image={
												post?.mediaObjects[0]
													.picture_url
											}
											alt="poste img"
											sx={SXCardMediaSmallScreen}
										/>
									) : (
										<Box
											position="relative"
											sx={{
												maxWidth: {
													xs: "450px",
													sm: "480px",
													md: "800px",
													lg: "800px",
												},
											}}
										>
											<CarouselViewer
												images={post?.mediaObjects}
												SXCardMedia={
													SXCardMediaSmallScreen
												}
											/>
										</Box>
									))}
							</Box>
						)}
						<Box
							display={"flex"}
							flexDirection={"column"}
							mt={1}
							mr={2}
							ml={2}
							mb={1}
							height={"100%"}
						>
							<Box
								display={"flex"}
								justifyContent={"space-between"}
							>
								<Box display={"flex"}>
									<IconButton
										onClick={handleLike}
										disabled={post?.loadingLike}
									>
										{post?.userHasLiked ? (
											<FavoriteIcon
												sx={{ color: "red" }}
											/>
										) : (
											<FavoriteIcon />
										)}
									</IconButton>
									<Typography
										variant="body2"
										textAlign={"left"}
										sx={{ mt: "auto", mb: "auto" }}
									>
										{post?.nbLikes || 0} J'aimes
									</Typography>
								</Box>
								<IconButton onClick={handleShared}>
									<ShareOutlinedIcon />
								</IconButton>
							</Box>
							<Box m={1} flex={1}>
								<Divider sx={{ mt: 3, ml: 5, mr: 5, mb: 2 }} />
								{comments?.length > 0 ? (
									<>
										{comments?.map((comment) => {
											return (
												<Box key={comment?.id} mb={2}>
													<Comment
														postId={postId}
														commentId={comment?.id}
													/>
												</Box>
											);
										})}
										<Box
											textAlign={"center"}
											onClick={handleLoadMoreComments}
											sx={{ cursor: "pointer" }}
											mb={2}
										>
											<ReplayIcon />
										</Box>
									</>
								) : (
									<>
										<Box
											display={"flex"}
											justifyContent={"center"}
											flexDirection={"column"}
										>
											<CardMedia
												component="img"
												image={commentGif}
												alt="wait-ro-search"
												sx={{
													width: "50px",
													height: "50px",
													ml: "auto",
													mr: "auto",
													mt: 2,
												}}
											/>
											<Typography
												textAlign={"center"}
												mt={1}
												fontWeight={500}
												fontSize={14}
												color={
													theme.palette.otherColor
														.main
												}
											>
												Soyez la première personne à
												donner votre avis
											</Typography>
										</Box>
									</>
								)}
							</Box>
							<Box
								position="sticky"
								bottom={0}
								width="100%"
								sx={{ backgroundColor: "white" }}
							>
								<InputComment postId={postId} userId={id} />
							</Box>
						</Box>
					</Box>
				</Box>
			</Card>
		</Modal>
	);
};

export default PostComponentDetails;
