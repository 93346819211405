import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
	Box,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Typography,
} from "@mui/material";
import React, { useState } from "react";

const InputPassword = ({
	password,
	setPassword,
	label,
	entityName,
	errors,
	setErrors,
	css,
	disabled
}) => {
	const [showPassword, setShowPassword] = useState(false);

	return (
		<Box sx={{ mb: 1, mt: 1, ...css }}>
			<FormControl
				variant="outlined"
				required
				fullWidth
				error={errors && !!errors[entityName]}
				sx={{
					boxShadow: "1px 1px 4px rgba(85, 85, 85, 0.25)",
					maxWidth: "700px",
					ml: "auto",
					mr: "auto",
				}}
				disabled={disabled}
			>
				<InputLabel htmlFor={"outlined-adornment-" + entityName}>
					{label}
				</InputLabel>
				<OutlinedInput
					id={"outlined-adornment-" + entityName}
					type={showPassword ? "text" : "password"}
					value={password}
					onChange={(e) => {
						if (errors && errors[entityName]) {
							let cleanError = { ...errors };
							cleanError[entityName] = "";
							setErrors(cleanError);
						}
						setPassword(e.target.value);
					}}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => setShowPassword(!showPassword)}
								edge="end"
								disabled={disabled}
							>
								{showPassword ? (
									<VisibilityOff />
								) : (
									<Visibility />
								)}
							</IconButton>
						</InputAdornment>
					}
					label={label + " *"}
				/>
			</FormControl>
			{errors && errors[entityName] && (
				<Typography color="error" fontSize={11} sx={{ ml: 2, mt: 0.5 }}>
					{errors[entityName]}
				</Typography>
			)}
		</Box>
	);
};

export default InputPassword;
