import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import postsReducer from './slices/postSlice';
import commentsReducer from "./slices/commentSlice";
import usersReducer from "./slices/userSlice";
import notifsReducer from "./slices/notifSlice";

const store = configureStore({
	reducer: {
		auth: authReducer,
		users: usersReducer,
		posts: postsReducer,
		notifs: notifsReducer,
		comments: commentsReducer,
	},
});

export default store;
