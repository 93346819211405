import React, { useEffect, useState } from "react";
import Navbar from "../molecules/Navbar/Navbar";
import { Box, Stack, useMediaQuery } from "@mui/material";
import { theme } from "../../assets/themes/theme";
import BottomNavbar from "../molecules/BottomNavbar/BottomNavbar";
import SettingsSection from "../organisms/SettingsSection/SettingsSection";
import { useDispatch, useSelector } from "react-redux";
import { addPost, cleanDataP, cleanErrorP } from "../../store/slices/postSlice";
import CreatePostSectionModale from "../organisms/CreatePostSection/CreatePostSectionModal";
import { updateProfile } from "../../store/slices/authSlice";
import { toast } from "react-toastify";

const SettingsPage = () => {
	document.title = "Paramètres | Goodmove";
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [open, setOpen] = useState(false);
	const [loadingEditProfile, setLoadingEditProfile] = useState(false);
	const dispatch = useDispatch();
	const { errorP } = useSelector((state) => state.posts);

	const user = useSelector((state) => state.auth.user);
	const currentUser = useSelector((state) => state?.users?.itemsU[user?.id]);

	const handleAddPost = (newPost) => {
		open && setOpen(false);
		dispatch(addPost({ userId: user?.id, newPost }));
	};
	if (errorP) {
		toast.error(errorP?.message || "un problème est survenu", {
			toastId: "post-error",
		});
		dispatch(cleanErrorP());
	}
	
	useEffect(() => {
		if (!loadingEditProfile && currentUser?.loadingUPatch) {
			setLoadingEditProfile(true);
		} else if (loadingEditProfile && !currentUser?.loadingUPatch) {
			dispatch(
				updateProfile({
					profilePicture: currentUser?.profilePicture,
					bannerImage: currentUser?.bannerImage,
					lastName: currentUser?.lastName,
					firstName: currentUser?.firstName,
					birthday: currentUser?.birthday,
					bio: currentUser?.bio,
				})
			);
			dispatch(cleanDataP());
			setLoadingEditProfile(false);
			toast.success("Informations du profil mises à jour. ", {
				toastId: "edited-profile-success",
			});
		}
	}, [currentUser]);

	return (
		<Box
			minHeight={"100vh"}
			sx={{
				ml: isMobile ? "0px" : "100px",
				backgroundColor: theme.palette.secondary.dark,
			}}
		>
			<Navbar
				minForce={true}
				handleOpenCreatePost={() => setOpen(true)}
			/>
			<Box>
				<Stack direction="row" minHeight={"100vh"}>
					<SettingsSection />
				</Stack>
			</Box>
			<CreatePostSectionModale
				open={open}
				handleClose={() => setOpen(false)}
				handleAddPost={handleAddPost}
			/>
			<BottomNavbar />
		</Box>
	);
};

export default SettingsPage;
