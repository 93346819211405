import {
	Avatar,
	BottomNavigation,
	BottomNavigationAction,
	Paper,
} from "@mui/material";
import { IoIosSearch } from "react-icons/io";
import { GoHome } from "react-icons/go";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const BottomNavbar = () => {
	const SXBottomNavigationAction = {
		"& .MuiBottomNavigationAction-label": { fontSize: "16px" },
	};
	const { user } = useSelector((state) => state.auth);
	return (
		<Paper
			sx={{
				position: "fixed",
				bottom: 0,
				left: 0,
				right: 0,
				zIndex: 10,
				display: {
					xs: "block",
					sm: "none",
					md: "none",
					lg: "none",
					xl: "none",
				},
			}}
			elevation={3}
		>
			<BottomNavigation showLabels>
				<BottomNavigationAction
					component={Link}
					to="/feed"
					label="Accueil"
					icon={<GoHome size={30} />}
					sx={SXBottomNavigationAction}
				/>
				<BottomNavigationAction component={Link} to="/search" label="Recherche" icon={<IoIosSearch size={30}/>} sx={SXBottomNavigationAction}/>
				<BottomNavigationAction
					component={Link}
					to={"/profile/"+user?.id}
					label="Profil"
					icon={
						<Avatar
							alt="Profile picture"
							src={user?.profilePicture}
							sx={{
								width: "30px",
								height: "30px",
								borderRadius: 1,
								ml: "auto",
								mr: "auto",
							}}
						/>
					}
					sx={SXBottomNavigationAction}
				/>
			</BottomNavigation>
		</Paper>
	);
};

export default BottomNavbar;
