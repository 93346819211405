import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProfileTabs from "../../molecules/Tabs/ProfileTabs";
import ProfileCard from "../../molecules/Card/ProfileCard";
import FollowModal from "../../molecules/Modal/FollowModal";
import {
	requestAxiosGetFollowers,
	requestAxiosGetFollowings,
	requestAxiosToggleFollowing,
} from "../../../services/axios/axiosRequests";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	addOneFollower,
	addOneFollowing,
	addUsersList,
	cleanErrorU,
	deleteOneFollower,
	deleteOneFollowing,
	editUserProfil,
} from "../../../store/slices/userSlice";
import { toast } from "react-toastify";
import ConfirmModal from "../../molecules/Modal/ConfirmModal";
import EditProfilModal from "../../molecules/Modal/EditProfileModal";
import LoadingSection from "../../organisms/LoadingSection/LoadingSection";
import { updateProfile } from "../../../store/slices/authSlice";
import { cleanDataP } from "../../../store/slices/postSlice";
import dayjs from "dayjs";

const ProfilSection = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [openFollowers, setOpenFollowers] = useState(false);
	const [openFollowing, setOpenFollowings] = useState(false);
	const [openEditProfil, setOpenEditProfil] = useState(false);
	const [openConfirm, setOpenConfirm] = useState(false);
	const [errors, setErrors] = useState();
	const [openCancelEditProfil, setOpenCancelEditProfil] = useState(false);
	const [followersList, setFollowersList] = useState([]);
	const [followingList, setFollowingList] = useState([]);
	const [followersPage, setFollowersPage] = useState(1);
	const [followingPage, setFollowingPage] = useState(1);
	const [loadingBtn, setLoadingBtn] = useState(false);
	const [loadingFollowers, setLoadingFollowers] = useState(false);
	const [loadingFollowing, setLoadingFollowing] = useState(false);
	const [loadingEditProfile, setLoadingEditProfile] = useState(false);

	const user = useSelector((state) => state?.users?.itemsU[id]);
	const auth = useSelector((state) => state?.auth);
	const currentUser = useSelector(
		(state) => state?.users?.itemsU[auth?.user?.id]
	);

	const fetchFollowers = async () => {
		if (!loadingFollowers) {
			setLoadingFollowers(true);
			const { items, pagination } = await requestAxiosGetFollowers(
				id,
				followersPage
			);

			if (followersList.length > 1) {
				const existingFollowersIds = new Set(
					followersList?.map((follower) => follower?.id)
				);
				// on garde uniquement les nouveaux posts qui ne sont pas déjà présents dans la liste
				const newFollowers = items?.filter(
					(follower) => !existingFollowersIds.has(follower?.id)
				);
				dispatch(addUsersList({ users: newFollowers }));
				setFollowersList([...followersList, ...newFollowers]);
			} else {
				if (items?.length > 0) {
					dispatch(addUsersList({ users: items }));
					setFollowersList(items);
				}
			}
			const endData = followersList?.length === pagination?.total;
			if (!endData) {
				setFollowersPage(followersPage + 1);
			}
			setLoadingFollowers(false);
		}
	};

	const fetchFollowing = async () => {
		if (!loadingFollowing) {
			setLoadingFollowing(true);
			const { items, pagination } = await requestAxiosGetFollowings(
				id,
				followingPage
			);
			if (followingList.length > 1) {
				const existingFollowersIds = new Set(
					followingList?.map((follower) => follower?.id)
				);
				// on garde uniquement les nouveaux posts qui ne sont pas déjà présents dans la liste
				const newFollowers = items?.filter(
					(follower) => !existingFollowersIds.has(follower?.id)
				);
				dispatch(addUsersList({ users: newFollowers }));
				setFollowingList([...followingList, ...newFollowers]);
			} else {
				if (items?.length > 0) {
					dispatch(addUsersList({ users: items }));
					setFollowingList(items);
				}
			}
			const endData = followingList.length === pagination?.total;
			if (!endData) {
				setFollowingPage(followingPage + 1);
			}
			setLoadingFollowing(false);
		}
	};

	const handleClickFollow = async () => {
		setLoadingBtn(true);
		try {
			await requestAxiosToggleFollowing(id);
			dispatch(addOneFollower({ userId: id }));
			dispatch(addOneFollowing({ userId: auth?.user?.id }));
			setLoadingBtn(false);
		} catch (err) {
			setLoadingBtn(false);
			toast.error(err?.message || "un problème est survenu", {
				toastId: "follow-error",
			});
			dispatch(cleanErrorU());
		}
	};

	const handleClickUnFollow = async () => {
		setLoadingBtn(true);
		setOpenConfirm(false);
		try {
			await requestAxiosToggleFollowing(id);
			dispatch(deleteOneFollower({ userId: id }));
			dispatch(deleteOneFollowing({ userId: auth?.user?.id }));
			setLoadingBtn(false);
		} catch (err) {
			setLoadingBtn(false);
			toast.error(err?.message || "un problème est survenu", {
				toastId: "follow-error",
			});
			dispatch(cleanErrorU());
		}
	};

	const handleCloseFollow = async () => {
		setOpenFollowers(false);
		setOpenFollowings(false);
		setFollowersPage(1);
		setFollowingPage(1);
		setFollowersList([]);
		setFollowingList([]);
	};

	const handleSave = (
		profilePicture,
		bannerImage,
		lastName,
		firstName,
		birthday,
		bio
	) => {
		let valid = true;
		let newErrors = {
			firstName: "",
			lastName: "",
			bio: "",
			birthday: "",
		};

		if (!firstName.trim()) {
			valid = false;
			newErrors.firstName = "Le prénom est requis.";
		} else if (firstName.length > 30) {
			valid = false;
			newErrors.firstName =
				"Le prénom ne doit pas dépasser les 30 caractères";
		}

		if (!lastName.trim()) {
			valid = false;
			newErrors.lastName = "Le nom est requis.";
		} else if (lastName.length > 30) {
			valid = false;
			newErrors.lastName =
				"Le nom de famille ne doit pas dépasser les 30 caractères";
		}
		if (bio?.length > 255) {
			valid = false;
			newErrors.bio = "La bio ne doit pas dépasser les 255 caractères";
		}
		if (!birthday) {
			newErrors.birthday = "La date de naissance est requise.";
		} else {
			const age = dayjs().diff(birthday, "year");
			if (age < 16) {
				valid = false;
				newErrors.birthday =
					"Vous devez avoir au moins 16 ans pour vous inscrire sur GoodMove.";
			}
		}
		setErrors(newErrors);
		if (valid) {
			dispatch(
				editUserProfil({
					userId: auth?.user?.id,
					profilePicture,
					bannerImage,
					lastName,
					firstName,
					birthday: birthday.format("YYYY/MM/DD"),
					bio,
				})
			);
		}
	};

	const handleCloseEditProfil = async () => {
		setOpenCancelEditProfil(false);
		setOpenEditProfil(false);
	};

	// detection de maj du profil
	useEffect(() => {
		if (!loadingEditProfile && currentUser?.loadingUPatch) {
			setLoadingEditProfile(true);
		} else if (loadingEditProfile && !currentUser?.loadingUPatch) {
			handleCloseEditProfil();
			dispatch(
				updateProfile({
					profilePicture: currentUser?.profilePicture,
					bannerImage: currentUser?.bannerImage,
					lastName: currentUser?.lastName,
					firstName: currentUser?.firstName,
					birthday: currentUser?.birthday,
					bio: currentUser?.bio,
				})
			);
			dispatch(cleanDataP());
			setLoadingEditProfile(false);
			toast.success("Informations du profil mises à jour. ", {
				toastId: "edited-profile-success",
			});
		}
	}, [currentUser]);

	useEffect(() => {}, [currentUser]);

	return (
		<Container sx={{ pl: { xs: 0, sm: 2 }, pr: { xs: 0, sm: 2 } }}>
			{!loadingEditProfile ? (
				<>
					<ProfileCard
						setOpenFollowers={setOpenFollowers}
						setOpenFollowings={setOpenFollowings}
						setOpenEditProfil={setOpenEditProfil}
						handleClickFollow={handleClickFollow}
						handleClickUnFollow={() => setOpenConfirm(true)}
						loadingBtn={loadingBtn}
					/>
					<ProfileTabs />
				</>
			) : (
				<LoadingSection />
			)}
			<FollowModal
				open={openFollowers}
				data={followersList}
				handleFetch={fetchFollowers}
				handleClose={handleCloseFollow}
				title={"Abonnés"}
			/>
			<FollowModal
				open={openFollowing}
				data={followingList}
				handleFetch={fetchFollowing}
				handleClose={handleCloseFollow}
				title={"Abonnements"}
			/>
			<EditProfilModal
				open={openEditProfil}
				handleClose={() => setOpenCancelEditProfil(true)}
				handleSave={handleSave}
				errors={errors}
				setErrors={setErrors}
			/>
			<ConfirmModal
				open={openConfirm}
				title={"Supprimer le follow ?"}
				description={
					user?.firstName +
					" " +
					user?.lastName +
					" ne sera pas au courant de cette action."
				}
				handleClose={() => setOpenConfirm(false)}
				handleConfirmClick={handleClickUnFollow}
			/>
			<ConfirmModal
				open={openCancelEditProfil}
				title={"Annuler les modifications"}
				description={
					"Attention, vous être sur le point de perdre les modifications apportées sur votre profil. Êtes vous sûr de vouloir fermer la fenêtre ?"
				}
				handleClose={() => setOpenCancelEditProfil(false)}
				handleConfirmClick={handleCloseEditProfil}
				confirmTitle={"Fermer"}
			/>
		</Container>
	);
};

export default ProfilSection;
