import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/themes/theme";
import { CardMedia, Tab, Tabs, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/system";
import SearchResults from "../SearchResults/SearchResults";
import searchGif from "../../../assets/videos/magnifying-glass.gif";

const SearchTabs = ({ data, filter, input, loadingData }) => {
	const [activeTab, setActiveTab] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams();

	const handleTabChange = (event, newValue) => {
		switch (newValue) {
			case 1:
				setSearchParams("filter=users");
				break;
			case 2:
				setSearchParams("filter=tags");
				break;
			default:
				setSearchParams("");
				break;
		}
	};

	useEffect(() => {
		const filterParam = searchParams.get("filter");
		switch (filterParam) {
			case "users":
				return setActiveTab(1);
			case "tags":
				return setActiveTab(2);
			default:
				return setActiveTab(0);
		}
	}, [searchParams]);

	return (
		<Box>
			<Box display={"flex"} justifyContent={"center"} mb={2}>
				<Tabs
					value={activeTab}
					onChange={handleTabChange}
					aria-label="navigation tabs"
					variant="scrollable"
					scrollButtons="auto"
					sx={{
						display: "flex",
						justifyContent: "center",
						".MuiTab-root": {
							[theme.breakpoints.up("lg")]: {
								minWidth: "160px",
								margin: "0 20px",
							},
							[theme.breakpoints.between("sm", "lg")]: {
								minWidth: "80px",
								margin: "0 15px",
							},
							[theme.breakpoints.down("sm")]: {
								minWidth: "50px",
								margin: "0 10px",
							},
						},
					}}
				>
					<Tab label="Tout" value={0} />
					<Tab label="Utilisateurs" value={1} />
					<Tab label="Tags" value={2} />
				</Tabs>
			</Box>
			{input && input.trim().length > 1 ? (
				<SearchResults data={data} filter={filter} loadingData={loadingData}/>
			) : (
				<Box display={"flex"} justifyContent={"center"} flexDirection={'column'}>
					<CardMedia
						component="img"
						image={searchGif}
						alt="wait-ro-search"
						sx={{width:'80px', height:'80px', ml:'auto', mr:'auto', mt:8}}
					/>
					<Typography textAlign={'center'} mt={3} fontWeight={500} color={theme.palette.otherColor.main}>Que souhaitez vous chercher ?</Typography>
				</Box>
			)}
		</Box>
	);
};

export default SearchTabs;
