import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	Divider,
	IconButton,
	Modal,
	Skeleton,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React from "react";
import { theme } from "../../../assets/themes/theme";
import notFoundGif from "../../../assets/videos/404.gif";
import soonGif from "../../../assets/videos/soon.gif";
import CloseIcon from "@mui/icons-material/Close";

const PostErrorModal = ({ open, handleClose, isError }) => {
	const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));
	const SXBoxContainer = {
		display: "flex",
		ml: "auto",
		mr: "auto",
	};

	const SXCardMediaWeb = {
		width: {
			lg: "580px",
			xl: "700px",
		},
		height: {
			lg: "464px",
			xl: "560px",
		},
		borderRadius: "10px",
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
			sx={{
				display: "flex",
				justifyContent: "center",
				flexDirection: "column",
			}}
		>
			<Card
				sx={{
					width: {
						xs: "100%",
						sm: "550px",
						md: "850px",
						lg: "1150px",
						xl: "1480px",
					},
					height: {
						xs: "100%",
						sm: "auto",
					},
					m: "auto",
				}}
			>
				<Box sx={SXBoxContainer}>
					{!smallScreen && (
						<Box ml={2} mt={2} mb={2}>
							<Skeleton
								variant="rectangular"
								sx={{ ...SXCardMediaWeb }}
								animation="wave"
							/>
						</Box>
					)}
					<Box
						flex={1}
						display={"flex"}
						flexDirection={"column"}
						sx={{
							maxHeight: {
								xs: "100vh",
								sm: "100vh",
								md: "100vh",
								lg: "464px",
								xl: "560px",
							},
							overflowY: "scroll",
						}}
					>
						<CardHeader
							avatar={
								<Skeleton
									variant="rectangular"
									width={40}
									height={40}
									animation="wave"
								/>
							}
							action={
								<>
									<IconButton
										aria-label="close"
										onClick={handleClose}
										sx={{ mr: 1 }}
									>
										<CloseIcon />
									</IconButton>
								</>
							}
							title={
								<Skeleton
									variant="text"
									sx={{ fontSize: "1rem" }}
									animation="wave"
								/>
							}
							subheader={
								<Skeleton
									variant="text"
									sx={{ fontSize: "1rem" }}
									animation="wave"
								/>
							}
							sx={{
								pb: 1,
								".MuiCardHeader-title": {
									fontSize: "16px",
									fontWeight: "600",
								},
								".MuiCardHeader-subheader": {
									fontSize: "12px",
									fontWeight: "500",
									color: theme.palette.primary.main,
								},
							}}
						/>
						<CardContent sx={{ pt: 1, pb: 1 }}>
							<Skeleton
								variant="text"
								sx={{ fontSize: "1rem" }}
								animation="wave"
							/>
							<Skeleton
								variant="text"
								sx={{ fontSize: "1rem" }}
								animation="wave"
							/>
							<Skeleton
								variant="text"
								sx={{ fontSize: "1rem" }}
								animation="wave"
							/>
							<Skeleton
								variant="text"
								sx={{ fontSize: "1rem" }}
								animation="wave"
							/>
							<Skeleton
								variant="text"
								sx={{ fontSize: "1rem" }}
								animation="wave"
							/>
						</CardContent>

						{smallScreen && (
							<Box ml={"auto"} mr={"auto"}>
								<Skeleton
									variant="rectangular"
									animation="wave"
									sx={{
										width: {
											xs: "400px",
											sm: "500px",
											md: "700px",
										},
										height: {
											xs: "250px",
											sm: "300px",
											md: "400px",
										},
										borderRadius: "10px",
									}}
								/>
							</Box>
						)}
						<Box m={1} flex={1}>
							<Divider sx={{ mt: 3, ml: 5, mr: 5, mb: 2 }} />
							{isError ? (
								<Box
									display={"flex"}
									justifyContent={"center"}
									flexDirection={"column"}
									mb={10}
								>
									<CardMedia
										component="img"
										image={notFoundGif}
										alt="wait-ro-search"
										sx={{
											width: "60px",
											height: "60px",
											ml: "auto",
											mr: "auto",
											mt: 2,
										}}
									/>
									<Typography
										textAlign={"center"}
										mt={1}
										fontWeight={500}
										fontSize={14}
										color={theme.palette.otherColor.main}
									>
										Il est possible que cette publication
										ait été supprimée.
									</Typography>
								</Box>
							) : (
								<Box
									display={"flex"}
									justifyContent={"center"}
									flexDirection={"column"}
									mb={10}
								>
									<CardMedia
										component="img"
										image={soonGif}
										alt="wait-ro-search"
										sx={{
											width: "60px",
											height: "60px",
											ml: "auto",
											mr: "auto",
											mt: 2,
										}}
									/>
									<Typography
										textAlign={"center"}
										mt={1}
										fontWeight={500}
										fontSize={14}
										color={theme.palette.otherColor.main}
									>
										Chargement de la publication en cours...
									</Typography>
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</Card>
		</Modal>
	);
};

export default PostErrorModal;
