import {
	Box,
	Card,
	Divider,
	Icon,
	IconButton,
	Switch,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React from "react";
import { theme } from "../../../assets/themes/theme";
import CustomButton from "../../atoms/Buttons/CustomButton";
import CloseIcon from "@mui/icons-material/Close";

const SwitchCard = ({
	title,
	onChange,
	icon,
	active,
	subtitle,
	description,
	handeSave,
	handleClose,
	loading,
}) => {
	const mediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
	const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Card
			sx={{
				maxWidth: mediumScreen ? "none" : "750px",
				width: {
					xs: "100%",
					sm: "100%",
					md: "100%",
				},
				height: {
					xs: "100%",
					sm: "100%",
					md: "auto",
				},
				pt: 2,
				pb: 2,
				borderRadius: "16px",
				minHeight: mediumScreen ? "100vh" : "100%",
				ml: "auto",
				mr: "auto",
				backgroundColor: theme.palette.secondary.main,
				overflowY: "scroll",
			}}
		>
			{mediumScreen && (
				<IconButton
					sx={{ top: 30, left: 10, position: "absolute" }}
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			)}
			<Box display={"flex"} justifyContent={"center"} mb={2} mt={2}>
				<Typography variant="h5" mr={2} mt={"auto"} mb={"auto"}>
					{title}
				</Typography>
				<Icon
					component={icon}
					sx={{
						mt: "auto",
						mb: "auto",
						width: "30px",
						height: "30px",
					}}
				/>
			</Box>
			<Divider sx={{ mb: 2 }} />
			<Box m={4} display={"flex"} flexDirection={"column"}>
				<Box display={"flex"} justifyContent={"space-between"} mb={3}>
					<Typography fontSize={"20px"}>{subtitle}</Typography>
					<Switch
						checked={active}
						onChange={onChange}
						sx={{ color: theme.palette.primary.main }}
					/>
				</Box>
				<Typography mb={5}>{description}</Typography>
				<CustomButton
					content={"Sauvegarder"}
					css={{
						width: "300px",
						mr: mobileScreen ? "auto" : 0,
						ml: "auto",
					}}
					onClick={handeSave}
					// disabled={loading}
					disabled={true}
				/>
				<Typography
					mb={5}
					mt={3}
					sx={{ textAlign: "center" }}
					color={theme.palette.error.main}
				>
					Fonctionnalité bientôt disponible sur GoodMove
				</Typography>
			</Box>
		</Card>
	);
};

export default SwitchCard;
