import { Autocomplete, Chip, TextField } from "@mui/material";
import React from "react";

const InputAutoComplete = ({
	data,
	setSelection,
	setIsFocus,
}) => {
	const handleOnChange = (e, v) => {
		e.preventDefault();
		setSelection(v);
	};

	const handleOnBlur = () =>{
		if (setIsFocus) {
			setIsFocus(false)
		}
	}	
	
	const handleOnFocus = () =>{
		if (setIsFocus) {
			setIsFocus(true)
		}
	}

	return (
		<Autocomplete
			multiple
			limitTags={3}
			id="multiple-limit-tags"
			options={data}
			getOptionLabel={(option) => option?.name}
			getOptionKey={(option) => option?.id}
			isOptionEqualToValue={(option, value) => option?.id === value?.id}
			renderInput={(params) => (
				<TextField {...params} label="Tags" variant="standard" placeholder="Tags" autoFocus />
			)}
			onChange={handleOnChange}
			renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => {
                const { key, ...rest } = getTagProps({ index });
                return (
                    <Chip
                        label={option['name']}
                        {...rest}
                        key={key}
                    />
                );
            })
        }
			onFocus={handleOnFocus}
			onBlur={handleOnBlur}
			sx={{ m: 2}}
		/>
	);
};

export default InputAutoComplete;
