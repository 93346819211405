import {
	Avatar,
	Box,
	Card,
	Divider,
	IconButton,
	TextField,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/themes/theme";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import banner from "../../../assets/images/banner.png";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { MobileDatePicker } from "@mui/x-date-pickers";
import CustomButton from "../../atoms/Buttons/CustomButton";
import ImageCropper from "../ImageCropper/ImageCropper";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { editUserProfil } from "../../../store/slices/userSlice";

const EditProfileCard = ({ handleClose, loading }) => {
	const auth = useSelector((state) => state?.auth);
	const mediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
	const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [croppingImage, setCroppingImage] = useState(null);
	const [profilePicture, setProfilePicture] = useState(null);
	const [cropContext, setCropContext] = useState("");
	const [bannerImage, setBannerImage] = useState(null);
	const [lastname, setLastname] = useState("");
	const [firstname, setFirstname] = useState("");
	const [birthday, setBirthday] = useState(null);
	const [errors, setErrors] = useState(null);
	const [bio, setBio] = useState("");

	const dispatch = useDispatch();
	dayjs.extend(localizedFormat);

	const handleSave = (
		profilePicture,
		bannerImage,
		lastName,
		firstName,
		birthday,
		bio
	) => {
		let valid = true;
		let newErrors = {
			firstName: "",
			lastName: "",
			bio: "",
			birthday: "",
		};

		if (!firstName.trim()) {
			valid = false;
			newErrors.firstName = "Le prénom est requis.";
		} else if (firstName.length > 30) {
			valid = false;
			newErrors.firstName =
				"Le prénom ne doit pas dépasser les 30 caractères";
		}

		if (!lastName.trim()) {
			valid = false;
			newErrors.lastName = "Le nom est requis.";
		} else if (lastName.length > 30) {
			valid = false;
			newErrors.lastName =
				"Le nom de famille ne doit pas dépasser les 30 caractères";
		}
		if (bio?.length > 255) {
			valid = false;
			newErrors.bio = "La bio ne doit pas dépasser les 255 caractères";
		}
		if (!birthday) {
			newErrors.birthday = "La date de naissance est requise.";
		} else {
			const age = dayjs().diff(birthday, "year");
			if (age < 16) {
				valid = false;
				newErrors.birthday =
					"Vous devez avoir au moins 16 ans pour vous inscrire sur GoodMove.";
			}
		}
		setErrors(newErrors);
		if (valid) {
			dispatch(
				editUserProfil({
					userId: auth?.user?.id,
					profilePicture,
					bannerImage,
					lastName,
					firstName,
					birthday: birthday.format("YYYY/MM/DD"),
					bio,
				})
			);
		}
	};

	const handleImageChange = (event, type) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				setCropContext(type);
				setCroppingImage(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleCropComplete = (croppedAreaPixels) => {
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");
		const image = new Image();
		image.src = croppingImage;
		image.onload = () => {
			canvas.width = croppedAreaPixels.width;
			canvas.height = croppedAreaPixels.height;
			ctx.drawImage(
				image,
				croppedAreaPixels.x,
				croppedAreaPixels.y,
				croppedAreaPixels.width,
				croppedAreaPixels.height,
				0,
				0,
				croppedAreaPixels.width,
				croppedAreaPixels.height
			);
			canvas.toBlob((blob) => {
				const nameDate = Math.round(Date.now() / 1000);
				const url = URL.createObjectURL(blob);
				const file = new File([blob], nameDate + ".jpg", {
					type: "image/jpeg",
				});
				const newImg = { file, url };
				if (cropContext === "banner") {
					setBannerImage(newImg);
				} else {
					setProfilePicture(newImg);
				}
				setCroppingImage(null);
			}, "image/jpeg");
		};
	};

	useEffect(() => {
		auth.user?.backgroundPicture &&
			setBannerImage(auth.user?.backgroundPicture);
		auth.user?.profilePicture &&
			setProfilePicture(auth.user?.profilePicture);
		auth.user?.firstName && setFirstname(auth.user?.firstName);
		auth.user?.lastName && setLastname(auth.user?.lastName);
		setBirthday(dayjs(auth.user?.birthday));
		auth.user?.bio && setBio(auth.user?.bio);
	}, []);

	return (
		<Box mb={8} mr={4} width={"100%"} height={"100%"}>
			<Card
				sx={{
					width: {
						xs: "100%",
						sm: "100%",
						md: "100%",
					},
					height: {
						xs: "100%",
						sm: "100%",
						md: "auto",
					},
					maxWidth: mediumScreen ? "none" : "750px",
					pt: 2,
					pb: 2,
					borderRadius: "16px",
					minHeight: mediumScreen ? "100vh" : "100%",
					ml: "auto",
					mr: "auto",
					backgroundColor: theme.palette.secondary.main,
					position: "relative",
					overflowY: "scroll",
				}}
			>
				{mediumScreen && (
					<IconButton
						sx={{ top: 30, left: 10, position: "absolute" }}
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
				)}

				<Box display={"flex"} justifyContent={"center"} mb={2} mt={2}>
					<Typography variant="h5" mr={2} mt={"auto"} mb={"auto"}>
						Modifier le profil
					</Typography>
					<AccountCircleOutlinedIcon
						sx={{
							mt: "auto",
							mb: "auto",
							width: "30px",
							height: "30px",
						}}
					/>
				</Box>
				<Divider sx={{ mb: 2 }} />
				<Box
					sx={{
						ml: 2,
						mr: 2,
						display: "flex",
						flexDirection: "column",
						flexWrap: "wrap",
					}}
				>
					<Box sx={{ position: "relative", mb: 1, mr: 1 }}>
						<Box
							component="img"
							src={bannerImage?.url || bannerImage || banner}
							alt="Bannière"
							sx={{
								width: "100%",
								height: "auto",
								objectFit: "cover",
								borderRadius: "8px",
								opacity: 0.8,
							}}
						/>
						<IconButton
							component="label"
							sx={{
								position: "absolute",
								width: "100%",
								height: "100%",
								borderRadius: "8px",
								top: "50%",
								right: "50%",
								transform: "translate(50%, -50%)",
								"&:hover": {
									backgroundColor:
										theme.palette.secondary.light,
									opacity: "0.5",
								},
							}}
						>
							<input
								type="file"
								hidden
								accept="image/*"
								onChange={(e) => handleImageChange(e, "banner")}
							/>
							<AddCircleOutlineIcon
								sx={{
									color: theme.palette.secondary.light,
								}}
							/>
						</IconButton>
					</Box>
					<Box
						display={"flex"}
						mb={2}
						justifyContent={"space-evenly"}
						alignItems={"center"}
						flexWrap={mobileScreen ? "wrap" : "none"}
						sx={{ mt: { xs: 1, sm: 1, md: 3 }, mr: 1 }}
					>
						<Box
							sx={{
								position: "relative",
								width: "120px",
								height: "120px",
							}}
						>
							<Avatar
								src={profilePicture?.url || profilePicture}
								alt="Profil"
								sx={{
									width: "120px",
									height: "120px",
									borderRadius: "8px",
									mt: "auto",
									mb: "auto",
									opacity: 0.8,
								}}
							/>
							<IconButton
								component="label"
								sx={{
									position: "absolute",
									width: "100%",
									height: "100%",
									borderRadius: "8px",
									top: "50%",
									right: "50%",
									transform: "translate(50%, -50%)",
									"&:hover": {
										backgroundColor:
											theme.palette.secondary.light,
										opacity: "0.5",
									},
								}}
							>
								<input
									type="file"
									hidden
									accept="image/*"
									onChange={(e) =>
										handleImageChange(e, "profile")
									}
								/>
								<AddCircleOutlineIcon
									sx={{
										color: theme.palette.secondary.light,
									}}
								/>
							</IconButton>
						</Box>
						<Box
							display={"block"}
							sx={{
								ml: mobileScreen ? 0 : 2,
								mt: mobileScreen ? 2 : 1,
								mb: 1,
								mr: mobileScreen ? 0 : 1,
								width: "100%",
							}}
						>
							<TextField
								label="Nom"
								variant="outlined"
								fullWidth
								value={lastname}
								onChange={(e) => {
									errors?.lastName &&
										setErrors({
											...errors,
											lastName: "",
										});
									setLastname(e.target.value);
								}}
								error={errors?.lastName || false}
								helperText={
									errors?.lastName && errors?.lastName
								}
								sx={{
									ml: { xs: 0, sm: 0, md: 1 },
									mb: { xs: 2, sm: 2, md: 1 },
								}}
							/>
							<TextField
								label="Prénom"
								variant="outlined"
								fullWidth
								value={firstname}
								onChange={(e) => {
									errors?.firstName &&
										setErrors({
											...errors,
											firstName: "",
										});
									setFirstname(e.target.value);
								}}
								error={errors?.firstName || false}
								helperText={
									errors?.firstName && errors?.firstName
								}
								sx={{
									ml: { xs: 0, sm: 0, md: 1 },
								}}
							/>
						</Box>
					</Box>
					<Box
						display={"flex"}
						flexDirection={"column"}
						mb={2}
						mr={1}
					>
						<MobileDatePicker
							label={"Date de naissance"}
							sx={{ width: "100%" }}
							value={birthday}
							onChange={(e) => {
								errors?.birthday &&
									setErrors({
										...errors,
										birthday: "",
									});
								setBirthday(e);
							}}
						/>
						{errors?.birthday && (
							<Typography
								color={theme.palette.error.main}
								fontSize={12}
								mt={0.5}
								ml={"auto"}
								mr={"auto"}
							>
								{errors?.birthday}
							</Typography>
						)}
					</Box>
					<TextField
						label="Bio"
						variant="outlined"
						fullWidth
						multiline
						rows={3}
						value={bio}
						onChange={(e) => {
							errors?.bio &&
								setErrors({
									...errors,
									bio: "",
								});
							setBio(e.target.value);
						}}
						error={errors?.bio || false}
						helperText={errors?.bio && errors?.bio}
						sx={{ mb: 2, mr: 1 }}
					/>

					<CustomButton
						content={"Sauvegarder"}
						css={{
							width: "300px",
							mr: mobileScreen ? "auto" : 1,
							mb: 20,
						}}
						onClick={() =>
							handleSave(
								profilePicture,
								bannerImage,
								lastname,
								firstname,
								birthday,
								bio
							)
						}
						disabled={loading}
					/>
				</Box>
			</Card>
			{croppingImage && (
				<ImageCropper
					imageSrc={croppingImage}
					onCropComplete={handleCropComplete}
					onCancel={() => {
						setCroppingImage(null);
					}}
					onClose={() => {
						setCroppingImage(null);
					}}
					aspect={cropContext === "banner" ? 4 / 1 : 1 / 1}
				/>
			)}
		</Box>
	);
};

export default EditProfileCard;
