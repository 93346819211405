import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/themes/theme";
import NotifsTabs from "../../molecules/Tabs/NotifsTabs";
import { Box, Snackbar, Typography } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	cleanDataN,
	cleanErrorN,
	fetchNotifs,
} from "../../../store/slices/notifSlice";
import { toast } from "react-toastify";
import BackBtn from "../../atoms/Buttons/BackBtn";
import ReplayIcon from "@mui/icons-material/Replay";

const NotifSection = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [loadingData, setloadingData] = useState(false);
	const [newReceived, setNewReceived] = useState(false);
	const [categories, setCategories] = useState(null);
	const dispatch = useDispatch();
	const { itemsN, loadingN, errorN, pageN, limitN, nbNewsNotifs } =
		useSelector((state) => state.notifs);

	if (errorN) {
		toast.error(errorN?.message || "un problème est survenu", {
			toastId: "notifications-error",
		});
		dispatch(cleanErrorN());
	}

	useEffect(() => {
		const filterParam = searchParams.get("filter");
		setloadingData(true);
		dispatch(cleanDataN());
		switch (filterParam) {
			case "publications":
				return setCategories("SOCIALPOST");
			case "relations":
				return setCategories("RELATIONS");
			case "trophies":
				return setCategories("CHALLENGE");
			default:
				return setCategories("ALL");
		}
	}, [searchParams]);

	const handleFetchNotifs = () => {
		dispatch(
			fetchNotifs({
				pageN: pageN + 1,
				limitN,
				categories: categories === "ALL" ? null : categories,
			})
		);
	};

	const handleLoadNewsNotifs = () => {
		dispatch(
			fetchNotifs({
				pageN: 1,
				limitN,
				categories: categories === "ALL" ? null : categories,
			})
		);
	};

	useEffect(() => {
		if (loadingData && categories !== null) {
			handleFetchNotifs();
		}
	}, [categories]);

	useEffect(() => {
		if (!loadingN && loadingData) {
			setloadingData(false);
		}
	}, [loadingN]);

	useEffect(() => {
		if (nbNewsNotifs > 0 && !loadingData) {
			setNewReceived(true);
			handleLoadNewsNotifs();
		} else {
			setNewReceived(false);
		}
	}, [nbNewsNotifs]);

	return (
		<Box
			bgcolor={theme.palette.secondary.dark}
			flex={4}
			p={2}
			ml={1}
			mr={1}
			mb={6}
			position={"relative"}
			sx={{ boxShadow: newReceived && "0px 0px 9px -2px #1ABD8C" }}
		>
			<Box
				display={"flex"}
				justifyContent={"center"}
				mb={2}
				position={"relative"}
			>
				<BackBtn
					css={{ width: "30px" }}
					cssBtn={{
						width: "40px",
						height: "40px",
						mt: "auto",
						mb: "auto",
						mr: 1,
						position: "absolute",
						left: 10,
					}}
				/>
				<Typography
					variant="h5"
					mt={1}
					mb={1}
					textAlign={"center"}
					width={"100%"}
				>
					Notifications
				</Typography>
			</Box>
			<NotifsTabs
				data={itemsN}
				loadingData={loadingData}
				setloadingData={setloadingData}
			/>
			{!loadingN && (
				<Box
					textAlign={"center"}
					onClick={handleFetchNotifs}
					sx={{ cursor: "pointer" }}
					mt={5}
					mb={2}
				>
					<ReplayIcon />
				</Box>
			)}

			<Snackbar
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				open={newReceived}
				onClick={() => {
					window.scrollTo({ top: 0, behavior: "smooth" });
					setNewReceived(false);
				}}
				onClose={() => setNewReceived(false)}
				message="Nouvelles notifications"
				sx={{
					cursor: "pointer",
					textAlign: "center",
					bgcolor: theme.palette.secondary.main,
					color: theme.palette.otherColor.main,
					boxShadow: "0px 0px 9px -2px #1ABD8C",
					fontSize: "20px",
				}}
			/>
		</Box>
	);
};

export default NotifSection;
