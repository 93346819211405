import { Button, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../../assets/themes/theme";

const CustomOutlinedBtn = ({ content, css, onClick, disabled }) => {
	const SXCustomOutlinedBtn = {
		boxShadow: "1px 3px 6px rgba(85, 85, 85, 0.25)",
		ml: "auto",
		mr: "auto",
		height: 39,
		width: "230px",
		...css,
		"&:hover": {
			backgroundColor: theme.palette.secondary.dark,
		},
	};

	return (
		<Button variant="outlined" sx={SXCustomOutlinedBtn} onClick={onClick} disabled={disabled||false}>
			<Typography
				sx={{
					fontSize: 14,
					fontWeight: "600",
				}}
			>
				{content}
			</Typography>
		</Button>
	);
};

export default CustomOutlinedBtn;
