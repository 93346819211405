import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../../assets/themes/theme";

const OrSeparator = () => {

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        m: "10px auto",
      }}
    >
      <Divider
        sx={{
          flexGrow: 1,
          m: 2,
          borderColor: theme.palette.otherColor.main,
        }}
      />
      <Typography
        sx={{
          margin: "0 8px",
          color: theme.palette.otherColor.main,
        }}
      >
        OU
      </Typography>
      <Divider
        sx={{
          flexGrow: 1,
          m: 2,
          borderColor: theme.palette.otherColor.main,
        }}
      />
    </Box>
  );
};

export default OrSeparator;
