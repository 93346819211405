import { Box, Typography } from "@mui/material";
import React from "react";
import InputPassword from "../../atoms/Inputs/InputPassword";
import CustomLoadingButton from "../../atoms/Buttons/CustomLoadingButton";
import { handleKeyDown } from "../../../utils/tool";
import { theme } from "../../../assets/themes/theme";

const ResetPasswordForm = ({
	activeVerif,
	olderPassword,
	password,
	confirmPassword,
	setOlderPassword,
	setPassword,
	setConfirmPassword,
	handleSubmit,
	loading,
	errors,
	setErrors,
	disabled,
}) => {
	return (
		<Box
			component="form"
			autoComplete="on"
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"center"}
			onKeyDown={(e) => handleKeyDown(e, handleSubmit)}
			onSubmit={handleSubmit}
		>
			{activeVerif && (
				<InputPassword
					password={olderPassword}
					setPassword={setOlderPassword}
					label={"Mot de passe actuel"}
					entityName={"currentPassword"}
					errors={errors}
					setErrors={setErrors}
					css={{ mb: 2 }}
					disabled={disabled}
				/>
			)}

			<InputPassword
				password={password}
				setPassword={setPassword}
				label={"Nouveau mot de passe"}
				entityName={"password"}
				errors={errors}
				setErrors={setErrors}
				css={{ mb: 2 }}
				disabled={disabled}
			/>

			<InputPassword
				password={confirmPassword}
				setPassword={setConfirmPassword}
				label={"Confirmer le nouveau mot de passe"}
				entityName={"confirmPassword"}
				errors={errors}
				setErrors={setErrors}
				css={{ mb: 2 }}
				disabled={disabled}
			/>

			<CustomLoadingButton
				content={"Mettre à jour le mot de passe"}
				css={{ mt: 3, width: "100%", maxWidth: "400px" }}
				onClick={handleSubmit}
				loading={loading}
				disabled={disabled}
			/>
			{disabled && <Typography color={theme.palette.error.main} mt={1}>Une connexion linkedin ne peut avoir accès à cette option.</Typography>}
		</Box>
	);
};

export default ResetPasswordForm;
