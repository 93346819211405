import { IconButton, useMediaQuery } from "@mui/material";
import { theme } from "../../../assets/themes/theme";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";

const AddPostMobileBtn = ({handleOpenCreatePost}) => {
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [lastScrollY, setLastScrollY] = useState(0);
	const [opacity, setOpacity] = useState(1);

	// const handleScroll = () => {
	// 	const scrollTop = window.scrollY;
	// 	const newOpacity = 0.7;
	// 	setOpacity(newOpacity);
	// };

	useEffect(() => {
		const handleScroll = () => {
			const currentScrollY = window.scrollY;
			if (currentScrollY > lastScrollY) {
				// Scrolling down
				setOpacity(0.7);
			} else {
				// Scrolling up
				setOpacity(1);
			}
			setLastScrollY(currentScrollY);
		};
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
	}, [lastScrollY]);

	const SXIconButton = {
		display: isMobile ? "bloc" : "none",
		backgroundColor: theme.palette.primary.main,
		position: "fixed",
		bottom: "65px",
		right: "10px",
		opacity: opacity,
		transition: "opacity 0.3s",
		"&:hover": {
			opacity: 1,
			backgroundColor: theme.palette.primary.dark,
		},
		zIndex:10
	};
	return (
		<IconButton
			aria-label="add-post"
			size="large"
			onClick={handleOpenCreatePost}
			color="secondary"
			sx={SXIconButton}
		>
			<AddIcon />
		</IconButton>
	);
};

export default AddPostMobileBtn;
