import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/themes/theme";
import { Tab, Tabs } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { cleanDataP } from "../../../store/slices/postSlice";

const FeedTabs = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();

	const handleTabChange = (event, newValue) => {
		switch (newValue){
            case 1:
				dispatch(cleanDataP());
                setSearchParams("filter=follow")
				break;
            default:
				dispatch(cleanDataP());
                setSearchParams("")
				break;
        }
	};

	useEffect(() => {
		const filterParam = searchParams.get("filter");
        switch (filterParam){
            case 'follow':
                return setActiveTab(1);
            default:
                return setActiveTab(0);
        }
	}, [searchParams]);

	return (
		<Box>
			<Box display={"flex"} justifyContent={"center"} mb={2}>
				<Tabs
					value={activeTab}
					onChange={handleTabChange}
					aria-label="navigation tabs"
					variant="scrollable"
					scrollButtons="auto"
					sx={{
						display: "flex",
						justifyContent: "center",
						".MuiTab-root": {
							[theme.breakpoints.up("lg")]: {
								minWidth: "160px",
								margin: "10px 50px",
							},
							[theme.breakpoints.between("sm", "lg")]: {
								minWidth: "80px",
								margin: "10px 35px",
							},
							[theme.breakpoints.down("sm")]: {
								minWidth: "50px",
								margin: "5px 20px",
							},
						},
					}}
				>
					<Tab label="Pour vous" value={0} />
					<Tab label="Abonnements" value={1} />
				</Tabs>
			</Box>
		</Box>
	);
};

export default FeedTabs;
