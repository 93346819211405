import React from 'react'
import Navbar from '../molecules/Navbar/Navbar'

const EventPage = () => {
  return (
    <div>
      <Navbar />      
      EventPage
    </div>
  )
}

export default EventPage