import {
	Box,
	Grid,
	IconButton,
	Paper,
	Typography,
} from "@mui/material";
import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ForgotForm from "../../molecules/Forms/ForgotForm";
import { theme } from "../../../assets/themes/theme";
import GoodMoveLogo from "../../../assets/images/logo_classic_outlined.svg";

const ForgotSection = ({email, setEmail, handleSubmit, goBack, error}) => {

	const SXBox = {
		ml: "auto",
		mr: "auto",
		mb: 5,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		maxWidth: "500px",
		width: "100%",
	};

	return (
		<Grid
			item
			xs={12}
			sm={7}
			md={5}
			component={Paper}
			elevation={10}
			square
			sx={{
				borderRadius: "0px 7px 7px 0px",
				pl: 2,
				pr: 2,
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<Box sx={SXBox}>
				<img alt="GoodMove" src={GoodMoveLogo} style={{width:'100%', marginLeft:'auto',  marginRight:'auto'}}/>
			</Box>
			<Box sx={{ maxWidth: "550px", ml: "auto", mr: "auto" }}>
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						mb: 4,
						position: "relative",
					}}
				>
					<IconButton
						aria-label="back"
						size="small"
						onClick={() => goBack()}
						sx={{
							position: "absolute",
							left: 0,
							top: "50%",
							transform: "translateY(-50%)",
						}}
					>
						<ArrowBackIosNewIcon />
					</IconButton>
					<Typography
						color={theme.palette.otherColor.main}
						fontWeight={"700"}
						sx={{
							width: "100%",
							maxWidth: "500px",
							textAlign: "center",
						}}
					>
						Mot de passe oublié ?
					</Typography>
				</Box>
				<Typography
					color={theme.palette.otherColor.main}
					sx={{
						width: "100%",
						maxWidth: "500px",
						textAlign: "center",
						fontSize: "14px",
						mt: 4,
						mb: 4,
					}}
				>
					Entrez l'adresse email ou le nom d'utilisateur associé à
					votre compte pour recevoir un mail de réinitiaisation de mot
					de passe.
				</Typography>
				<ForgotForm
					email={email}
					setEmail={setEmail}
					handleSubmit={handleSubmit}
					error={error}
				/>
			</Box>
		</Grid>
	);
};

export default ForgotSection;
