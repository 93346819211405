import { Badge, Box, IconButton } from "@mui/material";
import Logo from "../../../assets/images/logo_classic_outlined.svg";
import { IoMdNotificationsOutline } from "react-icons/io";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/themes/theme";
import DrawerCustom from "../Drawer/DrawerCustom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const HeaderMobile = ({ css }) => {
	const [showHeader, setShowHeader] = useState(true);
	const [lastScrollY, setLastScrollY] = useState(0);
	const { nbNewsNotifs } = useSelector((state) => state.notifs);
	const navigate = useNavigate();

	const controlHeader = () => {
		if (typeof window !== "undefined") {
			if (window.scrollY < lastScrollY) {
				setShowHeader(true);
			} else if (window.scrollY > 70) {
				// Si on scroll vers le bas
				setShowHeader(false);
			}
			setLastScrollY(window.scrollY);
		}
	};

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("scroll", controlHeader);

			return () => {
				window.removeEventListener("scroll", controlHeader);
			};
		}
	}, [lastScrollY]);

	return (
		<Box
			sx={{
				display: {
					xs: "flex",
					sm: "none",
					md: "none",
					lg: "none",
					xl: "none",
				},
				pt: 3,
				pb: 2,
				position: "fixed",
				top: showHeader ? 0 : "-100px",
				left: 0,
				right: 0,
				transition: "top 0.3s",
				zIndex: 1000,
				justifyContent: "space-between",
				backgroundColor: theme.palette.secondary.dark,
				boxShadow: "1px 3px 6px rgba(85, 85, 85, 0.25)",
				...css,
			}}
			elevation={3}
		>
			<Box mt={"auto"} mb={"auto"} ml={2}>
				<Badge badgeContent={nbNewsNotifs} color="error">
					<IconButton onClick={()=>navigate('/notifications')} sx={{p:0.5}}>
						<IoMdNotificationsOutline size={30} />
					</IconButton>
					
				</Badge>
			</Box>
			<img
				src={Logo}
				style={{ width: "200px", margin: "5px auto" }}
				alt="GoodMove"
			/>
			<Box mt={"auto"} mb={"auto"} mr={2}>
				<DrawerCustom />
			</Box>
		</Box>
	);
};

export default HeaderMobile;
