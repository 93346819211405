import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, useMediaQuery } from "@mui/material";
import PublicIcon from "@mui/icons-material/Public";
import MentionIcon from "@mui/icons-material/AlternateEmail";
import GroupIcon from "@mui/icons-material/Group";
import EventIcon from "@mui/icons-material/Event";
import TrophyIcon from "@mui/icons-material/EmojiEvents";
import Trophies from "../Trophies/Trophies";
import FeedSection from "../../organisms/FeedSection/FeedSection";
import AddPostMobileBtn from "../../atoms/Buttons/AddPostMobileBtn";
import { theme } from "../../../assets/themes/theme";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import {
	addPost,
	cleanErrorP,
	fetchPostsByUserId,
	sortProfilePosts,
} from "../../../store/slices/postSlice";
import { useIntersectionObserver } from "../../../hooks/useIntersectionObserver";
import CreatePostSectionModale from "../../organisms/CreatePostSection/CreatePostSectionModal";
import { toast } from "react-toastify";

const ProfileTabs = () => {
	const [activeTab, setActiveTab] = useState(0);
	const [searchParams, setSearchParams] = useSearchParams();
	const isWeb = useMediaQuery(theme.breakpoints.up("lg"));
	const { id } = useParams();
	const dispatch = useDispatch();
	const [open, setOpen] = useState(false);
	const [targetRef, isIntersecting] = useIntersectionObserver({
		threshold: 0.1,
	});

	const handleTabChange = (event, newValue) => {
		switch (newValue) {
			// case 1:
			// 	setSearchParams("filter=mentions");
			// 	break;
			case 2:
				setSearchParams("filter=trophies");
				break;
			default:
				setSearchParams("");
				break;
		}
	};

	useEffect(() => {
		const filterParam = searchParams.get("filter");
		switch (filterParam) {
			case "mentions":
				return setActiveTab(1);
			case "trophies":
				return setActiveTab(2);
			default:
				return setActiveTab(0);
		}
	}, [searchParams]);
	
	const { profilesItems, loadingP, limitP, reloadP, errorP } = useSelector(
		(state) => state.posts
	);

	const user = useSelector((state) => state.auth.user);

	const handleAddPost = (newPost) => {
		open && setOpen(false);
		dispatch(addPost({ userId: user?.id, newPost }));
	};

	const handleClickFetch = () => {
		dispatch(fetchPostsByUserId({ userId: id, page: 1, limit: limitP }));
	};

	useEffect(() => {
		if (
			((!profilesItems[id]?.endDataPI && isIntersecting) ||
				!profilesItems[id] ||
				profilesItems[id]?.itemsPI?.length === 0) &&
			!loadingP
		) {
			dispatch(
				fetchPostsByUserId({
					userId: id,
					page: profilesItems[id]?.pagePI + 1 || 1,
					limit: limitP,
				})
			);
		} else if (profilesItems[id]?.itemsPI?.length > 0) {
			dispatch(sortProfilePosts({ userId: id }));
		}
	}, [isIntersecting, id, reloadP]);

	const propLabel = isWeb ? "label" : "aria-label";

	if (errorP) {
		toast.error(errorP?.message || "un problème est survenu", {
			toastId: "post-error",
		});
		// une fois que l'information a été passée on clean l'erreur
		dispatch(cleanErrorP());
	}

	return (
		<Box>
			<Box display={"flex"} justifyContent={"center"} mb={2}>
				<Tabs
					value={activeTab}
					onChange={handleTabChange}
					aria-label="navigation tabs"
					variant="scrollable"
					scrollButtons="auto"
					sx={{
						mb:2,
						display: "flex",
						justifyContent: "center",
						".MuiTab-root": {
							[theme.breakpoints.up("lg")]: {
								minWidth: "160px",
								margin: "0 20px",
							},
							[theme.breakpoints.between("sm", "lg")]: {
								minWidth: "80px",
								margin: "0 15px",
							},
							[theme.breakpoints.down("sm")]: {
								minWidth: "50px",
								margin: "0 10px",
							},
						},
					}}
				>
					<Tab
						iconPosition="start"
						icon={<PublicIcon />}
						{...{ [propLabel]: "Publications" }}
					/>
					<Tab
						iconPosition="start"
						icon={<MentionIcon />}
						{...{ [propLabel]: "Mentions" }}
						disabled
					/>
					{/* <Tab
						iconPosition="start"
						icon={<GroupIcon />}
						{...{ [propLabel]: "Organisations" }}
						disabled
					/>
					<Tab
						iconPosition="start"
						icon={<EventIcon />}
						{...{ [propLabel]: "Evènements" }}
						disabled
					/> */}
					<Tab
						iconPosition="start"
						icon={<TrophyIcon />}
						{...{ [propLabel]: "Trophées" }}
					/>
				</Tabs>
			</Box>
			{activeTab === 0 && (
				<>
					<FeedSection
						handleAddPost={handleAddPost}
						targetRef={targetRef}
						context="profile"
						handleClickFetch={handleClickFetch}
						filter={false}
						create={user?.id == id} //si c'est notre profil on peut créer des posts
					/>
					{user?.id == id && (
						<>
							<AddPostMobileBtn
								handleOpenCreatePost={() => setOpen(true)}
							/>
							<CreatePostSectionModale
								open={open}
								handleClose={() => setOpen(false)}
								handleAddPost={handleAddPost}
							/>
						</>
					)}
				</>
			)}
			{activeTab === 1 && (
				<FeedSection filter={false} targetRef={targetRef} />
			)}
			{activeTab === 2 && <Trophies />}
		</Box>
	);
};

export default ProfileTabs;
