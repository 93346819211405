import {
	Box,
	Card,
	Divider,
	IconButton,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../../assets/themes/theme";
import ResetPasswordForm from "../Forms/ResetPasswordForm";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { requestAxiosResetPasswordFromConfig } from "../../../services/axios/axiosRequests";
import { useSelector } from "react-redux";

const ResetPasswordCard = ({ handleClose, loadingUpdate }) => {
	const [olderPassword, setOlderPassword] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errors, setErrors] = useState({
		currentPassword: "",
		password: "",
		confirmPassword: "",
	});
	const [loading, setLoading] = useState(false);
	const mediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
	const { user } = useSelector((state) => state.auth);
	const handleSubmit = async (e) => {
		let valid = true;
		setLoading(true);
		let newErrors = {
			currentPassword: "",
			password: "",
			confirmPassword: "",
		};
		if (olderPassword.length < 6) {
			valid = false;
			newErrors.currentPassword =
				"Le mot de passe doit comporter au moins 6 caractères.";
		}
		if (password.length < 6) {
			valid = false;
			newErrors.password =
				"Le mot de passe doit comporter au moins 6 caractères.";
		}
		if (password !== confirmPassword) {
			valid = false;
			newErrors.confirmPassword =
				"Les mots de passe ne correspondent pas.";
		}

		if (password === olderPassword) {
			valid = false;
			newErrors.password =
				"Le nouveau mot de passe doit être différent du dernier.";
		}

		if (valid) {
			e.preventDefault();
			try {
				await requestAxiosResetPasswordFromConfig(
					olderPassword,
					password
				);
				toast.success("Mot de passe mis à jour.", {
					toastId: "reset-password-success",
				});
			} catch (error) {
				let msg;
				if (error?.code === 409) {
					msg =
						"Le nouveau mot de passe doit être différent du dernier.";
				} else if (error?.code === 403) {
					msg = "Le mot de passe courant n'est pas correct.";
				} else {
					msg = "Erreur interne.";
				}
				toast.error(msg, {
					toastId: "reset-password-error",
				});
				setOlderPassword("");
				setPassword("");
				setConfirmPassword("");
			}
		} else {
			setOlderPassword("");
			setPassword("");
			setConfirmPassword("");
		}
		setErrors(newErrors);
		setLoading(false);
	};

	return (
		<Card
			sx={{
				maxWidth: mediumScreen ? "none" : "750px",
				width: {
					xs: "100%",
					sm: "100%",
					md: "100%",
				},
				height: {
					xs: "100%",
					sm: "100%",
					md: "auto",
				},
				pt: 2,
				borderRadius: "16px",
				minHeight: mediumScreen ? "100vh" : "100%",
				ml: "auto",
				mr: "auto",
				backgroundColor: theme.palette.secondary.main,
				overflowY: "scroll",
			}}
		>
			{mediumScreen && (
				<IconButton
					sx={{ top: 30, left: 10, position: "absolute" }}
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			)}
			<Box display={"flex"} justifyContent={"center"} mb={2} mt={2}>
				<Typography variant="h5" mr={2} mt={"auto"} mb={"auto"}>
					Changer le mot de passe
				</Typography>
				<LockResetOutlinedIcon
					sx={{
						mt: "auto",
						mb: "auto",
						width: "30px",
						height: "30px",
					}}
				/>
			</Box>
			<Divider sx={{ mb: 2 }} />
			<Box
				ml={"auto"}
				mr={"auto"}
				mt={2}
				sx={{
					maxWidth: {
						xs: "350px",
						sm: "400px",
						md: "600px",
						lg: "500px",
					},
				}}
			>
				<ResetPasswordForm
					activeVerif={true}
					olderPassword={olderPassword}
					password={password}
					confirmPassword={confirmPassword}
					setOlderPassword={setOlderPassword}
					setPassword={setPassword}
					setConfirmPassword={setConfirmPassword}
					handleSubmit={handleSubmit}
					loading={loading || loadingUpdate}
					errors={errors}
					setErrors={setErrors}
					disabled={user?.authProvider === "LINKEDIN"}
				/>
			</Box>
		</Card>
	);
};

export default ResetPasswordCard;
