import { Box, Typography } from "@mui/material";
import React from "react";

const Stats = ({count, label, handleClick}) => {
	return (
		<Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexWrap={'wrap'} flex={1} minHeight={'40px'} pl={1} sx={{cursor:'pointer'}} onClick={handleClick}>
            <Typography>{count}&nbsp;</Typography>
            <Typography>{label}</Typography>
        </Box>
	);
};

export default Stats;
