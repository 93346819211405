import React from "react";
import { Container, Typography, List, ListItem, Link } from "@mui/material";
import logo from '../../../assets/images/logo_classic_outlined.svg';

const Terms = () => {
    document.title = "Conditions d'utilisation | Goodmove";

    return (
        <Container maxWidth="md" style={{ paddingTop: '20px' }}>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src={logo} alt="Logo de Goodmove" style={{ width: '150px' }} />
            </div>
            <Typography variant="h1" gutterBottom>
                Conditions d'utilisation de GoodMove
            </Typography>

            <Typography variant="h2" gutterBottom>
                1. Introduction
            </Typography>
            <Typography paragraph>
                Bienvenue sur GoodMove ! En utilisant notre plateforme, vous acceptez les présentes conditions d'utilisation. Si vous n'êtes pas d'accord avec ces conditions, veuillez ne pas utiliser notre service.
            </Typography>

            <Typography variant="h2" gutterBottom>
                2. Acceptation des Conditions
            </Typography>
            <Typography paragraph>
                En accédant et en utilisant GoodMove, vous acceptez de respecter et de vous conformer à ces conditions d'utilisation. Nous nous réservons le droit de modifier ces conditions à tout moment, et votre utilisation continue de la plateforme constitue votre acceptation des conditions modifiées.
            </Typography>

            <Typography variant="h2" gutterBottom>
                3. Utilisation de la Plateforme
            </Typography>
            <Typography paragraph>
                Vous vous engagez à utiliser GoodMove uniquement à des fins légales et conformément aux lois en vigueur. Vous êtes responsable de tout contenu que vous publiez et vous vous engagez à ne pas :
            </Typography>
            <List>
                <ListItem>Publier du contenu illégal, offensant ou diffamatoire.</ListItem>
                <ListItem>Enfreindre les droits de propriété intellectuelle d'autrui.</ListItem>
                <ListItem>Utiliser la plateforme pour harceler, abuser ou menacer d'autres utilisateurs.</ListItem>
            </List>

            <Typography variant="h2" gutterBottom>
                4. Propriété Intellectuelle
            </Typography>
            <Typography paragraph>
                Tous les droits de propriété intellectuelle relatifs à GoodMove, y compris les droits d'auteur, les marques déposées et les brevets, appartiennent à GoodMove ou à ses concédants de licence. Vous vous engagez à ne pas copier, reproduire ou distribuer tout contenu de la plateforme sans notre autorisation préalable.
            </Typography>

            <Typography variant="h2" gutterBottom>
                5. Responsabilités et Limitations
            </Typography>
            <Typography paragraph>
                GoodMove ne pourra être tenu responsable des dommages directs ou indirects résultant de l'utilisation de la plateforme ou de l'incapacité à y accéder. Nous ne garantissons pas que la plateforme sera disponible en tout temps, ni qu'elle sera exempte d'erreurs ou de virus.
            </Typography>

            <Typography variant="h2" gutterBottom>
                6. Confidentialité
            </Typography>
            <Typography paragraph>
                Nous nous engageons à protéger vos informations personnelles comme décrit dans notre Politique de Confidentialité. En utilisant notre plateforme, vous acceptez la collecte et l'utilisation de vos données conformément à cette politique.
            </Typography>

            <Typography variant="h2" gutterBottom>
                7. Liens Externes
            </Typography>
            <Typography paragraph>
                Notre plateforme peut contenir des liens vers des sites Web tiers. Nous ne sommes pas responsables du contenu ou des pratiques de ces sites. L'inclusion de tout lien n'implique pas notre approbation des sites liés.
            </Typography>

            <Typography variant="h2" gutterBottom>
                8. Modification des Conditions
            </Typography>
            <Typography paragraph>
                Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Toute modification sera publiée sur notre site et prendra effet dès sa publication. Votre utilisation continue de notre plateforme après toute modification constitue votre acceptation des conditions modifiées.
            </Typography>

            <Typography variant="h2" gutterBottom>
                9. Résiliation
            </Typography>
            <Typography paragraph>
                Nous nous réservons le droit de résilier votre accès à GoodMove si nous estimons que vous avez violé ces conditions d'utilisation. En cas de résiliation, vous devez immédiatement cesser d'utiliser la plateforme et supprimer tout contenu que vous avez publié.
            </Typography>

            <Typography variant="h2" gutterBottom>
                10. Contact
            </Typography>
            <Typography paragraph>
                Pour toute question concernant ces conditions d'utilisation, veuillez nous contacter à <Link href="mailto:contact@goodmove.com">contact@goodmove.com</Link>.
            </Typography>

            <Typography variant="h2" gutterBottom>
                11. Mention Légale
            </Typography>
            <Typography paragraph>
                Veuillez noter que GoodMove est un projet étudiant fictif développé à des fins pédagogiques. Les informations et services décrits dans ce document sont simulés et ne doivent pas être considérés comme des offres réelles ou des engagements.
            </Typography>
        </Container>
    );
};

export default Terms;
