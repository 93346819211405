import { Box, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/themes/theme";
import { requestAxiosSuggestFollow } from "../../../services/axios/axiosRequests";
import { useDispatch } from "react-redux";
import { addUsersList } from "../../../store/slices/userSlice";
import SuggestCard from "../../molecules/Card/SuggestCard";
import { useNavigate } from "react-router-dom";
import ReplayIcon from "@mui/icons-material/Replay";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";

const Rightbar = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [loadingSuggest, setLoadingSuggest] = useState(false);
	const [suggestData, setSuggestData] = useState([]);
	const [suggestPage, setSuggestPage] = useState(1);

	useEffect(() => {
		fetchFollowSuggest();
	}, []);

	const handleLoadMoreComments = () => {
		fetchFollowSuggest();
	};

	const fetchFollowSuggest = async () => {
		if (!loadingSuggest) {
			setLoadingSuggest(true);
			const { items, pagination } = await requestAxiosSuggestFollow(
				suggestPage
			);

			if (suggestData.length > 1) {
				const existingSuggestIds = new Set(
					suggestData?.map((user) => user.id)
				);
				// on garde uniquement les nouveaux posts qui ne sont pas déjà présents dans la liste
				const newSuggest = items?.filter(
					(user) => !existingSuggestIds.has(user?.id)
				);
				dispatch(addUsersList({ users: newSuggest }));
				setSuggestData([...suggestData, ...newSuggest]);
			} else {
				if (items?.length > 0) {
					dispatch(addUsersList({ users: items }));
					setSuggestData(items);
				}
			}
			const endData = setSuggestData?.length === pagination?.total;
			const limitReached = items?.length === pagination?.limit;
			if (!endData && limitReached) {
				setSuggestPage(suggestPage + 1);
			}
			setLoadingSuggest(false);
		}
	};

	return (
		<Box
			color={theme.palette.secondary.main}
			flex={1.5}
			pl={1}
			pr={3}
			pt={2}
			height={"100%"}
			minHeight={"100vh"}
			sx={{
				display: {
					xs: "none",
					sm: "none",
					md: "none",
					lg: "block",
					xl: "block",
				},
			}}
			zIndex={2}
		>
			<Box display={"flex"} flexDirection={"column"}>
				<IconButton
					sx={{ ml: "auto", mb: 3.5, mr: 3 }}
					onClick={() => navigate("/settings")}
				>
					<SettingsIcon />
				</IconButton>
				<Box
					display={"flex"}
					justifyContent={"center"}
					flexDirection={"column"}
					bgcolor={theme.palette.secondary.light}
					borderRadius={1}
					p={3}
					maxHeight={"75vh"}
					sx={{ boxShadow: "1px 3px 6px rgba(85, 85, 85, 0.25)" }}
				>
					<Box
						display={"flex"}
						justifyContent={"space-between"}
						mb={2}
					>
						<Typography
							variant="h6"
							color={theme.palette.otherColor.main}
							mb={"auto"}
							ml={1}
							mt={"auto"}
						>
							Suggestions pour vous
						</Typography>
						<IconButton
							sx={{ mb: "auto", mt: "auto" }}
							onClick={() => navigate("/search?filter=users")}
						>
							<SearchIcon />
						</IconButton>
					</Box>
					<Box
						display={"flex"}
						flexDirection={"column"}
						sx={{
							overflowY: "auto",
							scrollbarWidth: "thin",
						}}
					>
						{suggestData?.length > 0 ? (
							suggestData?.map((user) => {
								return (
									<SuggestCard
										key={user?.id}
										userId={user?.id}
										handleClickProfil={() =>
											navigate("/profile/" + user?.id)
										}
									/>
								);
							})
						) : (
							<Typography
								textAlign={"center"}
								color={theme.palette.otherColor.main}
								variant="body2"
							>
								Aucune suggestion disponible
							</Typography>
						)}
						<IconButton
							onClick={handleLoadMoreComments}
							sx={{
								width: "40px",
								cursor: "pointer",
								mt: 2,
								mb: 2,
								ml: "auto",
								mr: "auto",
							}}
						>
							<ReplayIcon
								sx={{ color: theme.palette.otherColor.main }}
							/>
						</IconButton>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Rightbar;
