import { Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React from "react";
import { theme } from "../../../assets/themes/theme";

const CustomLoadingButton = ({ content, css, onClick, variant, loading, disabled }) => {
	const SXCustomOutlinedBtn = {
		boxShadow: "1px 3px 6px rgba(85, 85, 85, 0.25)",
		ml: "auto",
		mr: "auto",
		height: 39,
		width: "230px",
		backgroundColor: variant === 'outlined' ? theme.palette.secondary.main : theme.palette.primary.main,
		color: variant === 'outlined' ? theme.palette.primary.main : theme.palette.secondary.light,
		".MuiLoadingButton-root": {
			backgroundColor: variant === 'outlined' ? theme.palette.primary.dark : theme.palette.secondary.main,
		},
		"&:hover": {
			backgroundColor: variant === 'outlined' ? theme.palette.secondary.dark : theme.palette.primary.dark,
		},
		...css,
	};

	return (
		<LoadingButton
			variant={variant || "contained"}
			sx={SXCustomOutlinedBtn}
			onClick={onClick}
			loading={loading}
			disabled={disabled}
		>
			<Typography
				sx={{
					fontSize: 14,
					fontWeight: "600",
				}}
			>
				{content}
			</Typography>
		</LoadingButton>
	);
};

export default CustomLoadingButton;
