import React from "react";
import { Box, Card, CardMedia, Typography } from "@mui/material";
import trophySvg from "../../../assets/images/emoji_events.svg";
import lockpng from "../../../assets/images/circle.png";
import { timeElapsedDetails } from "../../../utils/tool";

const Trophy = ({ trophy }) => {
	const timeElapsedStr = timeElapsedDetails(trophy?.completedAt);

	return (
		<Card
			sx={{
				position: "relative",
				display: "flex",
				height: "10vh",
				minHeight: "120px",
				width: "100%",
				maxWidth: "600px",
				borderRadius: "10px",
			}}
		>
			{!trophy?.completedAt && (
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						backgroundColor: "rgba(0,0,0,0.29)",
						borderRadius: "inherit",
						zIndex: 1,
						pointerEvents: "none",
					}}
				/>
			)}
			<Box
				sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					borderRadius: "10px",
					borderRight: "1px solid #E9ECEE",
					width: "10vh",
					minWidth: "120px",
					zIndex: 2,
				}}
			>
				{trophy?.completedAt ? (
					<CardMedia
						sx={{ width: "50px", height: "50px" }}
						component="img"
						image={trophy?.completedAt ? trophySvg : lockpng}
						alt="Trophy"
					/>
				) : (
					<CardMedia
						sx={{ width: "100px", height: "100px" }}
						component="img"
						image={trophy?.completedAt ? trophySvg : lockpng}
						alt="Trophy"
					/>
				)}
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					flex: 3,
					padding: "10px",
					zIndex: 2,
				}}
			>
				<Box>
					<Typography sx={{ fontWeight: "600", mt: 1, mb: 1 }}>
						{trophy?.name}
					</Typography>
					<Typography sx={{ fontSize: "14px" }}>
						{trophy?.description}
					</Typography>
				</Box>
				<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
					{trophy?.completedAt && (
						<Typography sx={{ fontSize: "12px" }}>
							{timeElapsedStr}
						</Typography>
					)}
				</Box>
			</Box>
		</Card>
	);
};

export default Trophy;
