import React, { useState } from "react";
import Navbar from "../molecules/Navbar/Navbar";
import { Box, Stack } from "@mui/material";
import BottomNavbar from "../molecules/BottomNavbar/BottomNavbar";
import Rightbar from "../organisms/Rightbar/Rightbar";
import NotifSection from "../organisms/NotifSection/NotifSection";
import HeaderMobile from "../molecules/HeaderMobile/HeaderMobile";
import { useDispatch, useSelector } from "react-redux";
import { addPost, cleanErrorP } from "../../store/slices/postSlice";
import CreatePostSectionModale from "../organisms/CreatePostSection/CreatePostSectionModal";
import { toast } from "react-toastify";

const NotifsPage = ({ SXMarginSidebar }) => {
	document.title = "Notifications | Goodmove";
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();

	const user = useSelector((state) => state.auth.user);

	const { errorP } = useSelector((state) => state.posts);

	const handleAddPost = (newPost) => {
		open && setOpen(false);
		dispatch(addPost({ userId: user?.id, newPost }));
	};

	if (errorP) {
		toast.error(errorP?.message || "un problème est survenu", {
			toastId: "post-error",
		});
		dispatch(cleanErrorP());
	}

	return (
		<Box minHeight={"100vh"}>
			<Navbar handleOpenCreatePost={() => setOpen(true)} />
			<HeaderMobile />
			<Box
				sx={{
					ml: SXMarginSidebar(),
					pt: { xs: 12.5, sm: 0, md: 0, lg: 0, xl: 0 },
				}}
			>
				<Stack direction="row">
					<NotifSection />
					<Rightbar />
				</Stack>
			</Box>
			<CreatePostSectionModale
				open={open}
				handleClose={() => setOpen(false)}
				handleAddPost={handleAddPost}
			/>
			<BottomNavbar />
		</Box>
	);
};

export default NotifsPage;
