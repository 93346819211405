import { useEffect, useRef, useState } from "react";

export const useIntersectionObserver = (options) => {
	const [isIntersecting, setIsIntersecting] = useState(false);
	const targetRef = useRef(null);

	useEffect(() => {
		const ref = targetRef.current;
		if (!ref) return;

		const observer = new IntersectionObserver(([entry]) => {
			setIsIntersecting(entry.isIntersecting);
		}, options);

		observer.observe(ref);

		return () => {
			if (ref) {
				observer.unobserve(ref);
			}
		};
	}, [options]);

	return [targetRef, isIntersecting];
};
