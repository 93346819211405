import { Grid, Paper, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import greenRoad from "../../assets/images/greenRoad.jpg";
import SignInSection from "../organisms/SignInSection/SignInSection";
import WelcomeModal from "../molecules/Modal/WelcomeModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	cleanError,
	endOfRegister,
	loginUser,
	logout,
	validateAccount,
} from "../../store/slices/authSlice";
import { theme } from "../../assets/themes/theme";
import { toast } from "react-toastify";

const SignInPage = () => {
	document.title = "Se connecter | Goodmove";
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const [searchParams, setSearchParams] = useSearchParams();
	const [email, setEmail] = useState("");
	const [waitValidate, setWaitValidate] = useState(false);
	const [password, setPassword] = useState("");
	const [openWelcome, setOpenWelcome] = useState(false);
	const [errors, setErrors] = useState({
		email: "",
		password: "",
		global: "",
		linkedin: "",
	});

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		loading,
		error,
		errorValidate,
		provider,
		isAuthenticated,
		user,
		valideAccount,
	} = useSelector((state) => state.auth);

	const handleSubmit = (e) => {
		let valid = true;
		let newErrors = {
			email: "",
			password: "",
			global: "",
			linkedin: "",
		};
		if (!email.includes("@")) {
			valid = false;
			newErrors.email = "L'email doit être valide.";
		}
		if (password.length < 6) {
			valid = false;
			newErrors.password =
				"Le mot de passe doit comporter au moins 6 caractères.";
		}
		setErrors(newErrors);
		if (valid) {
			e.preventDefault();
			dispatch(loginUser({ username: email, password }));
		} else {
			setPassword("");
		}
	};

	const handleBack = () => {
		dispatch(logout());
		setOpenWelcome(false);
	};

	const handleSave = (username, birthday) => {
		dispatch(endOfRegister({ userId: user?.id, username, birthday }));
	};

	useEffect(() => {
		const token = searchParams.get("token");
		if (token && !waitValidate) {
			setWaitValidate(true);
			setSearchParams("");
			dispatch(validateAccount({ token }));
		}
	}, []);

	useEffect(() => {
		if (valideAccount) {
			toast.success("Votre compte vient d'être activé.", {
				toastId: "validate-account-success",
			});
		} else if (errorValidate) {
			toast.error(
				"Un problème est survenue lors de l'activation de votre compte.",
				{
					toastId: "validate-account-failed",
				}
			);
			dispatch(cleanError());
		}
	}, [valideAccount]);

	useEffect(() => {
		if (error && provider === "APP" && error?.code !== 409) {
			setPassword("");
			if (error?.code === 401 || error?.code === 404) {
				setErrors((prevErrors) => ({
					...prevErrors,
					global: "Email ou mot de passe incorrect",
					linkedin: "",
				}));
			} else {
				setErrors((prevErrors) => ({
					...prevErrors,
					global: "Erreur interne",
				}));
			}
		} else if (error && provider === "LINKEDIN") {
			setErrors((prevErrors) => ({
				...prevErrors,
				global: "",
				linkedin: "Erreur de connexion avec linkedin",
			}));
		}
	}, [error, provider]);

	useEffect(() => {
		if (isAuthenticated) {
			if (!user?.username) {
				setOpenWelcome(true);
			} else {
				navigate("/feed");
			}
		}
	}, [isAuthenticated, user?.username]);

	return (
		<>
			<Grid
				container
				component="main"
				p={isMobile ? 0 : 2}
				height={"100vh"}
				borderRadius={"7px"}
			>
				<Grid
					item
					xs={false}
					sm={5}
					md={7}
					component={Paper}
					elevation={isMobile ? 0 : 10}
					borderRadius={"7px 0px 0px 7px"}
					sx={{
						backgroundImage: "url(" + greenRoad + ")",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						backgroundPosition: "center",
					}}
				/>
				<SignInSection
					email={email}
					setEmail={setEmail}
					password={password}
					setPassword={setPassword}
					handleSubmit={handleSubmit}
					loading={loading}
					errors={errors}
					setErrors={setErrors}
				/>
			</Grid>
			<WelcomeModal
				open={openWelcome}
				handleBack={handleBack}
				handleSave={handleSave}
			/>
		</>
	);
};

export default SignInPage;
