import { useState, useEffect } from "react";
import { theme } from "../assets/themes/theme";
import { useMediaQuery } from "@mui/material";

const useDimensions = () => {
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const [dimensions, setDimensions] = useState({
		width: isMobile ? window.innerWidth : window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		const handleResize = () => {
			// calculation of our window width without navbar (100) and without scrollbar (22)
			// in mobile format, the navigation bar is below, so we don't need to worry about it
			setDimensions({
				width: isMobile
					? window.innerWidth - 22
					: window.innerWidth - 122,
				height: window.innerHeight,
			});
		};
		window.addEventListener("resize", handleResize);
		handleResize();

		return () => window.removeEventListener("resize", handleResize);
	}, [isMobile]);

	return [dimensions];
};

export default useDimensions;
