import { CardMedia, useMediaQuery } from "@mui/material";
import React from "react";
import { Keyboard, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { theme } from "../../../assets/themes/theme";

const CarouselViewer = ({ images, SXCardMedia }) => {
	const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
	return (
		<Swiper
			slidesPerView={1}
			spaceBetween={30}
			keyboard={{
				enabled: true,
			}}
			pagination={{
				clickable: true,
			}}
			navigation={!mobileScreen}
			modules={[Keyboard, Pagination, Navigation]}
			style={{
				"--swiper-navigation-color": theme.palette.secondary.main,
				"--swiper-pagination-color": theme.palette.secondary.main,
				zIndex: 1,
			}}
		>
			{images.map((image, index) => (
				<SwiperSlide key={`carousel-item-${index}`}>
					<CardMedia
						component="img"
						image={
							image?.picture_url
						}
						alt={`view-${image.id}`}
						sx={SXCardMedia}
					/>
				</SwiperSlide>
			))}
		</Swiper>
	);
};

export default CarouselViewer;
