import React, { useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Slider,
	Box,
	useMediaQuery,
} from "@mui/material";
import CustomOutlinedBtn from "../../atoms/Buttons/CustomOutlinedBtn";
import CustomButton from "../../atoms/Buttons/CustomButton";
import { theme } from "../../../assets/themes/theme";

const ImageCropper = ({ imageSrc, onCropComplete, onCancel, onClose, aspect }) => {
	const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [disabled, setDisabled] = useState(false);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

	const handleCropComplete = (croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	};

	const handleCancel = () => {
		if (!disabled) {
			setDisabled(true);
			onCancel();
		}
	};

	const handleSave = () => {
		if (!disabled) {
			setDisabled(true);
			onCropComplete(croppedAreaPixels);
		}
	};

	useEffect(() => {
		setZoom(1);
		setCrop({ x: 0, y: 0 });
		setDisabled(false);
	}, [imageSrc]);

	return (
		<Dialog open={Boolean(imageSrc)} onClose={onClose} fullWidth>
			<DialogTitle>Recadrez votre image</DialogTitle>
			<DialogContent sx={{ pb: 1 }}>
				<Box
					sx={{
						position: "relative",
						width: "100%",
						height: 310,
						background: "#333",
					}}
				>
					<Cropper
						image={imageSrc}
						crop={crop}
						zoom={zoom}
						aspect={aspect}
						onCropChange={setCrop}
						onZoomChange={setZoom}
						onCropComplete={handleCropComplete}
					/>
				</Box>
				{!mobileScreen && (
					<Slider
						value={zoom}
						min={1}
						max={3}
						step={0.1}
						onChange={(e, zoom) => setZoom(zoom)}
						style={{ marginTop: 16, marginBottom: 16 }}
					/>
				)}
			</DialogContent>
			<DialogActions sx={{ flexWrap: "wrap", justifyContent: "center" }}>
				<CustomOutlinedBtn
					content={"Annuler"}
					onClick={handleCancel}
					disabled={disabled}
					css={{ mb: 2, ml: 0, mr: 0 }}
				/>
				<CustomButton
					content={"Enregistrer"}
					onClick={handleSave}
					disabled={disabled}
					css={{ ml:0, mr:1, mb: 2 }}
				/>
			</DialogActions>
		</Dialog>
	);
};

export default ImageCropper;
