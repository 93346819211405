import { Box, Typography } from "@mui/material";
import React from "react";
import { formatText } from "../../../utils/tool";

const Bio = ({firstName, lastName, username, bio}) => {
	return (
		<Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'10px'} pt={'10px'} pb={"20px"}>
            <Typography fontWeight='bold'>{firstName} {lastName}</Typography>
            <Typography color='grey'>@{username}</Typography>
            {bio && <Typography>{formatText(bio)}</Typography>}
        </Box>
	);
};

export default Bio;
