import React, { createContext, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
	const history = useRef([]);
	const location = useLocation();

	useEffect(() => {
		history.current.push(location.pathname);
	}, [location]);

	return (
		<NavigationContext.Provider value={history}>
			{children}
		</NavigationContext.Provider>
	);
};

export const useNavigationHistory = () => {
	return useContext(NavigationContext);
};
