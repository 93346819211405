import {
	Box,
	Button,
	Divider,
	IconButton,
	List,
	Modal,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReportCard from "../Card/ReportCard";
import { requestAxiosCreateReports, requestAxiosGetReportsTypes } from "../../../services/axios/axiosRequests";
import { theme } from "../../../assets/themes/theme";
import CloseIcon from "@mui/icons-material/Close";

const ReportModal = ({ open, postId, handleClose }) => {
	const [selectedTypes, setSelectedTypes] = useState([]);
	const [types, setTypes] = useState([]);
	const [openDesc, setOpenDesc] = useState(false);
	const [desc, setDesc] = useState("");

	const handleToggle = (type) => {
		setSelectedTypes((prev) =>
			prev.some((t) => t?.key === type?.key)
				? prev.filter((t) => t?.key !== type?.key)
				: [...prev, type]
		);
	};

	const canSubmit = selectedTypes.length > 0;

	const handleSubmitReport = async () => {
		try {
			await requestAxiosCreateReports(postId,desc,selectedTypes.map(type => type.key));
			toast.success("Merci de nous avoir signalé ce post !");
		} catch (error) {
			toast.error("Une erreur s'est produite lors du signalement");
		}
		handleCloseReport();
	};

	const handleCloseReport = () => {
		setSelectedTypes([]);
		setDesc("");
		setOpenDesc(false);
		handleClose();
	};

	const isActive = (key) => {
		return selectedTypes.some((type) => type?.key === key);
	};

	const handleFetchReportsTypes = async () => {
		try {
			const res = await requestAxiosGetReportsTypes();
			const resFormatted = Object.entries(res).map(([key, label]) => ({
				key,
				label,
			}));
			setTypes(resFormatted);
		} catch (error) {}
	};

	useEffect(() => {
		if (open && types?.length === 0) {
			handleFetchReportsTypes();
		}
	}, [open]);

	return (
		<Modal
			open={open}
			onClose={handleCloseReport}
			aria-labelledby="report-modal-title"
			aria-describedby="report-modal-description"
			sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<Paper
				sx={{
					maxWidth: {xs: '100%', sm:600, md: 600},
					width: "100%",
					overflowY: "scroll",
					position: "relative",
					height: {xs: '100%', sm:'auto', md: 'auto'}
				}}
			>
				<Typography
					id="report-modal-title"
					variant="h5"
					textAlign={"center"}
					mt={2}
				>
					Signaler
				</Typography>
				<IconButton
					aria-label="close"
					onClick={handleCloseReport}
					sx={{ position: "absolute", top: 10, left: 20 }}
				>
					<CloseIcon />
				</IconButton>
				<Divider sx={{ mt: 1, mb: 1 }} />
				{openDesc ? (
					<Box
						display={"flex"}
						justifyContent={"center"}
						flexDirection={"column"}
					>
						<Typography
							id="report-modal-description"
							variant="h6"
							sx={{ mt: 2, ml: 3 }}
						>
							Souhaitez vous décrire les raisons de ce signalement
							?
						</Typography>
						<TextField
							fullWidth
							label="Optionnel"
							variant="outlined"
							multiline
							rows={6}
							value={desc}
							onChange={(e) => setDesc(e?.target?.value)}
							sx={{
								mt: 2,
								ml: "auto",
								mr: "auto",
								pl: 0,
								pr: 0,
								maxWidth: 500,
							}}
						/>
						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								m: 2,
							}}
						>
							<Button
								onClick={() => {
									setOpenDesc(false);
								}}
								color="primary"
							>
								Retour
							</Button>
							<Button
								onClick={handleSubmitReport}
								color="otherColor"
								variant="contained"
								disabled={!canSubmit}
								sx={{
									ml: 2,
									color: theme.palette.secondary.main,
									"&:hover": {
										backgroundColor:
											theme.palette.otherColor.light,
									},
								}}
							>
								Envoyer
							</Button>
						</Box>
					</Box>
				) : (
					<>
						<Typography
							id="report-modal-description"
							variant="h6"
							sx={{ mt: 2, ml: 3 }}
						>
							Pourquoi signalez vous ce post ?
						</Typography>
						<List sx={{ m: 2 }}>
							{types?.length > 0 &&
								types?.map((item) => {
									const active = isActive(item?.key);
									return (
										<ReportCard
											key={item?.key}
											active={active}
											title={item?.label}
											onClick={() => handleToggle(item)}
										/>
									);
								})}
						</List>

						<Box
							sx={{
								display: "flex",
								justifyContent: "flex-end",
								m: 2,
							}}
						>
							<Button onClick={handleCloseReport} color="primary">
								Annuler
							</Button>
							<Button
								onClick={() => {
									setOpenDesc(true);
								}}
								color="otherColor"
								variant="contained"
								disabled={!canSubmit}
								sx={{
									ml: 2,
									color: theme.palette.secondary.main,
									"&:hover": {
										backgroundColor:
											theme.palette.otherColor.light,
									},
								}}
							>
								Suivant
							</Button>
						</Box>
					</>
				)}
			</Paper>
		</Modal>
	);
};

export default ReportModal;
