import {
	Divider,
	List,
	ListItemButton,
	ListItemText,
	Modal,
	Paper,
	useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../../assets/themes/theme";
import ConfirmModal from "./ConfirmModal";

const OptionsModal = ({
	open,
	handleClose,
	handleDelete,
	handleReport,
	allAccess,
}) => {
	const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const paperWidth = mobileScreen ? 280 : 550;
	const [openConfirm, setOpenConfirm] = useState(false);

	const handleOnDelete = () => {
		setOpenConfirm(false);
		handleDelete();
	};

	const handleOnClose = () => {
		setOpenConfirm(false);
		handleClose();
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-created-post"
			aria-describedby="modal-created-post"
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<Paper
				elevation={2}
				sx={{
					width: paperWidth,
					color: theme.palette.secondary.dark,
					ml: "auto",
					mr: "auto",
				}}
			>
				<List
					p={2}
					sx={{
						borderRadius: 2,
						border: "2px solid",
						borderColor: theme.palette.secondary.dark,
						bgcolor: theme.palette.secondary.light,
					}}
				>
					{allAccess && (
						<>
							<ListItemButton
								onClick={() => setOpenConfirm(true)}
							>
								<ListItemText
									primary="Supprimer"
									sx={{
										color: theme.palette.error.main,
										textAlign: "center",
									}}
								/>
							</ListItemButton>
							<Divider />
						</>
					)}
					<ListItemButton onClick={handleReport}>
						<ListItemText
							primary="Signaler"
							sx={{
								color: theme.palette.error.main,
								textAlign: "center",
							}}
						/>
					</ListItemButton>
					<Divider />
					<ListItemButton onClick={handleClose}>
						<ListItemText
							primary="Annuler"
							sx={{
								color: theme.palette.otherColor.main,
								textAlign: "center",
							}}
						/>
					</ListItemButton>
				</List>
				<ConfirmModal
					open={openConfirm}
					title={"Attention : cette action est irréversible !"}
					description={"Voulez vous supprimer ce post ?"}
					handleConfirmClick={handleOnDelete}
					handleClose={handleOnClose}
				/>
			</Paper>
		</Modal>
	);
};

export default OptionsModal;
