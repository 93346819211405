import { Box, CardMedia, Grid, Typography } from "@mui/material";
import UserCard from "../../molecules/Card/UserCard";
import TagCard from "../../molecules/Card/TagCard";
import NoResultImg from "../../../assets/images/no-results.png";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/themes/theme";
import loadingGif from "../../../assets/videos/soon.gif";

const SearchResults = ({ data, filter, loadingData }) => {
	const [hasData, setHasData] = useState(false);

	useEffect(() => {
		setHasData(
			((filter === "users" || filter === "all") &&
				data?.users?.length > 0) ||
				((filter === "tags" || filter === "all") &&
					data?.tags?.length > 0)
		);
	}, [data, filter]);

	return (
		<>
			{loadingData ? (
				<Box
					display={"flex"}
					justifyContent={"center"}
					flexDirection={"column"}
				>
					<CardMedia
						component="img"
						image={loadingGif}
						alt="searching-data"
						sx={{
							width: "80px",
							height: "80px",
							ml: "auto",
							mr: "auto",
							mt: 8,
						}}
					/>
					<Typography
						textAlign={"center"}
						mt={3}
						fontWeight={500}
						color={theme.palette.otherColor.main}
					>
						Recherche en cours ...
					</Typography>
				</Box>
			) : hasData ? (
				<Grid container spacing={2} justifyContent={"space-evenly"}>
					{(filter === "users" || filter === "all") &&
						data?.users?.map((item) => (
							<Grid
								item
								key={'user-'+item?.id}
								xs={12}
								sm={12}
								md={6}
								xl={4}
							>
								<UserCard key={item?.id} user={item} />
							</Grid>
						))}

					{(filter === "tags" || filter === "all") &&
						data?.tags?.map((item) => (
							<Grid
								item
								key={'tag-'+item?.id}
								xs={12}
								sm={12}
								md={6}
								xl={4}
							>
								<TagCard key={item?.id} tag={item} />
							</Grid>
						))}
				</Grid>
			) : (
				<Box
					display={"flex"}
					justifyContent={"center"}
					flexDirection={"column"}
				>
					<CardMedia
						component="img"
						image={NoResultImg}
						alt="no-results"
						sx={{
							width: "80px",
							height: "80px",
							ml: "auto",
							mr: "auto",
							mt: 8,
						}}
					/>
					<Typography
						textAlign={"center"}
						mt={3}
						fontWeight={500}
						color={theme.palette.otherColor.main}
					>
						Aucun résultat ne correspond à votre recherche
					</Typography>
				</Box>
			)}
		</>
	);
};

export default SearchResults;
