import { Box, Typography } from "@mui/material";
import React from "react";
import InputPassword from "../../atoms/Inputs/InputPassword";
import InputCustom from "../../atoms/Inputs/InputCustom";
import CustomLoadingButton from "../../atoms/Buttons/CustomLoadingButton";
import { theme } from "../../../assets/themes/theme";
import { handleKeyDown } from "../../../utils/tool";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cleanError } from "../../../store/slices/authSlice";

const SignInForm = ({
	email,
	password,
	setEmail,
	setPassword,
	handleSubmit,
	loading,
	errors,
	setErrors,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	return (
		<Box
			component="form"
			autoComplete="on"
			sx={{ display: "flex", flexDirection: "column" }}
			onKeyDown={(e) => handleKeyDown(e, handleSubmit)}
			onSubmit={handleSubmit}
		>
			<InputCustom
				value={email}
				label={"Email"}
				errors={errors}
				setErrors={setErrors}
				entityName={"email"}
				setValue={setEmail}
			/>

			<InputPassword
				password={password}
				setPassword={setPassword}
				errors={errors}
				setErrors={setErrors}
				label={"Mot de passe"}
				entityName={"password"}
			/>

			<Box mb={2} mt={1} ml={"auto"}>
				<Typography
					onClick={() => {
						dispatch(cleanError());
						navigate("/forgot-password");
					}}
					color={theme.palette.primary.main}
					sx={{ cursor: "pointer", textDecoration: "underline" }}
					fontSize={13}
				>
					Mot de passe oublié ?
				</Typography>
			</Box>

			<CustomLoadingButton
				content={"Se connecter"}
				css={""}
				onClick={handleSubmit}
				loading={loading}
			/>

			{errors?.global && (
				<Typography
					color="error"
					fontSize={14}
					textAlign={"center"}
					sx={{ mt: 2 }}
				>
					{errors.global}
				</Typography>
			)}
		</Box>
	);
};

export default SignInForm;
