import {
	Box,
	Card,
	Divider,
	Icon,
	IconButton,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../../assets/themes/theme";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import CustomButton from "../../atoms/Buttons/CustomButton";
import ConfirmModal from "../Modal/ConfirmModal";
import CloseIcon from "@mui/icons-material/Close";

const DeleteAccountCard = ({ handleClose, loading }) => {
	const [openConfirm, setOpenConfirm] = useState(false);
	const mediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
	return (
		<>
			<Card
				sx={{
					maxWidth: mediumScreen ? "none" : "750px",
					width: {
						xs: "100%",
						sm: "100%",
						md: "100%",
					},
					height: {
						xs: "100%",
						sm: "100%",
						md: "auto",
					},
					pt: 2,
					pb: 2,
					borderRadius: "16px",
					minHeight: mediumScreen ? "100vh" : "100%",
					ml: "auto",
					mr: "auto",
					backgroundColor: theme.palette.secondary.main,
					overflowY: "scroll",
				}}
			>
				{mediumScreen && (
					<IconButton
						sx={{ top: 30, left: 10, position: "absolute" }}
						onClick={handleClose}
					>
						<CloseIcon />
					</IconButton>
				)}
				<Box display={"flex"} justifyContent={"center"} mb={2} mt={2}>
					<Typography variant="h5" mr={2} mt={"auto"} mb={"auto"}>
						Supprimer son compte
					</Typography>
					<Icon
						component={RemoveCircleOutlineOutlinedIcon}
						sx={{
							mt: "auto",
							mb: "auto",
							width: "30px",
							height: "30px",
						}}
					/>
				</Box>
				<Divider sx={{ mb: 2 }} />
				<Box m={4} display={"flex"} flexDirection={"column"}>
					<Typography fontSize={"20px"} mb={2}>
						Vous souhaitez nous quitter ?
					</Typography>
					<Typography fontSize={"16px"}>
						Êtes-vous sûr de vouloir supprimer définitivement votre
						compte ? Cette action est irréversible et entraînera la
						suppression complète de toutes vos données sur notre
						réseau social. <br />
						<br />
						En cas d'erreur, vous disposez de <b>30 jours </b>
						pour demander la récupération de votre compte. Passé ce
						délai, toutes vos informations seront définitivement
						perdues et ne pourront plus être récupérées. <br />
						<br />
						Si vous souhaitez procéder à la suppression de votre
						compte, veuillez confirmer votre choix ci-dessous.
					</Typography>
					<CustomButton
						content={"Supprimer définitivement son compte"}
						css={{
							mt: 3,
							width: "100%",
							height: "100%",
							color: theme.palette.secondary.main,
							bgcolor: theme.palette.error.main,
							"&:hover": {
								backgroundColor: theme.palette.error.dark,
							},
						}}
						onClick={() => setOpenConfirm(true)}
						// disabled={loading}
						disabled={true}
					/>
					<Typography
						mb={5}
						mt={3}
						sx={{ textAlign: "center" }}
						color={theme.palette.error.main}
					>
						Fonctionnalité bientôt disponible sur GoodMove
					</Typography>
				</Box>
			</Card>
			<ConfirmModal
				open={openConfirm}
				title={" !! Suppresion de compte !!"}
				description={
					"Attention : Vous êtes sur le point de supprimer votre compte. Êtes vous sûr de vouloir poursuivre ?"
				}
				handleClose={() => setOpenConfirm(false)}
				confirmTitle={"Tout supprimer"}
			/>
		</>
	);
};

export default DeleteAccountCard;
