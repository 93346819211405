import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	Modal,
	TextField,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/themes/theme";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import CustomButton from "../../atoms/Buttons/CustomButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import banner from "../../../assets/images/banner.png";
import ImageCropper from "../ImageCropper/ImageCropper";

const EditProfileModal = ({
	open,
	handleClose,
	handleSave,
	errors,
	setErrors,
}) => {
	const mediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
	const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const auth = useSelector((state) => state?.auth);
	const [croppingImage, setCroppingImage] = useState(null);
	const [profilePicture, setProfilePicture] = useState(null);
	const [cropContext, setCropContext] = useState("");
	const [bannerImage, setBannerImage] = useState(null);
	const [lastname, setLastname] = useState("");
	const [firstname, setFirstname] = useState("");
	const [birthday, setBirthday] = useState(null);
	const [bio, setBio] = useState("");
	dayjs.extend(localizedFormat);

	const handleImageChange = (event, type) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				setCropContext(type);
				setCroppingImage(reader.result);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleCropComplete = (croppedAreaPixels) => {
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");
		const image = new Image();
		image.src = croppingImage;
		image.onload = () => {
			canvas.width = croppedAreaPixels.width;
			canvas.height = croppedAreaPixels.height;
			ctx.drawImage(
				image,
				croppedAreaPixels.x,
				croppedAreaPixels.y,
				croppedAreaPixels.width,
				croppedAreaPixels.height,
				0,
				0,
				croppedAreaPixels.width,
				croppedAreaPixels.height
			);
			canvas.toBlob((blob) => {
				const nameDate = Math.round(Date.now() / 1000);
				const url = URL.createObjectURL(blob);
				const file = new File([blob], nameDate + ".jpg", {
					type: "image/jpeg",
				});
				const newImg = { file, url };
				if (cropContext === "banner") {
					setBannerImage(newImg);
				} else {
					setProfilePicture(newImg);
				}
				setCroppingImage(null);
			}, "image/jpeg");
		};
	};

	useEffect(() => {
		if (open) {
			auth.user?.backgroundPicture &&
				setBannerImage(auth.user?.backgroundPicture);
			auth.user?.profilePicture &&
				setProfilePicture(auth.user?.profilePicture);
			auth.user?.firstName && setFirstname(auth.user?.firstName);
			auth.user?.lastName && setLastname(auth.user?.lastName);
			setBirthday(dayjs(auth.user?.birthday));
			auth.user?.bio && setBio(auth.user?.bio);
		}
	}, [open]);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-edit-profile"
			aria-describedby="modal-edit-profile"
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<Box mb={8} mr={4} width={"100%"} height={"100%"}>
				<Card
					sx={{
						width: {
							xs: "100%",
							sm: "100%",
							md: "100%",
						},
						height: {
							xs: "100%",
							sm: "100%",
							md: "auto",
						},
						maxWidth: mediumScreen ? "none" : "750px",
						pt: 2,
						pb: 2,
						borderRadius: "16px",
						minHeight: mediumScreen ? "100%" : "100%",
						ml: "auto",
						mr: "auto",
						backgroundColor: theme.palette.secondary.main,
						position: "relative",
						overflowY: "scroll",
					}}
				>
					<CardHeader
						avatar={
							<>
								<IconButton
									aria-label="close"
									onClick={handleClose}
								>
									<CloseIcon />
								</IconButton>
							</>
						}
						title={"Modifier le profil"}
						sx={{
							pb: 1,
							".MuiCardHeader-title": {
								textAlign: "center",
								fontSize: "16px",
								fontWeight: "500",
								pr: 5,
							},
						}}
					/>
					<CardContent
						sx={{
							ml: 2,
							mr: 2,
							display: "flex",
							flexDirection: "column",
							flexWrap: "wrap",
						}}
					>
						<Box sx={{ position: "relative", mb: 1, mr: 1 }}>
							<Box
								component="img"
								src={bannerImage?.url || bannerImage || banner}
								alt="Bannière"
								sx={{
									width: "100%",
									height: "auto",
									objectFit: "cover",
									borderRadius: "8px",
									opacity: 0.8,
								}}
							/>
							<IconButton
								component="label"
								sx={{
									position: "absolute",
									width: "100%",
									height: "100%",
									borderRadius: "8px",
									top: "50%",
									right: "50%",
									transform: "translate(50%, -50%)",
									"&:hover": {
										backgroundColor:
											theme.palette.secondary.light,
										opacity: "0.5",
									},
								}}
							>
								<input
									type="file"
									hidden
									accept="image/*"
									onChange={(e) =>
										handleImageChange(e, "banner")
									}
								/>
								<AddCircleOutlineIcon
									sx={{
										color: theme.palette.secondary.light,
									}}
								/>
							</IconButton>
						</Box>
						<Box
							display={"flex"}
							mb={2}
							justifyContent={"space-evenly"}
							alignItems={"center"}
							flexWrap={mobileScreen ? "wrap" : "none"}
							sx={{ mt: { xs: 1, sm: 1, md: 3 }, mr: 1 }}
						>
							<Box
								sx={{
									position: "relative",
									width: "120px",
									height: "120px",
								}}
							>
								<Avatar
									src={profilePicture?.url || profilePicture}
									alt="Profil"
									sx={{
										width: "120px",
										height: "120px",
										borderRadius: "8px",
										mt: "auto",
										mb: "auto",
										opacity: 0.8,
									}}
								/>
								<IconButton
									component="label"
									sx={{
										position: "absolute",
										width: "100%",
										height: "100%",
										borderRadius: "8px",
										top: "50%",
										right: "50%",
										transform: "translate(50%, -50%)",
										"&:hover": {
											backgroundColor:
												theme.palette.secondary.light,
											opacity: "0.5",
										},
									}}
								>
									<input
										type="file"
										hidden
										accept="image/*"
										onChange={(e) =>
											handleImageChange(e, "profile")
										}
									/>
									<AddCircleOutlineIcon
										sx={{
											color: theme.palette.secondary
												.light,
										}}
									/>
								</IconButton>
							</Box>
							<Box
								display={"block"}
								sx={{
									ml: mobileScreen ? 0 : 2,
									mt: mobileScreen ? 2 : 1,
									mb: 1,
									mr: mobileScreen ? 0 : 1,
									width: "100%",
								}}
							>
								<TextField
									label="Nom"
									variant="outlined"
									fullWidth
									value={lastname}
									onChange={(e) => {
										errors?.lastName &&
											setErrors({
												...errors,
												lastName: "",
											});
										setLastname(e.target.value);
									}}
									error={errors?.lastName || false}
									helperText={
										errors?.lastName && errors?.lastName
									}
									sx={{
										ml: { xs: 0, sm: 0, md: 1 },
										mb: { xs: 2, sm: 2, md: 1 },
									}}
								/>
								<TextField
									label="Prénom"
									variant="outlined"
									fullWidth
									value={firstname}
									onChange={(e) => {
										errors?.firstName &&
											setErrors({
												...errors,
												firstName: "",
											});
										setFirstname(e.target.value);
									}}
									error={errors?.firstName || false}
									helperText={
										errors?.firstName && errors?.firstName
									}
									sx={{
										ml: { xs: 0, sm: 0, md: 1 },
									}}
								/>
							</Box>
						</Box>
						<Box
							display={"flex"}
							flexDirection={"column"}
							mb={2}
							mr={1}
						>
							<MobileDatePicker
								label={"Date de naissance"}
								sx={{ width: "100%" }}
								value={birthday}
								onChange={(e) => {
									errors?.birthday &&
										setErrors({
											...errors,
											birthday: "",
										});
									setBirthday(e);
								}}
							/>
							{errors?.birthday && (
								<Typography
									color={theme.palette.error.main}
									fontSize={12}
									mt={0.5}
									ml={"auto"}
									mr={"auto"}
								>
									{errors?.birthday}
								</Typography>
							)}
						</Box>
						<TextField
							label="Bio"
							variant="outlined"
							fullWidth
							multiline
							rows={3}
							value={bio}
							onChange={(e) => {
								errors?.bio &&
									setErrors({
										...errors,
										bio: "",
									});
								setBio(e.target.value);
							}}
							error={errors?.bio || false}
							helperText={errors?.bio && errors?.bio}
							sx={{ mb: 2, mr: 1 }}
						/>

						<CustomButton
							content={"Sauvegarder"}
							css={{ width: "100%", mb: 20 }}
							onClick={() =>
								handleSave(
									profilePicture,
									bannerImage,
									lastname,
									firstname,
									birthday,
									bio
								)
							}
						/>
					</CardContent>
				</Card>
				{croppingImage && (
					<ImageCropper
						imageSrc={croppingImage}
						onCropComplete={handleCropComplete}
						onCancel={() => {
							setCroppingImage(null);
						}}
						onClose={() => {
							setCroppingImage(null);
						}}
						aspect={cropContext === "banner" ? 4 / 1 : 1 / 1}
					/>
				)}
			</Box>
		</Modal>
	);
};

export default EditProfileModal;
