import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "../components/pages/HomePage";
import SignInPage from "../components/pages/SignInPage";
import SignUpPage from "../components/pages/SignUpPage";
import ForgotPasswordPage from "../components/pages/ForgotPasswordPage";
import FeedPage from "../components/pages/FeedPage";
import SearchPage from "../components/pages/SearchPage";
import NotifsPage from "../components/pages/NotifsPage";
import ProfilPage from "../components/pages/ProfilPage";
import OrgaPage from "../components/pages/OrgaPage";
import EventPage from "../components/pages/EventPage";
import SettingsPage from "../components/pages/SettingsPage";
import NotFoundPage from "../components/pages/NotFoundPage";
import LinkedinCallbackPage from "../components/pages/LinkedinCallbackPage";
import ProtectedRoute from "./ProtectedRoute";
import { NavigationProvider } from "../contexts/NavigationContext";
import SharedPage from "../components/pages/SharedPage";
import ResetPasswordPage from "../components/pages/ResetPasswordPage";
import NotificationsFetcher from "../services/fetcher/NotificationsFetcher";
import Terms from "../components/pages/Static/Terms";
import Privacy from "../components/pages/Static/Privacy";
import Cookies from "../components/pages/Static/Cookies";

const AppRoutes = ({ SXMarginSidebar }) => {
	return (
		<Router>
			<NavigationProvider>
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/signin" element={<SignInPage />} />
					<Route path="/signup" element={<SignUpPage />} />
					<Route
						path="/forgot-password"
						element={<ForgotPasswordPage />}
					/>
					<Route path="/shared/:uuid" element={<SharedPage />} />
					<Route
						path="/reset-password/:token"
						element={<ResetPasswordPage />}
					/>
					<Route
						path="/feed"
						element={
							<ProtectedRoute
								element={
									<FeedPage
										SXMarginSidebar={SXMarginSidebar}
									/>
								}
							/>
						}
					/>
					<Route
						path="/search"
						element={
							<ProtectedRoute
								element={
									<SearchPage
										SXMarginSidebar={SXMarginSidebar}
									/>
								}
							/>
						}
					/>
					<Route
						path="/notifications"
						element={
							<ProtectedRoute
								element={
									<NotifsPage
										SXMarginSidebar={SXMarginSidebar}
									/>
								}
							/>
						}
					/>
					<Route
						path="/profile/:id?"
						element={
							<ProtectedRoute
								element={
									<ProfilPage
										SXMarginSidebar={SXMarginSidebar}
									/>
								}
							/>
						}
					/>
					<Route
						path="/organisation"
						element={<ProtectedRoute element={<OrgaPage />} />}
					/>
					<Route
						path="/event"
						element={<ProtectedRoute element={<EventPage />} />}
					/>
					<Route
						path="/settings"
						element={<ProtectedRoute element={<SettingsPage />} />}
					/>
					<Route
						path="/linkedin-callback"
						element={<LinkedinCallbackPage />}
					/>
					<Route path="/terms" element={<Terms />} />
					<Route path="/privacy" element={<Privacy />} />
					<Route path="/cookies" element={<Cookies />} />
					<Route path="*" element={<NotFoundPage />} />
				</Routes>
				<NotificationsFetcher />
			</NavigationProvider>
		</Router>
	);
};

export default AppRoutes;
