import {
	Avatar,
	Box,
	Button,
	IconButton,
	TextField,
	Tooltip,
	Typography,
	useMediaQuery,
} from "@mui/material";
import TagIcon from "@mui/icons-material/Tag";
import ImageIcon from "@mui/icons-material/Image";
import React, { useEffect, useRef, useState } from "react";
import CarouselPostImage from "../../molecules/Carousel/CarouselPostImage";
import ImageControl from "../../atoms/ImageControl/ImageControl";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../../molecules/Modal/ConfirmModal";
import ImageCropper from "../../molecules/ImageCropper/ImageCropper";
import InputAutoComplete from "../../atoms/Inputs/InputAutoComplete";
import { theme } from "../../../assets/themes/theme";
import { useSelector } from "react-redux";
import { requestAxiosGetTags } from "../../../services/axios/axiosRequests";

const CreatePostSection = ({ handleAddPost }) => {
	const maxScreen = useMediaQuery(theme.breakpoints.up("lg"));
	const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
	const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [isExpanded, setIsExpanded] = useState(false);
	const [componentFocus, setComponentFocus] = useState();
	const [isTagsFocus, setIsTagsFocus] = useState(false);
	const [open, setOpen] = useState(false);
	const [draft, setDraft] = useState(false);
	const [input, setInput] = useState("");
	const [images, setImages] = useState([]);
	const [tagsData, setTagsData] = useState([]);
	const [croppingImagesQueue, setCroppingImagesQueue] = useState([]);
	const [croppingImage, setCroppingImage] = useState(null);
	const [slidesPerView, setSlidesPerView] = useState(0);
	const [hiddenTagSection, setHiddenTagSection] = useState(true);
	const [selectedTags, setSelectedTags] = useState([]);
	const postSectionRef = useRef(null);
	const inputTextRef = useRef(null);
	const inputImgRef = useRef(null);

	const { user } = useSelector((state) => state.auth);

	const SXBoxInputPost = {
		display: mobileScreen ? "none" : "flex",
		flexDirection: "column",
		justifyContent: "center",
		ml: "auto",
		mr: "auto",
		mb: 2,
		borderRadius: "7px",
		boxShadow: "1px 3px 6px rgba(85, 85, 85, 0.25)",
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.otherColor.main,
	};
	// Si on supprime le brouillon
	const handleRemovePostClick = () => {
		setIsExpanded(false);
		setInput("");
		setImages([]);
		setSelectedTags([]);
		setIsExpanded(false);
		setOpen(false);
		setHiddenTagSection(true);
		setComponentFocus();
	};
	// Si on publie le post
	const handlePostSubmit = () => {
		if (input || images.length) {
			const newPost = {
				content: input,
				tags: selectedTags,
				imgs: [],
			};
			images.map((image, index) => {
				return newPost.imgs.push({
					id: "image-" + index + 1,
					src: image,
				});
			});
			handleAddPost(newPost);
			setInput("");
			setImages([]);
			setSelectedTags([]);
			setIsExpanded(false);
			setHiddenTagSection(true);
			setComponentFocus();
		}
	};

	const handleImageChange = (event) => {
		const files = event.target.files;
		const newImages = Array.from(files).map((file) =>
			URL.createObjectURL(file)
		);
		// On met à jour la liste des images en file d'attente avant retouche
		setCroppingImagesQueue((prevQueue) => [...prevQueue, ...newImages]);
		if (!croppingImage) {
			startCroppingNextImage(newImages);
		}
		//reset event to predict conflict
		event.target.value = "";
	};

	const startCroppingNextImage = (images) => {
		// S'il reste des images à traiter
		if (images.length > 0) {
			// On sélectionne la prochaine image en file d'attente pour la rogner
			setCroppingImage(images[0]);
			setCroppingImagesQueue((prevQueue) => prevQueue.slice(1));
		}
	};
	// une fois l'image rogner --> on la reconvertie en file
	const handleCropComplete = (croppedAreaPixels) => {
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");
		const image = new Image();
		image.src = croppingImage;
		image.onload = () => {
			canvas.width = croppedAreaPixels.width;
			canvas.height = croppedAreaPixels.height;
			ctx.drawImage(
				image,
				croppedAreaPixels.x,
				croppedAreaPixels.y,
				croppedAreaPixels.width,
				croppedAreaPixels.height,
				0,
				0,
				croppedAreaPixels.width,
				croppedAreaPixels.height
			);
			canvas.toBlob((blob) => {
				const nameDate = Math.round(Date.now() / 1000);
				const url = URL.createObjectURL(blob);
				const file = new File([blob], nameDate + ".jpeg", {
					type: "image/jpeg",
				});
				const newImg = { file, url };
				setImages((prevImages) => [...prevImages, newImg]);
				setCroppingImage(null);
				startCroppingNextImage(croppingImagesQueue);
			}, "image/jpeg");
		};
	};

	const handleFetchTags = async () => {
		try {
			const tags = await requestAxiosGetTags();
			setTagsData(tags);
		} catch (error) {}
	};

	const handleImageRemove = (index) => {
		setImages(images.filter((_, i) => i !== index));
	};

	useEffect(() => {
		if (isExpanded && tagsData?.length === 0) {
			handleFetchTags();
		}
	}, [isExpanded]);

	// Listener pour détecter les clicks en dehors de la section & la présence d'un brouillon
	useEffect(() => {
		((!draft && (input || images.length > 0 || selectedTags.length > 0)) ||
			(draft &&
				!input &&
				images.length === 0 &&
				selectedTags.length === 0)) &&
			setDraft(!draft);

		if (!draft) {
			const handleClickOutside = (event) => {
				if (
					!croppingImage &&
					postSectionRef.current &&
					!isTagsFocus &&
					!postSectionRef.current.contains(event.target)
				) {
					setIsExpanded(false);
					setHiddenTagSection(true);
					setComponentFocus();
				}
			};

			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}
	}, [
		input,
		images,
		postSectionRef,
		isTagsFocus,
		selectedTags,
		draft,
		croppingImage,
	]);

	// En fonction de la taille de l'écran on aura pas le même nombre d'image par page de carousel
	useEffect(() => {
		if (largeScreen) {
			if (maxScreen) {
				setSlidesPerView(4);
			} else {
				setSlidesPerView(3);
			}
		} else {
			setSlidesPerView(2);
		}
	}, [maxScreen, largeScreen, mobileScreen]);

	useEffect(() => {
		if (isExpanded) {
			switch (componentFocus) {
				case "img":
					inputImgRef?.current?.click();
					break;
				case "tag":
					setHiddenTagSection(false);
					break;
				default:
					inputTextRef?.current?.focus();
					break;
			}
		}
	}, [isExpanded, componentFocus]);

	return (
		<Box ref={postSectionRef}>
			<Box sx={SXBoxInputPost}>
				{!hiddenTagSection && (
					<InputAutoComplete
						data={tagsData}
						setSelection={setSelectedTags}
						setIsFocus={setIsTagsFocus}
					/>
				)}
				<Box display="flex" alignItems="center" justifyContent="center">
					<Avatar
						src={user?.profilePicture}
						sx={{
							ml: 2,
							mb: "auto",
							borderRadius: 1,
							mt: isExpanded ? 2 : 1,
						}}
					/>
					{isExpanded ? (
						<>
							<Box flex={1} ml={2} mr={2} mt={2}>
								<TextField
									fullWidth
									multiline
									rows={4}
									variant="standard"
									placeholder={
										"Exprimez votre bonne action..."
									}
									value={input}
									onChange={(e) => setInput(e.target.value)}
									style={{ border: "0" }}
									InputProps={{ disableUnderline: true }}
									inputRef={inputTextRef}
								/>
							</Box>
							{draft && (
								<IconButton
									onClick={() => setOpen(true)}
									sx={{ mb: "auto", mt: 1, mr: 2 }}
								>
									<DeleteIcon />
								</IconButton>
							)}
							<ConfirmModal
								open={open}
								title={
									"Attention : cette action est irréversible !"
								}
								description={
									"Voulez vous supprimer ce brouillon ?"
								}
								handleConfirmClick={handleRemovePostClick}
								handleClose={() => setOpen(false)}
							/>
						</>
					) : (
						<Box
							flex={1}
							ml={2}
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							borderRadius={1}
							onClick={(e) => setIsExpanded(true)}
							p={1}
							sx={{
								backgroundColor: theme.palette.secondary.main,
								color: theme.palette.otherColor.main,
								cursor: "pointer",
								"&:hover": {
									backgroundColor:
										theme.palette.secondary.light,
								},
							}}
						>
							<Typography>
								Exprimez votre bonne action...
							</Typography>
							<Box ml="auto" display="flex">
								<IconButton
									onClick={(e) => setComponentFocus("img")}
								>
									<ImageIcon />
								</IconButton>
								<IconButton
									onClick={(e) => setComponentFocus("tag")}
								>
									<TagIcon />
								</IconButton>
							</Box>
						</Box>
					)}
				</Box>
				{isExpanded && (
					<>
						<Box
							display="flex"
							justifyContent="center"
							mt={2}
							mb={2}
						>
							{images.length > slidesPerView ? (
								<CarouselPostImage
									key={images.join("_")}
									images={images}
									handleImageRemove={handleImageRemove}
									slidesPerView={slidesPerView}
								/>
							) : (
								<Box display="flex" justifyContent="center">
									{images &&
										images.map((image, index) => (
											<ImageControl
												key={"image-control-" + index}
												index={index}
												image={image?.url}
												handleImageRemove={
													handleImageRemove
												}
											/>
										))}
								</Box>
							)}
						</Box>

						<Box
							pt={1}
							pb={1}
							pl={2}
							pr={2}
							display="flex"
							justifyContent="space-between"
							borderRadius=" 0px 0px 7px 7px"
							boxShadow="0px -1px 3px rgba(0, 0, 0, 0.1)"
						>
							<Box>
								<Tooltip title="Ajouter des images">
									<IconButton component="label">
										<input
											ref={inputImgRef}
											type="file"
											accept="image/*"
											multiple
											hidden
											onChange={handleImageChange}
										/>
										<ImageIcon />
									</IconButton>
								</Tooltip>

								<Tooltip title="Ajouter des tags">
									<IconButton
										component="label"
										onClick={(prev) =>
											setHiddenTagSection(
												!hiddenTagSection
											)
										}
									>
										<TagIcon />
									</IconButton>
								</Tooltip>
							</Box>

							<Button
								variant="contained"
								color="primary"
								onClick={handlePostSubmit}
								disabled={!input && images.length === 0}
								sx={{ color: theme.palette.secondary.main }}
							>
								Poster
							</Button>
						</Box>
					</>
				)}
			</Box>
			{croppingImage && (
				<ImageCropper
					imageSrc={croppingImage}
					onCropComplete={handleCropComplete}
					onCancel={() => {
						setCroppingImage(null);
						startCroppingNextImage(croppingImagesQueue);
					}}
					onClose={() => {
						setCroppingImage(null);
						setCroppingImagesQueue([]);
					}}
					aspect={5 / 4}
				/>
			)}
		</Box>
	);
};

export default CreatePostSection;
