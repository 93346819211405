import { Box, Typography } from "@mui/material";
import React from "react";
import CustomButton from "../../atoms/Buttons/CustomButton";
import InputCustom from "../../atoms/Inputs/InputCustom";
import { handleKeyDown } from "../../../utils/tool";

const ForgotForm = ({ email, setEmail, handleSubmit, error }) => {
	return (
		<Box
			component="form"
			autoComplete="on"
			sx={{ display: "flex", flexDirection: "column" }}
			onKeyDown={(e) => handleKeyDown(e, handleSubmit)}
			onSubmit={handleSubmit}
		>
			<InputCustom
				value={email}
				label={"Email"}
				entityName={"Email"}
				setValue={setEmail}
			/>

			<CustomButton
				content={"Envoyer mail de récupération"}
				css={{ width: "auto" }}
				onClick={handleSubmit}
			/>

			{error && (
				<Typography
					color="error"
					fontSize={14}
					textAlign={"center"}
					sx={{ mt: 2 }}
				>
					{error}
				</Typography>
			)}
		</Box>
	);
};

export default ForgotForm;
