import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNbNewsNotifs } from "../../store/slices/notifSlice";

const NotificationsFetcher = () => {
	const dispatch = useDispatch();

	const { isAuthenticated, user } = useSelector((state) => state.auth);

	useEffect(() => {
		if (isAuthenticated && user) {

			dispatch(fetchNbNewsNotifs());

			const intervalId = setInterval(() => {
				dispatch(fetchNbNewsNotifs());
			}, 10000); 

			return () => clearInterval(intervalId);
		}
	}, [isAuthenticated, user, dispatch]); 

	return null;
};

export default NotificationsFetcher;