import CircleIcon from "@mui/icons-material/Circle";
import {
	Avatar,
	Box,
	IconButton,
	Typography,
	useMediaQuery,
} from "@mui/material";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import React, { useState } from "react";
import CustomLoadingButton from "../../atoms/Buttons/CustomLoadingButton";
import { theme } from "../../../assets/themes/theme";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
	addOneFollower,
	addOneFollowing,
	cleanErrorU,
	deleteOneFollower,
	deleteOneFollowing,
} from "../../../store/slices/userSlice";
import { requestAxiosToggleFollowing } from "../../../services/axios/axiosRequests";
import ConfirmModal from "../Modal/ConfirmModal";

const FollowCard = ({ userId, handleClickProfil }) => {
	const auth = useSelector((state) => state?.auth);
	const user = useSelector((state) => state?.users?.itemsU[userId]);
	const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
	const [loadingBtn, setLoadingBtn] = useState(false);
	const dispatch = useDispatch();
	const [openConfirm, setOpenConfirm] = useState(false);

	const handleClickFollow = async () => {
		setLoadingBtn(true);
		try {
			await requestAxiosToggleFollowing(userId);
			dispatch(addOneFollower({ userId: userId }));
			dispatch(addOneFollowing({ userId: auth?.user?.id }));
			setLoadingBtn(false);
		} catch (err) {
			setLoadingBtn(false);
			toast.error(err?.message || "un problème est survenu", {
				toastId: "follow-error",
			});
			dispatch(cleanErrorU());
		}
	};

	const handleClickUnFollow = async () => {
		setLoadingBtn(true);
		setOpenConfirm(false);
		try {
			await requestAxiosToggleFollowing(userId);
			dispatch(deleteOneFollower({ userId: userId }));
			dispatch(deleteOneFollowing({ userId: auth?.user?.id }));
			setLoadingBtn(false);
		} catch (err) {
			setLoadingBtn(false);
			toast.error(err?.message || "un problème est survenu", {
				toastId: "follow-error",
			});
			dispatch(cleanErrorU());
		}
	};

	return (
		<Box
			display={"flex"}
			justifyContent={"space-between"}
			bgcolor={"white"}
			p={1.5}
			borderRadius={2}
			mb={1}
		>
			<Box display={"flex"}>
				<Avatar
					src={user?.profilePicture}
					alt="relation profile picture"
					onClick={handleClickProfil}
					sx={{
						borderRadius: 1,
						cursor: "pointer",
						mt: "auto",
						mb: "auto",
						width: "60px",
						height: "60px",
					}}
				/>
				<Box mt={"auto"} mb={"auto"}>
					<Typography fontWeight={600} ml={3}>
						{user?.firstName} {user?.lastName}
					</Typography>
					<Box display={"flex"}>
						<CircleIcon
							sx={{
								width: "10px",
								height: "10px",
								mt: "auto",
								mb: "auto",
								mr: 1,
								ml: 3,
								color: theme.palette.primary.main,
							}}
						/>
						<Typography>@{user?.username || "unknown"}</Typography>
					</Box>
				</Box>
			</Box>
			<Box display={"flex"}>
				{user.id != auth?.user?.id &&
					(user?.followedSince ? (
						smallScreen ? (
							<IconButton onClick={(e) => setOpenConfirm(true)} sx={{width:'45px', height:'45px', mt:'auto', mb: 'auto'}}>
								<PersonRemoveIcon sx={{color:theme.palette.primary.main}}/>
							</IconButton>
						) : (
							<CustomLoadingButton
								css={{ mt: "auto", mb: "auto" }}
								variant={"outlined"}
								content={"Ne plus suivre"}
								onClick={(e) => setOpenConfirm(true)}
								loading={loadingBtn}
							/>
						)
					) : smallScreen ? (
						<IconButton onClick={handleClickFollow} sx={{width:'45px', height:'45px', mt:'auto', mb: 'auto'}}>
							<PersonAddAlt1Icon sx={{color:theme.palette.primary.main}}/>
						</IconButton>
					) : (
						<CustomLoadingButton
							css={{ mt: "auto", mb: "auto" }}
							content={"Suivre"}
							onClick={handleClickFollow}
							loading={loadingBtn}
						/>
					))}
			</Box>
			<ConfirmModal
				open={openConfirm}
				title={"Supprimer le follow ?"}
				description={
					user?.firstName +
					" " +
					user?.lastName +
					" ne sera pas au courant de cette action."
				}
				handleClose={() => setOpenConfirm(false)}
				handleConfirmClick={handleClickUnFollow}
			/>
		</Box>
	);
};

export default FollowCard;
