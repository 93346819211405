import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";
import LinkedinAuthBtn from "../../atoms/Buttons/LinkedinAuthBtn";
import GoodMoveLogo from "../../../assets/images/logo_classic_outlined.svg";
import SignInForm from "../../molecules/Forms/SignInForm";
import OrSeparator from "../../atoms/OrSeparator/OrSeparator";
import { Link, useNavigate } from "react-router-dom";
import { theme } from "../../../assets/themes/theme";
import { useDispatch } from "react-redux";
import { cleanError } from "../../../store/slices/authSlice";

const SignInSection = ({
	email,
	setEmail,
	password,
	setPassword,
	handleSubmit,
	loading,
	errors,
	setErrors,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const SXBox = {
		ml: "auto",
		mr: "auto",
		mb: 5,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		maxWidth: "500px",
		width: "100%",
	};

	const SXBoxTypo = {
		ml: "auto",
		mr: "auto",
		mb: 3,
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "center",
		maxWidth: "500px",
		width: "100%",
	};

	return (
		<Grid
			item
			xs={12}
			sm={7}
			md={5}
			component={Paper}
			elevation={10}
			square
			sx={{
				borderRadius: "0px 7px 7px 0px",
				pl: 3,
				pr: 3,
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<Box sx={{ mt: 2, ...SXBox }}>
				<img alt="GoodMove" src={GoodMoveLogo} />
			</Box>
			<Box sx={SXBox}>
				<SignInForm
					email={email}
					password={password}
					setEmail={setEmail}
					setPassword={setPassword}
					handleSubmit={handleSubmit}
					loading={loading}
					errors={errors}
					setErrors={setErrors}
				/>
			</Box>
			<Box sx={SXBoxTypo}>
				<Typography sx={{ mr: 1 }}>
					Vous n'avez pas de compte ?
				</Typography>
				<Typography
					onClick={() => {
						dispatch(cleanError());
						navigate("/signup");
					}}
					color={theme.palette.primary.main}
					sx={{ cursor: "pointer", textDecoration: "underline" }}
				>
					Créer un compte
				</Typography>
			</Box>
			<Box sx={SXBox}>
				<OrSeparator />
				<LinkedinAuthBtn />
				{errors?.linkedin && (
					<Typography
						color="error"
						fontSize={14}
						textAlign={"center"}
						sx={{ mt: 2 }}
					>
						{errors.linkedin}
					</Typography>
				)}
			</Box>
		</Grid>
	);
};

export default SignInSection;
