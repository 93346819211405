import React, { useEffect, useState } from "react";
import Navbar from "../molecules/Navbar/Navbar";
import Rightbar from "../organisms/Rightbar/Rightbar";
import { Box, Stack } from "@mui/material";
import BottomNavbar from "../molecules/BottomNavbar/BottomNavbar";
import FeedSection from "../organisms/FeedSection/FeedSection";
import HeaderMobile from "../molecules/HeaderMobile/HeaderMobile";
import LoadingSection from "../organisms/LoadingSection/LoadingSection";
import AddPostMobileBtn from "../atoms/Buttons/AddPostMobileBtn";
import { useDispatch, useSelector } from "react-redux";
import {
	addPost,
	cleanErrorP,
	fetchPosts,
	sortPosts,
} from "../../store/slices/postSlice";
import { toast } from "react-toastify";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import CreatePostSectionModale from "../organisms/CreatePostSection/CreatePostSectionModal";
import { useSearchParams } from "react-router-dom";

const FeedPage = ({ SXMarginSidebar }) => {
	document.title = "Fil d'actualité | Goodmove";
	const [isLoading, setIsLoading] = useState(true);
	const [loadingInit, setLoadingInit] = useState(true);
	const [loadingFilter, setLoadingFilter] = useState(true);
	const [open, setOpen] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [filter, setFilter] = useState("all");
	const [targetRef, isIntersecting] = useIntersectionObserver({
		threshold: 0.1,
	});

	const dispatch = useDispatch();

	const { itemsP, endDataP, loadingP, pageP, limitP, errorP } = useSelector(
		(state) => state.posts
	);

	const user = useSelector((state) => state.auth.user);

	useEffect(() => {
		setLoadingInit(true);
		const filterParam = searchParams.get("filter");
		const tag = searchParams.get("tag");
		if (tag) {
			setFilter(tag);
		} else if (filterParam === "follow") {
			setFilter("follow");
		} else {
			setFilter("all");
		}
		setLoadingFilter(false);
	}, [searchParams]);

	useEffect(() => {
		if (
			((!endDataP && isIntersecting) ||
				(loadingInit && itemsP.length === 0)) &&
			!loadingP &&
			!loadingFilter
		) {
			loadingInit && setLoadingInit(false);
			dispatch(fetchPosts({ page: pageP + 1, limit: limitP, filter }));
		} else if (!loadingFilter && loadingInit && itemsP.length > 0) {
			loadingInit && setLoadingInit(false);
			dispatch(sortPosts());
		} else if (!loadingFilter && !loadingInit && itemsP.length === 0) {
			setIsLoading(false);
		}
	}, [endDataP, isIntersecting, filter, loadingFilter]);

	useEffect(() => {
		if (itemsP?.length > 0 && !loadingP) {
			setIsLoading(false);
		}
	}, [itemsP]);

	const handleAddPost = (newPost) => {
		open && setOpen(false);
		dispatch(addPost({ userId: user?.id, newPost }));
	};

	const handleClickFetch = () => {
		dispatch(fetchPosts({ page: 1, limit: limitP, filter }));
	};

	if (errorP) {
		toast.error(errorP?.message || "un problème est survenu", {
			toastId: "post-error",
		});
		dispatch(cleanErrorP());
	}

	if (isLoading || loadingFilter || loadingInit ) {
		return <LoadingSection />;
	}

	return (
		<Box minHeight={"100vh"}>
			<Navbar handleOpenCreatePost={() => setOpen(true)} />
			<HeaderMobile />
			<Box sx={{ ml: SXMarginSidebar(), pt: {xs:12.5, sm:0, md:0, lg:0, xl:0} }}>
				<Stack direction="row">
					<FeedSection
						handleAddPost={handleAddPost}
						targetRef={targetRef}
						handleClickFetch={handleClickFetch}
					/>
					<Rightbar />
				</Stack>
			</Box>
			<AddPostMobileBtn handleOpenCreatePost={() => setOpen(true)} />
			<CreatePostSectionModale
				open={open}
				handleClose={() => setOpen(false)}
				handleAddPost={handleAddPost}
			/>
			<BottomNavbar />
		</Box>
	);
};

export default FeedPage;
