import React from "react";
import { Button, Typography } from "@mui/material";
import { theme } from "../../../assets/themes/theme";
import { Link } from "react-router-dom";

const CustomButton = ({
	content,
	css,
	onClick,
	variant,
	variantTypo,
	disabled,
	destination,
}) => {
	const SXCustomOutlinedBtn = {
		boxShadow: "1px 3px 6px rgba(85, 85, 85, 0.25)",
		ml: "auto",
		mr: "auto",
		height: 39,
		width: "230px",
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.light,
		"&:hover": {
			backgroundColor: theme.palette.primary.dark,
		},
		...css,
	};

	return (
		<Button
			component={destination && Link}
			to={destination && destination}
			variant={variant || "contained"}
			sx={SXCustomOutlinedBtn}
			onClick={onClick}
			disabled={disabled || false}
		>
			<Typography
				variant={variantTypo || "button"}
			>
				{content}
			</Typography>
		</Button>
	);
};

export default CustomButton;
