import axios from "axios";

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_API_BASE_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

axiosInstance.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("token");
		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

axiosInstance.interceptors.response.use(
	(response) => {
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			try {
				const cookieHeader = document.cookie;

				const refresh_token = cookieHeader
					?.split("; ")
					?.find((row) => row.startsWith("refresh_token"));
 
				if (!refresh_token) {
					localStorage.removeItem("token");
					throw new Error("No refresh_token found in cookies.");
				}

				const refresh_token_value = refresh_token.split("=")[1];
				const response = await axios.post(
					`${process.env.REACT_APP_BACKEND_API_BASE_URL}/auth/refresh`,
					{ refresh_token : refresh_token_value }
				);
				const { token } = response.data;
				localStorage.setItem("token", token);
				axiosInstance.defaults.headers[
					"Authorization"
				] = `Bearer ${token}`;
				originalRequest.headers["Authorization"] = `Bearer ${token}`;
				return axiosInstance(originalRequest);
			} catch (e) {
				return Promise.reject(error);
			}
		}
		return Promise.reject(error);
	}
);

export default axiosInstance;
