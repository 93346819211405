import React from "react";

export const formatText = (text) => {
    return text && text.split('\n').map((str, index) => (
      <React.Fragment key={index}>
        {str}
        <br />
      </React.Fragment>
    ));
};

const monthNames = [
	"janvier",
	"février",
	"mars",
	"avril",
	"mai",
	"juin",
	"juillet",
	"août",
	"septembre",
	"octobre",
	"novembre",
	"décembre",
];

export const timeElapsed = (createdAt) => {
	const now = new Date();
	const givenDate = new Date(createdAt);
	const diffInSeconds = Math.floor((now - givenDate) / 1000);

	if (diffInSeconds < 60) {
		return "maintenant";
	}

	const diffInMinutes = Math.floor(diffInSeconds / 60);
	if (diffInMinutes < 60) {
		return `${diffInMinutes} min`;
	}

	const diffInHours = Math.floor(diffInMinutes / 60);
	if (diffInHours < 24) {
		return `${diffInHours} h`;
	}

	const diffInDays = Math.floor(diffInHours / 24);
	if (diffInDays < 7) {
		return `${diffInDays} j`;
	}

	const diffInWeeks = Math.floor(diffInDays / 7);
	if (diffInWeeks < 4) {
		return `${diffInWeeks} sem`;
	}

	const diffInMonths = Math.floor(diffInDays / 30);
	if (diffInMonths < 12) {
		const day = givenDate.getDate();
		const month = monthNames[givenDate.getMonth()];
		const year = givenDate.getFullYear();
		return `${day} ${month} ${year}`;
	}

	const day = givenDate.getDate();
	const month = monthNames[givenDate.getMonth()];
	const year = givenDate.getFullYear();
	return `${day} ${month} ${year}`;
};

export const timeElapsedDetails = (createdAt) => {
	const givenDate = new Date(createdAt);

	const day = givenDate.getDate();
	const month = monthNames[givenDate.getMonth()];
	const year = givenDate.getFullYear();
	return `${day} ${month} ${year}`;
};

export const handleKeyDown = (e, handleSubmit) => {
	if (e.key === "Enter") {
		handleSubmit(e);
	}
};
