import {
	Avatar,
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Tooltip,
	Typography,
	useMediaQuery,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import TagIcon from "@mui/icons-material/Tag";
import ImageIcon from "@mui/icons-material/Image";
import React, { useEffect, useState } from "react";
import CarouselPostImage from "../../molecules/Carousel/CarouselPostImage";
import ImageControl from "../../atoms/ImageControl/ImageControl";
import ImageCropper from "../../molecules/ImageCropper/ImageCropper";
import InputAutoComplete from "../../atoms/Inputs/InputAutoComplete";
import { theme } from "../../../assets/themes/theme";
import { useSelector } from "react-redux";
import ConfirmModal from "../../molecules/Modal/ConfirmModal";
import { requestAxiosGetTags } from "../../../services/axios/axiosRequests";

const CreatePostSectionModale = ({ open, handleClose, handleAddPost }) => {
	const maxScreen = useMediaQuery(theme.breakpoints.up("lg"));
	const largeScreen = useMediaQuery(theme.breakpoints.up("md"));
	const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [input, setInput] = useState("");
	const [images, setImages] = useState([]);
	const [croppingImagesQueue, setCroppingImagesQueue] = useState([]);
	const [croppingImage, setCroppingImage] = useState(null);
	const [slidesPerView, setSlidesPerView] = useState(0);
	const [openDelete, setOpenDelete] = useState(false);
	const [hiddenTagSection, setHiddenTagSection] = useState(true);
	const [selectedTags, setSelectedTags] = useState([]);
	const [tagsData, setTagsData] = useState([]);

	const { user } = useSelector((state) => state.auth);

	const SXBoxInputPost = {
		width: {
			xs: "100%",
			sm: "100%",
			md: "800px",
			lg: "1100px",
		},
		height: {
			xs: "100%",
			sm: "100%",
			md: "auto",
		},
		display: "flex",
		flexDirection: "column",
		m: "auto",
		borderRadius: "7px",
		boxShadow: "1px 3px 6px rgba(85, 85, 85, 0.25)",
		backgroundColor: theme.palette.secondary.main,
		color: theme.palette.otherColor.main,
	};

	// Si on publie le post
	const handlePostSubmit = () => {
		if (input || images.length) {
			const newPost = {
				content: input,
				tags: selectedTags,
				imgs: [],
			};
			images.map((image, index) => {
				return newPost.imgs.push({
					id: "image-" + index + 1,
					src: image,
				});
			});
			handleAddPost(newPost);
			setInput("");
			setImages([]);
			setSelectedTags([]);
			setHiddenTagSection(true);
		}
	};

	const handleOnClose = () => {
		if (input || images.length > 0 || selectedTags.length > 0) {
			setOpenDelete(true);
		} else {
			handleClose();
		}
	};

	const handleRemovePostClick = () => {
		setInput("");
		setImages([]);
		setSelectedTags([]);
		setOpenDelete(false);
		handleClose();
	};

	const handleFetchTags = async () => {
		try {
			const tags = await requestAxiosGetTags();
			setTagsData(tags);
		} catch (error) {}
	};

	const handleImageChange = (event) => {
		const files = event.target.files;
		const newImages = Array.from(files).map((file) =>
			URL.createObjectURL(file)
		);
		// On met à jour la liste des images en file d'attente avant retouche
		setCroppingImagesQueue((prevQueue) => [...prevQueue, ...newImages]);
		if (!croppingImage) {
			startCroppingNextImage(newImages);
		}
		event.target.value = "";
	};

	const startCroppingNextImage = (images) => {
		// S'il reste des images à traiter
		if (images.length > 0) {
			// On sélectionne la prochaine image en file d'attente pour la rogner
			setCroppingImage(images[0]);
			setCroppingImagesQueue((prevQueue) => prevQueue.slice(1));
		}
	};

	// une fois l'image rogner --> on la reconvertie en file
	const handleCropComplete = (croppedAreaPixels) => {
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");
		const image = new Image();
		image.src = croppingImage;
		image.onload = () => {
			canvas.width = croppedAreaPixels.width;
			canvas.height = croppedAreaPixels.height;
			ctx.drawImage(
				image,
				croppedAreaPixels.x,
				croppedAreaPixels.y,
				croppedAreaPixels.width,
				croppedAreaPixels.height,
				0,
				0,
				croppedAreaPixels.width,
				croppedAreaPixels.height
			);
			canvas.toBlob((blob) => {
				const nameDate = Math.round(Date.now() / 1000);
				const url = URL.createObjectURL(blob);
				const file = new File([blob], nameDate + ".jpg", {
					type: "image/jpeg",
				});
				const newImg = { file, url };
				setImages((prevImages) => [...prevImages, newImg]);
				setCroppingImage(null);
				startCroppingNextImage(croppingImagesQueue);
			}, "image/jpeg");
		};
	};

	const handleImageRemove = (index) => {
		setImages(images.filter((_, i) => i !== index));
	};

	// En fonction de la taille de l'écran on aura pas le même nombre d'image par page de carousel
	useEffect(() => {
		if (largeScreen) {
			if (maxScreen) {
				setSlidesPerView(4);
			} else {
				setSlidesPerView(3);
			}
		} else {
			if (mobileScreen) {
				setSlidesPerView(1);
			} else {
				setSlidesPerView(2);
			}
		}
	}, [maxScreen, largeScreen, mobileScreen]);

	useEffect(() => {
		if (open && tagsData?.length === 0) {
			handleFetchTags();
		}
	}, [open]);

	return (
		<Modal
			open={open}
			onClose={handleOnClose}
			aria-labelledby="modal-created-post"
			aria-describedby="modal-created-post"
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<Box sx={SXBoxInputPost}>
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					mt={2}
					mb={2}
					position={"relative"}
				>
					<IconButton
						component="label"
						onClick={handleOnClose}
						sx={{ position: "absolute", left: 15 }}
					>
						<KeyboardBackspaceIcon />
					</IconButton>
					<Typography textAlign={"center"} fontWeight={600}>
						Publier sur votre journal
					</Typography>
				</Box>
				{!hiddenTagSection && (
					<InputAutoComplete
						data={tagsData}
						setSelection={setSelectedTags}
					/>
				)}
				<Box display="flex" alignItems="center" justifyContent="center">
					<Avatar
						src={user?.profilePicture}
						sx={{
							ml: 2,
							mb: "auto",
							borderRadius: 1,
							mt: 1,
						}}
					/>
					<Box flex={1} ml={2} mr={2} mt={1.5}>
						<TextField
							fullWidth
							multiline
							rows={4}
							variant="standard"
							placeholder={"Exprimez votre bonne action..."}
							value={input}
							onChange={(e) => setInput(e.target.value)}
							style={{ border: "0" }}
							InputProps={{ disableUnderline: true }}
						/>
					</Box>
				</Box>
				<Box display="flex" justifyContent="center" mt={2} mb={2}>
					{images.length > slidesPerView ? (
						<CarouselPostImage
							key={images.join("_")}
							images={images}
							handleImageRemove={handleImageRemove}
							slidesPerView={slidesPerView}
						/>
					) : (
						<Box display="flex" justifyContent="center">
							{images &&
								images.map((image, index) => (
									<ImageControl
										key={"image-control-" + index}
										index={index}
										image={image?.url}
										handleImageRemove={handleImageRemove}
									/>
								))}
						</Box>
					)}
				</Box>
				<ConfirmModal
					open={openDelete}
					title={"Attention : cette action est irréversible !"}
					description={"Voulez vous supprimer ce brouillon ?"}
					handleConfirmClick={handleRemovePostClick}
					handleClose={() => setOpenDelete(false)}
				/>
				<Box
					pt={1}
					pb={1}
					pl={2}
					pr={2}
					display="flex"
					justifyContent="space-between"
					borderRadius=" 0px 0px 7px 7px"
					boxShadow="0px -1px 3px rgba(0, 0, 0, 0.1)"
				>
					<Box>
						<Tooltip title="Ajouter des images">
							<IconButton component="label">
								<input
									type="file"
									accept="image/*"
									multiple
									hidden
									onChange={handleImageChange}
								/>
								<ImageIcon />
							</IconButton>
						</Tooltip>

						<Tooltip title="Ajouter des tags">
							<IconButton
								component="label"
								onClick={(prev) =>
									setHiddenTagSection(!hiddenTagSection)
								}
							>
								<TagIcon />
							</IconButton>
						</Tooltip>
					</Box>

					<Button
						variant="contained"
						color="primary"
						onClick={handlePostSubmit}
						disabled={!input && images.length === 0}
						sx={{ color: theme.palette.secondary.main }}
					>
						Poster
					</Button>
				</Box>
				{croppingImage != null && (
					<ImageCropper
						imageSrc={croppingImage}
						onCropComplete={handleCropComplete}
						onCancel={() => {
							setCroppingImage(null);
							startCroppingNextImage(croppingImagesQueue);
						}}
						onClose={() => {
							setCroppingImage(null);
							setCroppingImagesQueue([]);
						}}
						aspect={5 / 4}
					/>
				)}
			</Box>
		</Modal>
	);
};

export default CreatePostSectionModale;
