import { Box, Icon, Typography } from "@mui/material";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import React from "react";

const CustomTabCard = ({ icon, label }) => {
	return (
		<Box
			sx={{
				width: {
					xs: "100%",
					sm: "100%",
					md: "100%",
					lg: "350px",
					xl: "350px",
				},
                p:3,
                borderRadius: "16px",
                boxShadow: "1px 3px 6px rgba(85, 85, 85, 0.25)",
                bgcolor: "white",
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Box display={"flex"} >
					<Icon
						component={icon}
						sx={{ fontSize: 30, mt: "auto", mb: "auto", mr: 2 }}
					/>
					<Typography
						variant="body2"
						align="center"
						sx={{ mt: "auto", mb: "auto" }}
                        textAlign={'left'}
                        fontWeight={500}
					>
						{label}
					</Typography>
				</Box>

				<KeyboardArrowRightOutlinedIcon
					sx={{ mt: "auto", mb: "auto" }}
				/>
			</Box>
		</Box>
	);
};

export default CustomTabCard;
