import React, { useEffect, useState } from "react";
import LoadingSection from "../organisms/LoadingSection/LoadingSection";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useNavigationHistory } from "../../contexts/NavigationContext";

const NotFoundPage = () => {
	const [nbSec, setNbSec] = useState(5);
	const navigate = useNavigate();
	const history = useNavigationHistory();

	const handleBackRedirect = () => {
		document.title = "Page non trouvée | Goodmove";
		toast.dismiss("404-page-error");
		if (history.current.length > 1) {
			const currentPath = history.current[history.current.length - 1];
			let lastDifferentOriginIndex = history.current.length - 2;

			while (
				lastDifferentOriginIndex >= 0 &&
				history.current[lastDifferentOriginIndex] === currentPath
			) {
				lastDifferentOriginIndex--;
			}

			if (lastDifferentOriginIndex >= 0) {
				const lastPath = history.current[lastDifferentOriginIndex];
				if (lastPath.startsWith("/")) {
					navigate(lastPath);
					history.current = history.current.slice(
						0,
						lastDifferentOriginIndex + 1
					);
				} else {
					navigate("/feed");
					history.current = ["/feed"];
				}
			} else {
				navigate("/feed");
				history.current = ["/feed"];
			}
		} else {
			navigate("/feed");
		}
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			setNbSec((prev) => (prev > 0 ? prev - 1 : prev));
		}, 1000);

		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		if (nbSec === 0) {
			handleBackRedirect();
		} else {
			toast.update("404-page-error", {
				render: `La page que vous cherchez est indisponible. Redirection dans ${nbSec} secondes.`,
			});
		}
	}, [nbSec]);

	useEffect(() => {
		toast.error(
			`La page que vous cherchez est indisponible. Redirection dans ${nbSec} secondes.`,
			{
				toastId: "404-page-error",
				onClick: handleBackRedirect,
				autoClose: false, 
			}
		);
	}, []);

	return <LoadingSection />;
};

export default NotFoundPage;
