import React, { useEffect, useState } from "react";
import { Container, useMediaQuery } from "@mui/material";
import Trophy from "../../atoms/Trophy/Trophy";
import { theme } from "../../../assets/themes/theme";
import { requestAxiosGetChallenges } from "../../../services/axios/axiosRequests";
import { useParams } from "react-router-dom";

const Trophies = () => {
	const isWeb = useMediaQuery(theme.breakpoints.up("lg"));
	const [challenges, setChallenges] = useState([]);
	const { id } = useParams();

	useEffect(() => {
		if (challenges?.length === 0) {
			handleFetchChallenges();
		}
	}, []);

	const handleFetchChallenges = async () => {
		try {
			const res = await requestAxiosGetChallenges(id);
			setChallenges([...res]);
		} catch (error) {}
	};

	return (
		<Container
			sx={{
				display: "grid",
				gridTemplateColumns: isWeb
					? "repeat(auto-fill, minmax(400px, 1fr))"
					: "repeat(auto-fill, minmax(300px, 1fr))",
				gap: "20px",
				padding: "20px",
				mb:10,
			}}
		>
			{challenges?.length > 0 &&
				challenges?.map((challenge, key) => {
					return <Trophy key={key} trophy={challenge} />;
				})}
		</Container>
	);
};

export default Trophies;
