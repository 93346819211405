import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardHeader,
	CardMedia,
	Divider,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { theme } from "../../../assets/themes/theme";
import { useNavigate } from "react-router-dom";
import { timeElapsed } from "../../../utils/tool";
import CarouselViewer from "../Carousel/CarouselViewer";
import CommentShared from "../../atoms/Comment/CommentShared";
import ReplayIcon from '@mui/icons-material/Replay';
import commentGif from "../../../assets/videos/redaction.gif";

const SharedPost = ({ post, comments, handleLoadMoreComments }) => {
	const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));
	const [isExpanded, setIsExpanded] = useState(false);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const contentRef = useRef(null);
	const navigate = useNavigate();

	const timeElapsedStr = timeElapsed(post?.createdAt);

	const SXBoxContainer = {
		display: "flex",
		ml: "auto",
		mr: "auto",
	};

	const SXCardMediaSmallScreen = {
		width: "100%",
		height: "auto",
	};

	const SXCardMediaWeb = {
		width: {
			lg: "430px",
			xl: "550px",
		},
		height: {
			lg: "344px",
			xl: "440px",
		},
		borderRadius: "10px",
	};

	const handleToggleExpand = () => {
		setIsExpanded(!isExpanded);
	};

	useEffect(() => {
		const element = contentRef.current;
		if (element) {
			setIsOverflowing(element.scrollHeight > element.clientHeight);
		}
	}, [post?.description]);

	return (
		<Card
			sx={{
				width: {
					xs: "100%",
					sm: "600px",
					md: "850px",
					lg: "900px",
					xl: "1200px",
				},
				height: {
					xs: "100%",
					sm: "auto",
				},
				ml: "auto",
				mr: "auto",
			}}
		>
			<Box sx={SXBoxContainer}>
				{!smallScreen && (
					<Box ml={2} mt={"auto"} mb={"auto"}>
						{post?.mediaObjects &&
							post?.mediaObjects.length > 0 &&
							(post?.mediaObjects.length === 1 ? (
								<CardMedia
									component="img"
									image={post?.mediaObjects[0].picture_url}
									alt="poste img"
									sx={{
										mt: 2,
										mb: 2,
										...SXCardMediaWeb,
									}}
								/>
							) : (
								<Box
									mt={2}
									mb={2}
									sx={{
										...SXCardMediaWeb,
										borderRadius: 0,
									}}
								>
									<CarouselViewer
										images={post?.mediaObjects}
										SXCardMedia={SXCardMediaWeb}
									/>
								</Box>
							))}
					</Box>
				)}
				<Box
					flex={1}
					display={"flex"}
					flexDirection={"column"}
					sx={{
						maxHeight: {
							xs: "100vh",
							sm: "100vh",
							md: "100vh",
							lg: "344px",
							xl: "440px",
						},
						overflowY: {
							xs: "scroll",
							sm: "scroll",
							md: "scroll",
							lg: "hidden",
							xl: "hidden",
						},
					}}
				>
					<CardHeader
						avatar={
							<Avatar
								src={post?.user?.profilePicture}
								onClick={() =>
									navigate("/profile/" + post?.user?.id)
								}
								sx={{ borderRadius: 1, cursor: "pointer" }}
							/>
						}
						title={
							post?.user?.firstName + " " + post?.user?.lastName
						}
						subheader={timeElapsedStr}
						sx={{
							pb: 1,
							".MuiCardHeader-title": {
								fontSize: "16px",
								fontWeight: "600",
							},
							".MuiCardHeader-subheader": {
								fontSize: "12px",
								fontWeight: "500",
								color: theme.palette.primary.main,
							},
						}}
					/>
					<CardContent sx={{ pt: 1, pb: 1 }}>
						<Typography
							ref={contentRef}
							variant="body2"
							sx={{
								width: "100%",
								overflow: "hidden",
								overflowWrap: "break-word",
								display: "-webkit-box",
								WebkitBoxOrient: "vertical",
								WebkitLineClamp: isExpanded ? "none" : 5,
								lineClamp: isExpanded ? "none" : 5,
							}}
						>
							{post?.description}
						</Typography>
						{isOverflowing && (
							<Typography
								variant="body2"
								onClick={handleToggleExpand}
								sx={{ cursor: "pointer" }}
							>
								{isExpanded ? "voir moins" : "voir plus..."}
							</Typography>
						)}
					</CardContent>

					{smallScreen && (
						<Box ml={"auto"} mr={"auto"}>
							{post?.mediaObjects &&
								post?.mediaObjects.length > 0 &&
								(post?.mediaObjects.length === 1 ? (
									<CardMedia
										component="img"
										image={
											post?.mediaObjects[0].picture_url
										}
										alt="poste img"
										sx={SXCardMediaSmallScreen}
									/>
								) : (
									<Box
										position="relative"
										sx={{
											maxWidth: {
												xs: "450px",
												sm: "480px",
												md: "600px",
												lg: "800px",
											},
										}}
									>
										<CarouselViewer
											images={post?.mediaObjects}
											SXCardMedia={SXCardMediaSmallScreen}
										/>
									</Box>
								))}
						</Box>
					)}
					<Divider sx={{ mt: 3, ml: 5, mr: 5, mb: 2 }} />
					<Box
						ml={1}
						mr={1}
						sx={{
							overflowY: {
								xs: "visible",
								sm: "visible",
								md: "visible",
								lg: "scroll",
								xl: "scroll",
							},
						}}
					>
						{comments?.length > 0 ? (
							<>
								{comments?.map((comment) => {
									return (
										<Box key={comment?.id} mb={2}>
											<CommentShared comment={comment} />
										</Box>
									);
								})}
							</>
						) : (
							<>
								<Box
									display={"flex"}
									justifyContent={"center"}
									flexDirection={"column"}
								>
									<CardMedia
										component="img"
										image={commentGif}
										alt="wait-ro-search"
										sx={{
											width: "50px",
											height: "50px",
											ml: "auto",
											mr: "auto",
											mt: 2,
										}}
									/>
									<Typography
										textAlign={"center"}
										mt={1}
										fontWeight={500}
										fontSize={14}
										color={theme.palette.otherColor.main}
									>
										Soyez la première personne à donner
										votre avis
									</Typography>
								</Box>
							</>
						)}
						<Box
							textAlign={"center"}
							onClick={handleLoadMoreComments}
							sx={{ cursor: "pointer" }}
							mb={6}
							mt={3}
						>
							<ReplayIcon />
						</Box>
					</Box>
				</Box>
			</Box>
		</Card>
	);
};

export default SharedPost;
