import { Box, Link, Typography } from "@mui/material";
import React from "react";
import InputPassword from "../../atoms/Inputs/InputPassword";
import InputCustom from "../../atoms/Inputs/InputCustom";
import CustomLoadingButton from "../../atoms/Buttons/CustomLoadingButton";
import { handleKeyDown } from "../../../utils/tool";

const SignUpForm = ({
	email,
	firstName,
	lastName,
	password,
	confirmPassword,
	setEmail,
	setPassword,
	setConfirmPassword,
	setFirstName,
	setLastName,
	handleSubmit,
	loading,
	errors,
	setErrors,
}) => {
	return (
		<Box
			component="form"
			autoComplete="on"
			sx={{ display: "flex", flexDirection: "column" }}
			onKeyDown={(e) => handleKeyDown(e, handleSubmit)}
			onSubmit={handleSubmit}
		>
			<InputCustom
				value={email}
				errors={errors}
				label={"Email"}
				entityName={"email"}
				setErrors={setErrors}
				setValue={setEmail}
			/>

			<Box display={"flex"} flexWrap={"wrap"}>
				<Box flex={1} mr={1}>
					<InputCustom
						value={lastName}
						errors={errors}
						label={"Nom"}
						entityName={"Nom"}
						setErrors={setErrors}
						setValue={setLastName}
					/>
				</Box>
				<Box flex={1}>
					<InputCustom
						value={firstName}
						errors={errors}
						label={"Prénom"}
						entityName={"Prénom"}
						setErrors={setErrors}
						setValue={setFirstName}
					/>
				</Box>
			</Box>

			<InputPassword
				password={password}
				setPassword={setPassword}
				label={"Mot de passe"}
				entityName={"password"}
				errors={errors}
				setErrors={setErrors}
			/>

			<InputPassword
				password={confirmPassword}
				setPassword={setConfirmPassword}
				label={"Confirmer mot de passe"}
				entityName={"confirmPassword"}
				errors={errors}
				setErrors={setErrors}
			/>
			<Typography fontSize={10} m={1}>
				En vous inscrivant, vous acceptez les{" "}
				<Link href="/terms" target="_blank" rel="noopener">
					Conditions d'utilisation
				</Link>{" "}
				et la{" "}
				<Link href="/privacy" target="_blank" rel="noopener">
					Politique de confidentialité
				</Link>
				, notamment l'{" "}
				<Link href="/cookies" target="_blank" rel="noopener">
					Utilisation des cookies
				</Link>
				.
			</Typography>
			<CustomLoadingButton
				content={"Créer un compte"}
				css={{ mt: 1 }}
				onClick={handleSubmit}
				loading={loading}
			/>

			{errors?.global && (
				<Typography
					color="error"
					fontSize={14}
					textAlign={"center"}
					sx={{ mt: 2 }}
				>
					{errors.global}
				</Typography>
			)}
		</Box>
	);
};

export default SignUpForm;
