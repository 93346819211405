import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	requestAxiosGetNewsNotifs,
	requestAxiosGetNotifs,
	requestAxiosReadNotifs,
} from "../../services/axios/axiosRequests";

export const fetchNotifs = createAsyncThunk(
	"notifs/fetchNotifs",
	async ({ pageN, limitN, categories }, { rejectWithValue }) => {
		try {
			const response = await requestAxiosGetNotifs(
				pageN,
				limitN,
				categories,
				false
			);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const fetchNbNewsNotifs = createAsyncThunk(
	"notifs/fetchNbNewsNotifs",
	async (_, { rejectWithValue }) => {
		try {
			const response = await requestAxiosGetNewsNotifs(1, 100, true);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

//ici 2 array de posts sont présent
//car le chargement des posts d'un profile ne doit pas impacter les posts du fil d'actualité
const notifSlice = createSlice({
	name: "notifs",
	initialState: {
		itemsN: [],
		limitN: 30,
		pageN: 0,
		endDataN: false,
		loadingN: false,
		errorN: null,
		nbNewsNotifs: 0,
	},
	reducers: {
		sortNotifications: (state) => {
			state.itemsN?.sort((a, b) => {
				if (a.createdAt < b.createdAt) return 1;
				if (a.createdAt > b.createdAt) return -1;
				return 0;
			});
		},
		readNotifications: (state, action) => {
			try {
				const { notifId } = action.payload;
				if (notifId) {
					const notRead = state.itemsN?.find(
						(notif) => notif?.id === notifId
					);

					if (notRead && notRead?.readAt === null) {
						requestAxiosReadNotifs([notifId]);
						state.nbNewsNotifs -= 1;
					}
				}
			} catch (error) {
				state.errorN = error;
			}
		},
		cleanErrorN: (state) => {
			state.errorN = null;
		},
		cleanDataN: (state) => {
			state.errorN = null;
			state.itemsN = [];
			state.pageN = 0;
			state.endDataN = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchNotifs.pending, (state) => {
				state.loadingN = true;
				state.errorN = null;
			})
			.addCase(fetchNotifs.fulfilled, (state, action) => {
				const { items, pagination } = action.payload;
				if (state.itemsN?.length === 0) {
					state.itemsN = items;
				} else {
					const existingNotifsIds = new Set(
						state.itemsN?.map((notif) => notif?.id)
					);
					const newNotifs = items?.filter(
						(notif) => !existingNotifsIds.has(notif?.id)
					);
					if (newNotifs) {
						state.itemsN = [...state.itemsN, ...newNotifs];
						state.itemsN.sort(
							(a, b) =>
								new Date(b.createdAt) - new Date(a.createdAt)
						);
					}
				}

				const endDataN = state.itemsN?.length === pagination?.total;
				const limitReached = items?.length === pagination?.limit;
				state.endDataN = endDataN;
				// S'il reste des posts à charger on passe à la page suivante
				if (!endDataN && limitReached) {
					state.pageN += 1;
				}
				state.loadingN = false;
			})
			.addCase(fetchNotifs.rejected, (state, action) => {
				state.loadingN = false;
				state.errorN = action.payload;
			})
			.addCase(fetchNbNewsNotifs.pending, (state) => {
				state.errorN = null;
			})
			.addCase(fetchNbNewsNotifs.fulfilled, (state, action) => {
				const { items } = action.payload;
				state.nbNewsNotifs = items?.length;
			})
			.addCase(fetchNbNewsNotifs.rejected, (state, action) => {
				state.errorN = action.payload;
			});
	},
});

export const { sortNotifications, readNotifications, cleanErrorN, cleanDataN } =
	notifSlice.actions;

export default notifSlice.reducer;
