import { Box } from "@mui/material";
import React from "react";
import Banner from "../../atoms/ProfileCard/Banner";
import Stats from "../../atoms/ProfileCard/Stats";
import ProfilePicture from "../../atoms/ProfileCard/ProfilePicture";
import Bio from "../../atoms/ProfileCard/Bio";
import { theme } from "../../../assets/themes/theme";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomLoadingButton from "../../atoms/Buttons/CustomLoadingButton";
import CustomOutlinedBtn from "../../atoms/Buttons/CustomOutlinedBtn";

const ProfileCard = ({
	setOpenFollowers,
	setOpenFollowings,
	setOpenEditProfil,
	handleClickFollow,
	handleClickUnFollow,
	loadingBtn,
}) => {
	const { id } = useParams();
	const user = useSelector((state) => state?.users?.itemsU[id]);
	const auth = useSelector((state) => state?.auth);

	return (
		<Box width={"100%"} bgcolor={"white"} borderRadius={"7px"} mb={3} sx={{borderTopLeftRadius:"20px", borderTopRightRadius:"20px", boxShadow: "1px 3px 6px rgba(85, 85, 85, 0.25)"}}>
			<Banner bannerPicture={user?.backgroundPicture} />
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				bgcolor={theme.palette.secondary.dark}
				ml={0.5}
				mr={0.5}
				mb={3}
				maxHeight={"55px"}
				sx={{boxShadow: "1px 3px 6px rgba(85, 85, 85, 0.25)"}}
			>
				<Stats
					count={user?.nbFollowers || 0}
					label="Abonnés"
					handleClick={() => setOpenFollowers(true)}
				/>
				<ProfilePicture profilePicture={user?.profilePicture} />
				<Stats
					count={user?.nbFollowing || 0}
					label="Abonnements"
					handleClick={() => setOpenFollowings(true)}
				/>
			</Box>
			<Bio
				firstName={user?.firstName}
				lastName={user?.lastName}
				username={user?.username}
				bio={user?.bio}
			/>
			<Box display={"flex"} justifyContent={"center"}>
				{id != auth?.user?.id ? (
					user?.followedSince ? (
						<CustomLoadingButton
							css={{ ml: "auto", mr: "auto", mb: 2 }}
							variant={"outlined"}
							content={"Ne plus suivre"}
							onClick={handleClickUnFollow}
							loading={loadingBtn}
						/>
					) : (
						<CustomLoadingButton
							css={{ ml: "auto", mr: "auto", mb: 2 }}
							content={"Suivre"}
							onClick={handleClickFollow}
							loading={loadingBtn}
						/>
					)
				) : (
					<CustomOutlinedBtn
						content={"Modifier le profil"}
						css={{ ml: "auto", mr: "auto", mb: 2 }}
						onClick={() => setOpenEditProfil(true)}
					/>
				)}
			</Box>
		</Box>
	);
};

export default ProfileCard;
