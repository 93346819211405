import { Modal } from "@mui/material";
import React from "react";
import SwitchCard from "../Card/SwitchCard";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";

const ThemeModal = ({ open, activeDarkMode, setActiveDarkMode, handleClose, waitUpdated, handeSave, }) => {

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="parent-modal-title"
			aria-describedby="parent-modal-description"
		>
			<SwitchCard
				loading={waitUpdated}
				active={activeDarkMode}
				onChange={() => setActiveDarkMode(!activeDarkMode)}
				description={"Changer le thème global de votre application"}
				handeSave={() =>
					handeSave("APP_THEME", activeDarkMode ? "dark" : "light")
				}
				icon={LightModeOutlinedIcon}
				subtitle={"Mode sombre"}
				title={"Changer l'apparence"}
				handleClose={handleClose}
			/>
		</Modal>
	);
};

export default ThemeModal;
