import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	requestAxiosEndOfRegister,
	requestAxiosGetMe,
	requestAxiosLogin,
	requestAxiosOauth,
	requestAxiosPutSettings,
	requestAxiosRegister,
	requestAxiosValidateRegister,
} from "../../services/axios/axiosRequests";

export const loginUser = createAsyncThunk(
	"auth/loginUser",
	async (credentials, { rejectWithValue }) => {
		try {
			const response = await requestAxiosLogin(credentials);
			const { token, refresh_token } = response;

			localStorage.setItem("token", token);

			document.cookie = `refresh_token=${refresh_token}; path=/; Secure; SameSite=Strict`;

			const user = await requestAxiosGetMe();
			return user;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const fetchUser = createAsyncThunk(
	"auth/fetchUser",
	async (_, { rejectWithValue }) => {
		try {
			const user = await requestAxiosGetMe();
			return user;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const updateConfig = createAsyncThunk(
	"auth/updateUserSettings",
	async ({ configurationKey, value }, { rejectWithValue }) => {
		try {
			const res = await requestAxiosPutSettings(configurationKey, value);
			return res;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const registerUser = createAsyncThunk(
	"auth/registerUser",
	async (credentials, { rejectWithValue }) => {
		try {
			await requestAxiosRegister(credentials);
			return;
		} catch (error) {
			if (error?.response && error?.response?.data) {
				return rejectWithValue(error.response.data);
			} else {
				return rejectWithValue(error);
			}
		}
	}
);

export const endOfRegister = createAsyncThunk(
	"auth/endOfRegister",
	async ({ userId, username, birthday }, { rejectWithValue }) => {
		try {
			const updatedUser = await requestAxiosEndOfRegister(
				userId,
				username,
				birthday
			);
			return updatedUser;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const validateAccount = createAsyncThunk(
	"auth/validateAccount",
	async ({ token }, { rejectWithValue }) => {
		try {
			await requestAxiosValidateRegister(
				token
			);
			return;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const authLinkedin = createAsyncThunk(
	"auth/linkedin",
	async (credentials, { rejectWithValue }) => {
		try {
			const response = await requestAxiosOauth(credentials);
			const { token, refresh_token } = response;

			if (!token || !refresh_token) {
				throw new Error("Response not received");
			}

			localStorage.setItem("token", token);

			document.cookie = `refresh_token=${refresh_token}; path=/; Secure; SameSite=Strict`;
			const user = await requestAxiosGetMe();
			return user;
		} catch (error) {
			if (error?.response && error?.response?.data) {
				return rejectWithValue(error.response.data);
			} else {
				return rejectWithValue(error);
			}
		}
	}
);

const authSlice = createSlice({
	name: "auth",
	initialState: {
		isAuthenticated: !!localStorage.getItem("token"),
		handleRedirect: false,
		user: null,
		loading: false,
		loadingLinkedin: false,
		error: null,
		provider: null,
	},
	reducers: {
		logout: (state) => {
			state.isAuthenticated = false;
			state.user = null;
			localStorage.removeItem("token");
			document.cookie =
				"refresh_token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT";
		},
		redirect: (state) => {
			state.handleRedirect = false;
		},
		cleanError: (state) => {
			state.error = null;
		},
		updateProfile: (state, action) => {
			const {
				firstName,
				lastName,
				profilePicture,
				backgroundPicture,
				bio,
				birthday,
			} = action.payload;
			if (firstName) {
				state.user.firstName = firstName;
			}
			if (lastName) {
				state.user.lastName = lastName;
			}
			if (profilePicture) {
				state.user.profilePicture = profilePicture;
			}
			if (backgroundPicture) {
				state.user.backgroundPicture = backgroundPicture;
			}
			if (bio) {
				state.user.bio = bio;
			}
			if (birthday) {
				state.user.birthday = birthday;
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(loginUser.pending, (state) => {
				state.loading = true;
				state.error = null;
				state.provider = "APP";
			})
			.addCase(loginUser.fulfilled, (state, action) => {
				state.user = action.payload;
				state.isAuthenticated = true;
				state.loading = false;
			})
			.addCase(loginUser.rejected, (state, action) => {
				state.isAuthenticated = false;
				state.user = null;
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(fetchUser.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchUser.fulfilled, (state, action) => {
				state.user = action.payload;
				state.loading = false;
			})
			.addCase(fetchUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(updateConfig.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(updateConfig.fulfilled, (state, action) => {
				state.user.configurations = action.payload;
				state.loading = false;
			})
			.addCase(updateConfig.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(registerUser.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(registerUser.fulfilled, (state) => {
				state.loading = false;
				state.handleRedirect = true;
			})
			.addCase(registerUser.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(endOfRegister.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(endOfRegister.fulfilled, (state, action) => {
				const { username, birthday } = action.payload;
				state.loading = false;
				state.user.username = username;
				state.user.birthday = birthday;
			})
			.addCase(endOfRegister.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			})
			.addCase(validateAccount.pending, (state) => {
				state.loading = true;
				state.errorValidate = null;
			})
			.addCase(validateAccount.fulfilled, (state, action) => {
				state.loading = false;
				state.valideAccount = true;
			})
			.addCase(validateAccount.rejected, (state, action) => {
				state.loading = false;
				state.errorValidate = action.payload;
				state.valideAccount = false;
			})
			.addCase(authLinkedin.pending, (state) => {
				state.loadingLinkedin = true;
				state.error = null;
				state.provider = "LINKEDIN";
			})
			.addCase(authLinkedin.fulfilled, (state, action) => {
				state.isAuthenticated = true;
				state.loadingLinkedin = false;
				state.user = action.payload;
			})
			.addCase(authLinkedin.rejected, (state, action) => {
				state.isAuthenticated = false;
				state.user = null;
				state.loadingLinkedin = false;
				state.errorLinkedin = action.payload;
			});
	},
});

export const { logout, redirect, cleanError, updateProfile } =
	authSlice.actions;

export default authSlice.reducer;
