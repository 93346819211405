import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import GoodMoveLogo from "../../assets/images/logo_classic_outlined.svg";
import CustomButton from "../atoms/Buttons/CustomButton";
import SharedPost from "../molecules/PostComponent/SharedPost";
import { useNavigate, useParams } from "react-router-dom";
import LoadingSection from "../organisms/LoadingSection/LoadingSection";
import {
	requestAxiosGetSharedPost,
	requestAxiosGetSharedPostComments,
} from "../../services/axios/axiosRequests";
import { toast } from "react-toastify";

const SharedPage = () => {
	document.title = "Rejoignez Goodmove";

	const navigate = useNavigate();
	const { uuid } = useParams();
	const [post, setPost] = useState(null);
	const [comments, setComments] = useState([]);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState(true);

	const fetchSharedPostData = async () => {
		try {
			const res = await requestAxiosGetSharedPost(uuid);
			setPost(res);
			handleLoadMoreComments();
		} catch (error) {
			toast.error(error?.message || "erreur lors du chargement du post", {
				toastId: "load-sharedpost-error",
			});
		}
	};

	const handleLoadMoreComments = async () => {
		try {
			const res = await requestAxiosGetSharedPostComments(uuid, page);
			const existingCommentIds = new Set(
				comments?.map((comment) => comment?.id)
			);
			// on garde uniquement les nouveaux commentaires qui ne sont pas déjà présents dans la liste
			const newComments = res?.items?.filter(
				(comment) => !existingCommentIds.has(comment?.id)
			);
			setComments([...comments, ...newComments]);
			
			const endData = comments?.length === res?.pagination?.total;
			const limitReached = res?.items?.length === 10;
			// S'il reste des commentaires à charger on passe à la page suivante
			if (!endData && limitReached) {
				setPage(page + 1);
			}
			setLoading(false);
		} catch (error) {
			toast.error(
				error?.message || "erreur lors du chargement des commentaires",
				{
					toastId: "load-sharedpostcomments-error",
				}
			);
		}
	};

	useEffect(() => {
		fetchSharedPostData();
	}, []);

	if (loading) {
		return <LoadingSection />;
	}

	return (
		<Box
			minHeight={"100vh"}
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"center"}
		>
			<Box sx={{ pt: 2 }} ml={"auto"} mr={"auto"} mb={3}>
				<img
					alt="GoodMove"
					src={GoodMoveLogo}
					style={{ width: "300px", cursor: "pointer" }}
					onClick={() => {
						navigate("/signin");
					}}
				/>
			</Box>
			<SharedPost
				post={post}
				comments={comments}
				handleLoadMoreComments={handleLoadMoreComments}
			/>
			<Typography variant="h6" textAlign={"center"} mt={3}>
				Envie de Réagir ?
			</Typography>
			<CustomButton
				content={"Rejoindre GoodMove"}
				destination={"/signin"}
				css={{ mt: 1, mb: 2 }}
			/>
		</Box>
	);
};

export default SharedPage;
