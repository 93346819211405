import { Box, IconButton, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../assets/themes/theme";
import SearchBar from "../../molecules/SearchBar/SearchBar";
import SearchTabs from "../../molecules/Tabs/SearchTabs";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { requestAxiosGetSearchResult } from "../../../services/axios/axiosRequests";
import BackBtn from "../../atoms/Buttons/BackBtn";
import SettingsIcon from "@mui/icons-material/Settings";

const SearchSection = () => {
	const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [searchParams, setSearchParams] = useSearchParams();
	const [loadingData, setloadingData] = useState(true);
	const [input, setInput] = useState("");
	const [suggestions, setSuggestions] = useState([]);
	const [filter, setFilter] = useState("all");
	const [debounceTimeout, setDebounceTimeout] = useState(null);
	const navigate = useNavigate();

	const handleChange = (value) => {
		!loadingData && setloadingData(true);
		setInput(value);
	};

	useEffect(() => {
		setSuggestions([]);
		const fetchSuggestions = async () => {
			if (!input || input.trim().length < 2) return;
			// Créer un nouveau token d'annulation
			const source = axios.CancelToken.source();
			try {
				const res = await requestAxiosGetSearchResult(
					input,
					filter,
					source
				);
				setSuggestions(res);
			} catch (error) {
				if (axios.isCancel(error)) {
					console.log("Requête annulée :", error.message);
				} else {
					console.error("Erreur lors de la requête :", error);
				}
			} finally {
				setTimeout(() => {
					setloadingData(false);
				}, 500);
			}

			return () => {
				source.cancel(
					"Recherche annulée car une nouvelle saisie a été faite"
				);
			};
		};

		if (debounceTimeout) {
			clearTimeout(debounceTimeout);
		}
		const newTimeout = setTimeout(fetchSuggestions, 1000);

		setDebounceTimeout(newTimeout);
		return () => clearTimeout(newTimeout);
	}, [input]);

	useEffect(() => {
		const filterParam = searchParams.get("filter");
		switch (filterParam) {
			case "relation":
				return setFilter("relations");
			case "tags":
				return setFilter("tags");
			default:
				setFilter("all");
		}
	}, [searchParams]);

	return (
		<Box
			color={theme.palette.primary.main}
			flex={4}
			p={2}
			display={"flex"}
			flexDirection={"column"}
		>
			<Box display={"flex"} justifyContent={"center"} mb={2}>
				{!mobileScreen && (
					<BackBtn
						css={{ width: "30px" }}
						cssBtn={{
							width: "40px",
							height: "40px",
							mt: "auto",
							mb: "auto",
							mr: 1
						}}
					/>
				)}
				<SearchBar handleChange={handleChange} input={input} />
				{!mobileScreen && (
					<IconButton
					sx={{ mt: "auto", mb: 'auto', mr: 3, ml: 1 }}
					onClick={() => navigate("/settings")}
				>
					<SettingsIcon />
				</IconButton>
				)}
			</Box>
			<SearchTabs
				data={suggestions}
				filter={filter}
				input={input}
				loadingData={loadingData}
			/>
		</Box>
	);
};

export default SearchSection;
