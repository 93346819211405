import { Avatar, Box, Typography, IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { timeElapsed } from "../../../utils/tool";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { theme } from "../../../assets/themes/theme";
import { likeComment } from "../../../store/slices/commentSlice";
import { formatText } from "../../../utils/tool";
import { useNavigate } from "react-router-dom";

const Comment = ({ postId, commentId }) => {
	const navigate = useNavigate();
	const comment = useSelector((state) =>
		state?.comments?.itemsC[postId]?.comments?.find(
			(c) => c.id === commentId
		)
	);
	
	const dispatch = useDispatch();
	const timeElapsedStr = timeElapsed(comment?.createdAt);
	const handleLike = () => {
		if (!comment.loadingLike) {
			dispatch(likeComment({ postId, commentId }));
		}
	};

	return (
		<Box display={"flex"} justifyContent={"space-between"} mt={1}>
			<Box flex={1} mr={1} onClick={() => navigate("/profile/"+comment?.user?.id)}>
				<Avatar
					alt="Profile picture"
					src={comment?.user?.profilePicture}
					sx={{ borderRadius: 1, ml: "auto", mr: "auto", mt : 0.5, cursor:'pointer' }}
				/>
			</Box>
			<Box flex={9}>
				<Box display={"flex"} justifyContent={"space-evenly"}>
					<Box flex={8}>
						<Typography>
							<b>{comment?.user?.username || "unknown"} </b>
							{formatText(comment?.content)}
						</Typography>
						<Box
							display={"flex"}
							justifyContent={"left"}
							flexWrap={"wrap"}
							mb={1}
						>
							<Typography
								mr={2}
								color={theme.palette.primary.main}
								fontWeight={500}
							>
								{timeElapsedStr}
							</Typography>
							<Typography
								mr={2}
								ml={1}
								sx={{ cursor: "pointer" }}
							>
								{comment?.nbLikes || 0} J'aimes
							</Typography>
							{/* <Typography
								mr={1}
								ml={1}
								sx={{ cursor: "pointer" }}
							>
								Répondre
							</Typography> */}
						</Box>
					</Box>
					<Box flex={1} sx={{ textAlign: "center" }}>
						<IconButton
							onClick={handleLike}
							disabled={comment?.loadingLike}
						>
							{comment?.userHasLiked ? (
								<FavoriteIcon
									sx={{ cursor: "pointer", color: "red" }}
								/>
							) : (
								<FavoriteIcon sx={{ cursor: "pointer" }} />
							)}
						</IconButton>
					</Box>
				</Box>
				{/* {comment?.nbReplies > 0 && (
					<Box display={"flex"}>
						<Typography
							variant="body2"
							color="secondary.ultradark"
							textAlign={"left"}
							sx={{
								mt: 1,
								cursor: "pointer",
							}}
						>
							{"--- " + comment?.nbReplies + " réponses"}
						</Typography>
					</Box>
				)} */}
			</Box>
		</Box>
	);
};

export default Comment;
