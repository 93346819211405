import { IconButton, InputAdornment, TextField, useMediaQuery } from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { theme } from "../../../assets/themes/theme";

const SearchBar = ({ input, handleChange }) => {

	const mediumScreen = useMediaQuery(theme.breakpoints.between("sm", "lg"));
	
	return (
		<TextField
			label={mediumScreen ? "Recherche" : "Rechercher des tags ou des utilisateurs"}
			variant="outlined"
			fullWidth
			value={input}
			onChange={(e) => handleChange(e.target.value)}
			sx={{
				backgroundColor: theme.palette.secondary.light,
				boxShadow: "1px 3px 6px rgba(85, 85, 85, 0.25)",
				maxWidth: 1000,
				ml: "auto",
				mr: "auto",
				mb: 'auto',
				mt: 'auto'
			}}
			InputLabelProps={{
				sx: {
					backgroundColor: theme.palette.secondary.light,
				},
			}}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						{input && (
							<IconButton onClick={()=>handleChange('')}>
								<CloseIcon />
							</IconButton>
						)}
						<IconButton onClick={()=>{}}>
							<SearchIcon />
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	);
};

export default SearchBar;
