import {
	Avatar,
	Box,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import LogoutIcon from "@mui/icons-material/Logout";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
	cleanError,
	logout,
	updateConfig,
} from "../../../store/slices/authSlice";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../Modal/ConfirmModal";
import ThemeModal from "../Modal/ThemeModal";
import { toast } from "react-toastify";

const DrawerCustom = () => {
	const [open, setOpen] = useState(false);
	const [openTheme, setOpenTheme] = useState(false);
	const [activeDarkMode, setActiveDarkMode] = useState(false);
	const [waitUpdated, setWaitUpdated] = useState(false);
	const [openConfirm, setOpenConfirm] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const configs = useSelector((state) => state.auth.user.configurations);
	const { loading, error } = useSelector((state) => state.auth);

	const handleOpen = (isOpen) => {
		setOpen(isOpen);
	};

	const handleParams = () => {
		setOpen(false);
		navigate("/settings");
	};

	const handleTheme = () => {
		setOpenTheme(true);
	};

	const handleLogout = () => {
		setOpen(false);
		dispatch(logout());
		navigate("/signin");
	};

	const { user } = useSelector((state) => state.auth);

	const handeSave = (configurationKey, value) => {
		dispatch(updateConfig({ configurationKey, value }));
		setWaitUpdated(true);
	};

	useEffect(() => {
		setActiveDarkMode(
			configs?.find((config) => config?.configurationKey === "APP_THEME")
				?.value === "dark"
		);
	}, [openTheme]);

	useEffect(() => {
		if (waitUpdated && !loading && !error) {
			setWaitUpdated(false);
			setOpenTheme(false);
			toast.success("Thème modifié avec succès.", {
				toastId: "theme-update-success",
			});
		} else if (waitUpdated && !loading && error) {
			setWaitUpdated(false);
			setOpenTheme(false);
			toast.error("Une erreur est survenue lors du changement de thème.", {
				toastId: "settings-update-error",
			});
			dispatch(cleanError());
		}
	}, [configs]);

	const DrawerList = (
		<Box sx={{ width: 300 }}>
			<Box display={"flex"} mt={5} mb={5}>
				<IconButton
					onClick={() => handleOpen(false)}
					sx={{ cursor: "pointer", ml: 1 }}
				>
					<CloseOutlinedIcon size={"22px"} />
				</IconButton>
				<Avatar
					src={user?.profilePicture}
					sx={{
						ml: 4,
						borderRadius: 1,
					}}
				/>
				<Typography mt={"auto"} mb={"auto"} ml={1}>
					{user?.firstName} {user?.lastName}
				</Typography>
			</Box>
			<List>
				{[
					{
						text: "Paramètres",
						icon: <SettingsOutlinedIcon size={"24px"} />,
						click: () => handleParams(),
					},
					{
						text: "Changer d'apparence",
						icon: <WbSunnyOutlinedIcon size={"24px"} />,
						click: () => handleTheme(),
					},
					{
						text: "Déconnexion",
						icon: (
							<LogoutIcon size={"24px"} sx={{ color: "red" }} />
						),
						css: { color: "red" },
						click: () => setOpenConfirm(true),
					},
				].map((item, index) => (
					<ListItem
						key={item?.text}
						onClick={item?.click}
						disablePadding
					>
						<ListItemButton sx={{ mb: 2 }}>
							<ListItemIcon>{item?.icon}</ListItemIcon>
							<ListItemText
								primary={item?.text}
								sx={{ ml: 1, ...item?.css }}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<ConfirmModal
				open={openConfirm}
				title={"Déconnexion de GoodMove ?"}
				description={
					"Cette action n'est pas définitive, vous pouvez vous reconnecter à tout moment."
				}
				handleClose={() => setOpenConfirm(false)}
				handleConfirmClick={handleLogout}
				confirmTitle={"Déconnexion"}
			/>
			<ThemeModal
				open={openTheme}
				activeDarkMode={activeDarkMode}
				setActiveDarkMode={setActiveDarkMode}
				handeSave={handeSave}
				handleClose={() => setOpenTheme(false)}
				waitUpdated={waitUpdated}
			/>
		</Box>
	);

	return (
		<Box>
			<IconButton
				onClick={() => handleOpen(true)}
				sx={{ cursor: "pointer" }}
			>
				<RxHamburgerMenu size={"22px"} />
			</IconButton>
			<Drawer
				open={open}
				onClose={() => handleOpen(false)}
				anchor={"right"}
			>
				{DrawerList}
			</Drawer>
		</Box>
	);
};

export default DrawerCustom;
