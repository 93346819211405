import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigationHistory } from "../../../contexts/NavigationContext";
import { theme } from "../../../assets/themes/theme";

const BackBtn = ({css, cssBtn}) => {
	const navigate = useNavigate();
	const history = useNavigationHistory();

	const handleBackButtonClick = () => {
		if (history.current.length > 1) {
			const currentPath = history.current[history.current.length - 1];
			let lastDifferentOriginIndex = history.current.length - 2;

			// Parcourir l'historique pour trouver une origine différente
			while (
				lastDifferentOriginIndex >= 0 &&
				history.current[lastDifferentOriginIndex] === currentPath
			) {
				lastDifferentOriginIndex--;
			}

			if (lastDifferentOriginIndex >= 0) {
				const lastPath = history.current[lastDifferentOriginIndex];
				if (lastPath.startsWith("/")) {
					navigate(lastPath);
					history.current = history.current.slice(
						0,
						lastDifferentOriginIndex + 1
					);
				} else {
					navigate("/feed");
					history.current = ["/feed"];
				}
			} else {
				navigate("/feed");
				history.current = ["/feed"];
			}
		} else {
			navigate("/feed");
		}
	};

	return (
		<IconButton onClick={handleBackButtonClick} sx={{...cssBtn}}>
			<KeyboardBackspaceIcon
				sx={{
					color: theme.palette.otherColor.main,
					pr: 0.3,
					width: "20px",
					...css
				}}
			/>
		</IconButton>
	);
};

export default BackBtn;
