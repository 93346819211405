import React from "react";
import { Container, Typography, Link } from "@mui/material";
import logo from '../../../assets/images/logo_classic_outlined.svg';

const Cookies = () => {
    document.title = "Politique de Cookies | Goodmove";

    return (
        <Container maxWidth="md" style={{ paddingTop: '20px' }}>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src={logo} alt="Logo de Goodmove" style={{ width: '150px' }} />
            </div>
            <Typography variant="h1" gutterBottom>
                Politique de Cookies de GoodMove
            </Typography>

            <Typography variant="h2" gutterBottom>
                1. Introduction
            </Typography>
            <Typography paragraph>
                Chez GoodMove, nous utilisons des cookies et technologies similaires pour améliorer votre expérience sur notre plateforme. Cette politique de cookies explique ce que sont les cookies, comment nous les utilisons, et comment vous pouvez les gérer.
            </Typography>

            <Typography variant="h2" gutterBottom>
                2. Qu'est-ce qu'un Cookie ?
            </Typography>
            <Typography paragraph>
                Les cookies sont de petits fichiers texte qui sont stockés sur votre appareil lorsque vous visitez un site Web. Ils permettent au site de se souvenir de vos préférences, de suivre votre navigation, et d'améliorer votre expérience utilisateur.
            </Typography>

            <Typography variant="h2" gutterBottom>
                3. Types de Cookies Utilisés
            </Typography>

            <Typography variant="h3" gutterBottom>
                3.1. Cookies Essentiels
            </Typography>
            <Typography paragraph>
                Ces cookies sont nécessaires au fonctionnement de notre plateforme. Ils permettent des fonctionnalités de base comme la connexion à votre compte et la navigation sur le site. Sans ces cookies, certaines parties de notre site pourraient ne pas fonctionner correctement.
            </Typography>

            <Typography variant="h3" gutterBottom>
                3.2. Cookies de Performance
            </Typography>
            <Typography paragraph>
                Ces cookies nous aident à analyser l’utilisation de notre plateforme et à améliorer ses performances. Ils nous permettent de collecter des informations sur la manière dont les utilisateurs utilisent notre site, comme les pages les plus visitées et les erreurs rencontrées.
            </Typography>

            <Typography variant="h3" gutterBottom>
                3.3. Cookies Fonctionnels
            </Typography>
            <Typography paragraph>
                Ces cookies permettent de se souvenir de vos préférences et paramètres (comme la langue ou la région) pour personnaliser votre expérience. Ils peuvent également être utilisés pour fournir des fonctionnalités améliorées et personnalisées.
            </Typography>

            <Typography variant="h3" gutterBottom>
                3.4. Cookies de Publicité
            </Typography>
            <Typography paragraph>
                Nous ne utilisons pas actuellement de cookies pour la publicité. Cependant, nous nous réservons le droit de le faire à l'avenir pour vous proposer des publicités plus pertinentes en fonction de vos intérêts.
            </Typography>

            <Typography variant="h2" gutterBottom>
                4. Gestion des Cookies
            </Typography>

            <Typography variant="h3" gutterBottom>
                4.1. Paramètres du Navigateur
            </Typography>
            <Typography paragraph>
                La plupart des navigateurs vous permettent de gérer vos préférences en matière de cookies. Vous pouvez généralement configurer votre navigateur pour refuser les cookies ou pour vous avertir lorsqu'un cookie est envoyé. Veuillez consulter les paramètres de votre navigateur pour plus d'informations sur la gestion des cookies.
            </Typography>

            <Typography variant="h3" gutterBottom>
                4.2. Désactivation des Cookies
            </Typography>
            <Typography paragraph>
                Veuillez noter que désactiver les cookies peut affecter la fonctionnalité de notre site et limiter votre expérience utilisateur. Certaines fonctionnalités peuvent ne pas fonctionner correctement sans cookies.
            </Typography>

            <Typography variant="h2" gutterBottom>
                5. Modifications de la Politique de Cookies
            </Typography>
            <Typography paragraph>
                Nous nous réservons le droit de modifier cette politique de cookies à tout moment. Toute modification sera publiée sur notre site et prendra effet dès sa publication. Votre utilisation continue de notre plateforme après toute modification constitue votre acceptation de la nouvelle politique de cookies.
            </Typography>

            <Typography variant="h2" gutterBottom>
                6. Contact
            </Typography>
            <Typography paragraph>
                Pour toute question ou préoccupation concernant cette politique de cookies, veuillez nous contacter à <Link href="mailto:contact@goodmove.com">contact@goodmove.com</Link>.
            </Typography>

            <Typography variant="h2" gutterBottom>
                7. Mention Légale
            </Typography>
            <Typography paragraph>
                Veuillez noter que GoodMove est un projet étudiant fictif développé à des fins pédagogiques. Les informations, fonctionnalités et services décrits dans ce document sont simulés et ne doivent pas être considérés comme des offres réelles ou des engagements.
            </Typography>
        </Container>
    );
};

export default Cookies;
