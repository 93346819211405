import { Box, Card, Divider, Icon, IconButton, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { theme } from "../../../assets/themes/theme";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import GoodMoveLogo from "../../../assets/images/logo_classic_outlined.svg";
import CloseIcon from "@mui/icons-material/Close";

const HelpCard = ({ handleClose }) => {
	const mediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
	return (
		<Card
			sx={{
				maxWidth: mediumScreen?"none":"750px",
				width: {
					xs: "100%",
					sm: "100%",
					md: "100%",
				},
				height: {
					xs: "100%",
					sm: "100%",
					md: "auto",
				},
				pt: 2,
				pb: 2,
				borderRadius: "16px",
				minHeight: mediumScreen?"100vh":"100%",
				ml: "auto",
				mr: "auto",
				backgroundColor: theme.palette.secondary.main,
				overflowY:'scroll'
			}}
		>
			{mediumScreen && (
				<IconButton
					sx={{ top: 30, left: 10, position: "absolute" }}
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			)}
			<Box display={"flex"} justifyContent={"center"} mb={2} mt={2}>
				<Typography variant="h5" mr={2} mt={"auto"} mb={"auto"}>
					Obtenir de l'aide
				</Typography>
				<Icon
					component={HelpOutlineOutlinedIcon}
					sx={{
						mt: "auto",
						mb: "auto",
						width: "30px",
						height: "30px",
					}}
				/>
			</Box>
			<Divider sx={{ mb: 2 }} />
			<Box m={4} display={"flex"} flexDirection={"column"}>
				<Typography fontSize={"20px"}>
					Besoin de nous contacter ?
				</Typography>
				<Typography mb={5} mt={2}>
					Nos équipes GoodMove restent disponibles en cas de besoin
					que se soit pour une demande de renseignement, pour nous
					signaler un incident, pour une récupération de compte ou
					pour n'importe qu'elle autre demande. <br />
					<br />
					Vous pourrez nous contacter à l'adresse :{" "}
					<b>goodmove.contacts@gmail.com</b>
				</Typography>
				<img
					alt="GoodMove"
					src={GoodMoveLogo}
					style={{
						width: "300px",
						cursor: "pointer",
						marginLeft: "auto",
						marginRight: "auto",
					}}
				/>
			</Box>
		</Card>
	);
};

export default HelpCard;
