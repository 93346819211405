import {
	Avatar,
	Badge,
	Box,
	Button,
	IconButton,
	List,
	ListItemButton,
	ListItemText,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { theme } from "../../../assets/themes/theme";
import Logo from "../../../assets/images/logo_classic_outlined.svg";
import Icon from "../../../assets/images/icon_outlined_classic.svg";
import { Link, useNavigate } from "react-router-dom";
import { GoHome } from "react-icons/go";
import { IoIosSearch } from "react-icons/io";
import { IoMdNotificationsOutline } from "react-icons/io";
import { MdOutlineLogout } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../store/slices/authSlice";
import PenIcon from "../../../assets/images/pen_icon.png";
import ConfirmModal from "../Modal/ConfirmModal";

const Navbar = ({ handleOpenCreatePost, minForce }) => {
	const isWeb = useMediaQuery(theme.breakpoints.up("lg"));
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [openConfirm, setOpenConfirm] = useState(false);
	const SXListItemButton = { color: theme.palette.otherColor.main, mt: 3 };
	const SXListItemText = {
		ml: 2,
		display: isWeb ? "block" : "none",
	};
	const TypoListItemText = { fontSize: "20px" };

	const handleLogout = () => {
		dispatch(logout());
		navigate("/signin");
	};

	const { user } = useSelector((state) => state.auth);
	const { nbNewsNotifs } = useSelector((state) => state.notifs);

	return (
		<Box
			bgcolor={theme.palette.secondary.dark}
			p={2}
			sx={{
				position: "fixed",
				zIndex: 10,
				top: 0,
				left: 0,
				width: isWeb && !minForce ? "250px" : "65px",
				display: { xs: "none", sm: "flex" },
				flexDirection: "column",
				justifyContent: "space-between",
				height: "100%",
				overflowX: "hidden",
				overflowY: "auto",
				boxShadow: "1px 1px 4px #555555",
			}}
		>
			<Box>
				{isWeb && !minForce ? (
					<Box
						className="navbar_logo"
						sx={{ textAlign: "center", mb: 2, cursor: "pointer" }}
					>
						<img
							src={Logo}
							style={{ width: "200px" }}
							alt="GoodMove"
						/>
					</Box>
				) : (
					<Box
						component={Link}
						to="/feed"
						sx={{
							marginLeft: "8px",
							mb: 2,
							cursor: "pointer",
						}}
					>
						<img
							src={Icon}
							style={{ width: "50px", margin: "0 auto" }}
							alt="GoodMove"
						/>
					</Box>
				)}
				{isWeb && !minForce ? (
					<>
						<List className="navbar_list" sx={{ margin: "0 auto" }}>
							<ListItemButton
								className="navbar_item"
								component={Link}
								to="/feed"
								sx={SXListItemButton}
							>
								<GoHome size={30} />
								<ListItemText
									primary="Accueil"
									primaryTypographyProps={TypoListItemText}
									className="navbar_text"
									sx={SXListItemText}
								/>
							</ListItemButton>
							<ListItemButton
								className="navbar_item"
								component={Link}
								to="/search"
								sx={SXListItemButton}
							>
								<IoIosSearch size={30} />
								<ListItemText
									primary="Recherche"
									primaryTypographyProps={TypoListItemText}
									className="navbar_text"
									sx={SXListItemText}
								/>
							</ListItemButton>
							<ListItemButton
								className="navbar_item"
								component={Link}
								to="/notifications"
								sx={SXListItemButton}
							>
								<Badge
									badgeContent={nbNewsNotifs}
									color="error"
								>
									<IoMdNotificationsOutline size={30} />
								</Badge>
								<ListItemText
									primary="Notifications"
									primaryTypographyProps={TypoListItemText}
									className="navbar_text"
									sx={SXListItemText}
								/>
							</ListItemButton>
							<ListItemButton
								className="navbar_item"
								component={Link}
								to={"/profile/" + user?.id}
								sx={SXListItemButton}
							>
								<Avatar
									alt="Profile picture"
									src={user?.profilePicture}
									sx={{
										width: "30px",
										height: "30px",
										borderRadius: 1,
										ml: "auto",
										mr: "auto",
									}}
								/>
								<ListItemText
									primary="Profil"
									primaryTypographyProps={TypoListItemText}
									className="navbar_text"
									sx={SXListItemText}
								/>
							</ListItemButton>
						</List>
						<Box
							sx={{
								textAlign: "center",
								marginTop: 5,
							}}
						>
							<Button
								variant="contained"
								color="primary"
								onClick={handleOpenCreatePost}
								sx={{
									color: theme.palette.secondary.main,
									minWidth: 150,
									margin: "0 auto",
								}}
							>
								Publier
							</Button>
						</Box>
					</>
				) : (
					<>
						<Box
							sx={{
								textAlign: "center",
								marginTop: 5,
							}}
						>
							<IconButton
								className="navbar_item"
								onClick={() => navigate("/feed")}
								sx={{ ...SXListItemButton, mt: 0 }}
							>
								<GoHome size={30} />
							</IconButton>
							<IconButton
								className="navbar_item"
								onClick={() => navigate("/search")}
								sx={SXListItemButton}
							>
								<IoIosSearch size={30} />
							</IconButton>
							<IconButton
								className="navbar_item"
								onClick={() => navigate("/notifications")}
								sx={SXListItemButton}
							>
								<Badge
									badgeContent={nbNewsNotifs}
									color="error"
								>
									<IoMdNotificationsOutline size={30} />
								</Badge>
							</IconButton>
							<IconButton
								className="navbar_item"
								onClick={() => navigate("/profile/" + user?.id)}
								sx={{ ...SXListItemButton, mt: 2 }}
							>
								<Avatar
									alt="Profile picture"
									src={user?.profilePicture}
									sx={{
										width: "30px",
										height: "30px",
										borderRadius: 1,
										ml: "auto",
										mr: "auto",
									}}
								/>
							</IconButton>
						</Box>
						<Box
							sx={{
								textAlign: "center",
								marginTop: 5,
							}}
						>
							<IconButton
								className="navbar_item"
								onClick={handleOpenCreatePost}
								sx={{
									backgroundColor: theme.palette.primary.main,
									width: "45px",
									height: "45px",
									...SXListItemButton,
									"&:hover": {
										backgroundColor:
											theme.palette.primary.dark,
									},
								}}
							>
								<img
									src={PenIcon}
									alt="create post"
									style={{ width: "24px", height: "24px" }}
								/>
							</IconButton>
						</Box>
					</>
				)}
			</Box>
			<Box>
				{isWeb && !minForce ? (
					<Box
						sx={{
							textAlign: "center",
							marginTop: 5,
						}}
					>
						<Button
							variant="contained"
							color="primary"
							onClick={(e) => setOpenConfirm(true)}
							sx={{
								color: theme.palette.secondary.main,
								backgroundColor: theme.palette.otherColor.main,
								minWidth: 150,
								ml: "auto",
								mr: "auto",
								mb: "50px",
								"&:hover": {
									backgroundColor:
										theme.palette.otherColor.light,
								},
							}}
						>
							Déconnexion
						</Button>
					</Box>
				) : (
					<Box
						sx={{
							textAlign: "center",
							mb: "50px",
						}}
					>
						<IconButton
							className="navbar_item"
							onClick={(e) => setOpenConfirm(true)}
							sx={SXListItemButton}
						>
							<MdOutlineLogout size={30} />
						</IconButton>
					</Box>
				)}
			</Box>
			<ConfirmModal
				open={openConfirm}
				title={"Déconnexion de GoodMove ?"}
				description={
					"Cette action n'est pas définitive, vous pouvez vous reconnecter à tout moment."
				}
				handleClose={() => setOpenConfirm(false)}
				handleConfirmClick={handleLogout}
				confirmTitle={"Déconnexion"}
			/>
		</Box>
	);
};

export default Navbar;
