import React from "react";
import { Container, Typography, Link } from "@mui/material";
import logo from '../../../assets/images/logo_classic_outlined.svg';

const Privacy = () => {
    document.title = "Politique de Confidentialité | Goodmove";

    return (
        <Container maxWidth="md" style={{ paddingTop: '20px' }}>
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                <img src={logo} alt="Logo de Goodmove" style={{ width: '150px' }} />
            </div>
            <Typography variant="h1" gutterBottom>
                Politique de Confidentialité de GoodMove
            </Typography>

            <Typography variant="h2" gutterBottom>
                1. Introduction
            </Typography>
            <Typography paragraph>
                Chez GoodMove, nous nous engageons à protéger votre vie privée et à garantir la sécurité de vos données personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons, et protégeons vos informations.
            </Typography>

            <Typography variant="h2" gutterBottom>
                2. Données Collectées
            </Typography>
            <Typography paragraph>
                Nous collectons les types de données suivants :
            </Typography>
            <ul>
                <li><strong>Informations personnelles :</strong> nom, prénom, adresse e-mail, etc.</li>
                <li><strong>Informations sur l'utilisation :</strong> activités sur notre plateforme, interactions avec le contenu, etc.</li>
                <li><strong>Données techniques :</strong> adresse IP, type de navigateur, informations sur l'appareil utilisé, etc.</li>
            </ul>

            <Typography variant="h2" gutterBottom>
                3. Utilisation des Données
            </Typography>
            <Typography paragraph>
                Nous utilisons vos données pour :
            </Typography>
            <ul>
                <li>Fournir et améliorer nos services.</li>
                <li>Vous envoyer des notifications importantes et des mises à jour.</li>
                <li>Analyser l'utilisation de notre plateforme pour mieux répondre à vos besoins.</li>
            </ul>

            <Typography variant="h2" gutterBottom>
                4. Conservation des Données
            </Typography>
            <Typography paragraph>
                Nous conservons vos données personnelles aussi longtemps que nécessaire pour atteindre les objectifs pour lesquels elles ont été collectées. En cas de suppression de votre compte, vos données seront supprimées au bout de 30 jours. Les documents et informations de votre compte seront également supprimés et ne seront pas conservés après cette période.
            </Typography>

            <Typography variant="h2" gutterBottom>
                5. Sécurité des Données
            </Typography>
            <Typography paragraph>
                Nous prenons des mesures de sécurité appropriées pour protéger vos données personnelles contre tout accès non autorisé, modification, divulgation ou destruction. Les mots de passe sont stockés de manière sécurisée en utilisant des techniques de chiffrement.
            </Typography>

            <Typography variant="h2" gutterBottom>
                6. Vos Droits
            </Typography>
            <Typography paragraph>
                Vous avez le droit d'accéder à vos données personnelles, de les corriger ou de les supprimer. Vous pouvez également demander une copie de vos données personnelles que nous avons en notre possession. Pour exercer vos droits, veuillez nous contacter à <Link href="mailto:contact@goodmove.com">contact@goodmove.com</Link>.
            </Typography>

            <Typography variant="h2" gutterBottom>
                7. Modifications de la Politique de Confidentialité
            </Typography>
            <Typography paragraph>
                Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur notre site et prendra effet dès sa publication. Votre utilisation continue de notre plateforme après toute modification constitue votre acceptation de la nouvelle politique de confidentialité.
            </Typography>

            <Typography variant="h2" gutterBottom>
                8. Contact
            </Typography>
            <Typography paragraph>
                Pour toute question ou préoccupation concernant cette politique de confidentialité, veuillez nous contacter à <Link href="mailto:contact@goodmove.com">contact@goodmove.com</Link>.
            </Typography>

            <Typography variant="h2" gutterBottom>
                9. Mention Légale
            </Typography>
            <Typography paragraph>
                Veuillez noter que GoodMove est un projet étudiant fictif développé à des fins pédagogiques. Les informations et services décrits dans ce document sont simulés et ne doivent pas être considérés comme des offres réelles ou des engagements.
            </Typography>
        </Container>
    );
};

export default Privacy;
